import React, { useContext, useEffect } from "react";
import { IncomeCashDay } from "../icons/IncomeCashDay";
import DashboardContext from "../../Context/Dashboard/DashboardContext";
const CardCashEntryDay = () => {
  const { ingresos_dia, obtenerIngresosDelDia } = useContext(DashboardContext);
  useEffect(() => {
    obtenerIngresosDelDia();
  }, []);
  return (
    <div className='card'>
      <div className='container'>
        <div className='cloud front'>
          <IncomeCashDay />
        </div>
        <div className='cloud back'></div>
      </div>

      <div className='card-header'>
        <span>Ingresos del día</span>
        <span>{ingresos_dia.fecha}</span>
      </div>

      <span className='temp'>{ingresos_dia.total_pagos_hoy}</span>

      <div className='temp-scale'>
        <span>MXN</span>
      </div>
    </div>
  );
};

export default CardCashEntryDay;
