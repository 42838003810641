import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MethodGet from "../../../config/Service";
import { CardMedia, Divider, Grid, Paper, Typography } from "@mui/material";

const ModalShowProduct = ({ open, handleClose, id }) => {
  const [product, setProduct] = useState(null);
  const miImage = require("../../../assets/img/default .png");
  useEffect(() => {
    if (product === null) {
      let url = `/products/${id}`;
      MethodGet(url)
        .then((res) => {
          setProduct(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <Paper elevation={0}>
            {product && (
              <CardMedia
                component='img'
                height='auto'
                image={product.img[0]?.image ?? miImage}
                alt={product.name}
              />
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper elevation={0}>
            {product && (
              <>
                <Typography
                  textAlign='center'
                  fontWeight='bold'
                  fontSize='30px'
                  sx={{ color: "#FFA489" }}
                >
                  {product.name}
                </Typography>
                <Typography fontSize='28px' marginLeft='4px' color='#FFA489'>
                  ${product.price_purchase}
                </Typography>
                <Typography fontSize='24px' marginLeft='4px' color='black'>
                  SKU: {product ? product.sku : "N/A"}
                </Typography>
                <Typography
                  fontSize='20px'
                  marginLeft='4px'
                  sx={{ color: "#43a047" }}
                >
                  {product.stock} disponibles{" "}
                </Typography>
                <Typography fontSize='18px' marginLeft='4px'>
                  Categoría: <b>{product.category.name}</b>
                </Typography>
                <Typography fontSize='18px' marginLeft='4px'>
                  Subcategoría: <b>{product.subcategory.name}</b>
                </Typography>
                <Divider>
                  <Typography>Descripción</Typography>
                </Divider>
                <Typography
                  fontSize='16px'
                  marginLeft='4px'
                  color='gray'
                  textAlign='justify'
                >
                  {product.description}
                </Typography>
              </>
            )}
          </Paper>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant='contained'
          sx={{
            backgroundColor: "red",
            color: "white",
            "&:hover": {
              backgroundColor: "red",
              color: "white",
            },
          }}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalShowProduct;
