export const AddCartIcon = ({
  fill = "currentColor",
  filled,
  size,
  height,
  width,
  label,
  ...props
}) => {
  return (
    <svg
      fill='#FFA489'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      stroke='#FFA489'
      width='50PX'
    >
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        stroke-linecap='round'
        stroke-linejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        {" "}
        <path d='M13,12 L16.5,12 C16.7761424,12 17,12.2238576 17,12.5 C17,12.7761424 16.7761424,13 16.5,13 L13,13 L13,16.5 C13,16.7761424 12.7761424,17 12.5,17 C12.2238576,17 12,16.7761424 12,16.5 L12,13 L8.5,13 C8.22385763,13 8,12.7761424 8,12.5 C8,12.2238576 8.22385763,12 8.5,12 L12,12 L12,8.5 C12,8.22385763 12.2238576,8 12.5,8 C12.7761424,8 13,8.22385763 13,8.5 L13,12 Z M12.5,23 C6.70101013,23 2,18.2989899 2,12.5 C2,6.70101013 6.70101013,2 12.5,2 C18.2989899,2 23,6.70101013 23,12.5 C23,18.2989899 18.2989899,23 12.5,23 Z M12.5,22 C17.7467051,22 22,17.7467051 22,12.5 C22,7.25329488 17.7467051,3 12.5,3 C7.25329488,3 3,7.25329488 3,12.5 C3,17.7467051 7.25329488,22 12.5,22 Z'></path>{" "}
      </g>
    </svg>
  );
};
