import React from "react";
import SendIndex from "../OrdersAdmin/SendIndex";
import Layout from "../../../components/Layout/Layout";

const IndexCont = () => {
  return (
    <Layout>
      <div style={{ fontSize: "25px", textAlign: "center" }}>
        Ordenes enviadas
      </div>
      <SendIndex />
    </Layout>
  );
};

export default IndexCont;
