import React, { useReducer } from "react";
import PointsReducer from "./PointsReducer";
import PointsContext from "./PointsContext";
import MethodGet, { MethodPost } from "../../config/Service";
import {
  ADD_POINTS,
  CREATE_CLIENT,
  DECREASE_POINTS,
  GET_INFO,
} from "../../types";
const PointsState = ({ children }) => {
  const initialState = {
    cliente: {},
    ErrorsApi: [],
  };
  const [state, dispatch] = useReducer(PointsReducer, initialState);

  const GetInfoClient = (phone) => {
    console.log(phone, "el telefono que se manda");
    let url = "https://apipoints.wapizima.com/GetInfo";
    const formData = new FormData();
    formData.append("telefono", phone);
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_INFO,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const CreateClient = (data) => {
    let url = "https://apipoints.wapizima.com/CreateClient";
    const formData = new FormData();
    formData.append("nombre", data.name);
    formData.append("ap_pat", data.ap_pat);
    formData.append("ap_mat", data.ap_mat);
    formData.append("telefono", data.phone);
    formData.append("puntos", data.points);
    MethodPost(url)
      .then((res) => {
        dispatch({
          type: CREATE_CLIENT,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const AddPoints = (data) => {
    let url = "https://apipoints.wapizima.com/AddPoints";
    const formData = new FormData();
    formData.append("telefono", data.phone);
    formData.append("puntos", data.puntos);
    MethodPost(url)
      .then((res) => {
        dispatch({
          type: ADD_POINTS,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ChangePoints = (data) => {
    let url = "https://apipoints.wapizima.com/DecreasePoints";
    const formData = new FormData();
    formData.append("telefono", data.phone);
    formData.append("puntosADescontar", data.points);
    MethodPost(url)
      .then((res) => {
        dispatch({
          type: DECREASE_POINTS,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <PointsContext.Provider
      value={{
        client: state.client,
        GetInfoClient,
        CreateClient,
        AddPoints,
        ChangePoints,
      }}
    >
      {children}
    </PointsContext.Provider>
  );
};

export default PointsState;
