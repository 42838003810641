import React, { useContext, useEffect, useState } from "react";
import ProductsContext from "../../Context/Products/ProductsContext";
import Layout from "../../components/Layout/Layout";
import { Grid, IconButton, Typography } from "@mui/material";
import CardProduct from "../../components/Products/CardProduct";
import CartContext from "../../Context/Cart/CartContext";
import { BackIcon } from "../../components/icons/BackIcon";
import NoDataComponents from "../../components/loading/NoDataComponents";
const ProductsCategory = (props) => {
  const { id, category } = props.match.params;
  const { ProductsCategory, products } = useContext(ProductsContext);
  const [spinner, setSpinerCargando] = useState(false);

  useEffect(() => {
    ProductsCategory(id);
  }, [id]);
  const { GetCartUserLogged, cart } = useContext(CartContext);
  useEffect(() => {
    GetCartUserLogged();
  }, []);
  const [productsWithQuantity, setProductsWithQuantity] = useState(
    products
      ? products.map((product) => {
          const cartItem = cart.find((item) => item.id === product.id);
          const quantity = cartItem ? cartItem.quantity : 0;
          return { ...product, quantity };
        })
      : []
  );
  useEffect(() => {
    setSpinerCargando(true);
    setProductsWithQuantity(
      products.map((product) => {
        const cartItem = cart.find((item) => item.id === product.id);
        const quantity = cartItem ? cartItem.quantity : 0;
        return { ...product, quantity };
      })
    );
  }, [cart, products]);
  return (
    <Layout>
      <Grid item xs={2} sx={{ display: "flex", justifyContent: "end" }}>
        <IconButton aria-label='' onClick={() => props.history.goBack()}>
          <BackIcon />
        </IconButton>
      </Grid>
      <Typography variant='h3' color='initial' textAlign='center'>
        {category}
      </Typography>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        {productsWithQuantity.length > 0 ? (
          productsWithQuantity.map((p) => (
            <Grid item xs={12} sm={6} ms={4} lg={3} xl={3} key={p.id}>
              <CardProduct product={p} />
            </Grid>
          ))
        ) : (
          <NoDataComponents />
        )}
      </Grid>
    </Layout>
  );
};

export default ProductsCategory;
