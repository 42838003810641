import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, Box } from "@mui/material";
import ProductsContext from "../../../Context/Products/ProductsContext";
import CardProductAdmin from "../../../components/Products/CardProductAdmin";
import { Link } from "react-router-dom";
import Layout from "../../../components/Layout/Layout";
const AllProductsAdmin = () => {
  const [spinner, setSpinerCargando] = useState(false);
  const { GetAllProducts, products, totalDocs, links, totalPages } =
    useContext(ProductsContext);
  //paginacion
  const [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages };
  useEffect(() => {
    setSpinerCargando(true);
    if (params.limite !== null && params.pages !== null) {
      GetAllProducts(params);
    }
  }, [rowsPerPage, pages]);
  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ justifyContent: "end", marginTop: -10, display: "flex" }}
        >
          <Link to={"/Registrar-producto"}>
            <Button variant='contained'>Agregar</Button>
          </Link>
        </Grid>
        {products.map((product, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={4}
            xl={3}
            key={index}
            justifyContent='center'
          >
            <CardProductAdmin product={product} />
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};

export default AllProductsAdmin;
