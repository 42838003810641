import React, { useContext, useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import DashboardContext from "../../Context/Dashboard/DashboardContext";
const SalesChart = () => {
  const { ObtenerVentasMesGrafica, ventas_grafica } =
    useContext(DashboardContext);
  useEffect(() => {
    ObtenerVentasMesGrafica();
  }, []);
  const [filter, setFilter] = useState("7days");

  // Datos estáticos de ejemplo
  // const salesData = [
  //   { date: "2024-08-01", ventas: 7800 },
  //   { date: "2024-08-02", ventas: 9330 },
  //   { date: "2024-08-03", ventas: 14455 },
  //   { date: "2024-08-04", ventas: 5690 },
  //   { date: "2024-08-05", ventas: 13400 },
  //   { date: "2024-08-06", ventas: 25670 },
  //   { date: "2024-08-07", ventas: 41220 },
  //   { date: "2024-08-08", ventas: 22670 },
  //   { date: "2024-08-09", ventas: 18090 },
  //   { date: "2024-08-10", ventas: 17560 },
  //   { date: "2024-08-11", ventas: 36000 },
  //   { date: "2024-08-12", ventas: 19679 },
  //   { date: "2024-08-13", ventas: 12445 },
  //   { date: "2024-08-14", ventas: 34555 },
  //   { date: "2024-08-15", ventas: 3342 },
  //   { date: "2024-08-16", ventas: 12900 },
  //   { date: "2024-08-17", ventas: 39000 },
  //   { date: "2024-08-18", ventas: 28090 },
  //   { date: "2024-08-19", ventas: 24890 },
  //   { date: "2024-08-20", ventas: 20000 },
  //   { date: "2024-08-21", ventas: 9170 },
  //   { date: "2024-08-22", ventas: 15589 },
  //   { date: "2024-08-23", ventas: 19967 },
  //   { date: "2024-08-24", ventas: 29034 },
  //   { date: "2024-08-25", ventas: 10377 },
  //   { date: "2024-08-26", ventas: 14537 },
  //   { date: "2024-08-27", ventas: 16377 },
  //   { date: "2024-08-28", ventas: 19397 },
  //   { date: "2024-08-29", ventas: 25377 },
  // ];

  const filterData = () => {
    let days = 7; // Default to last 7 days

    switch (filter) {
      case "14days":
        days = 14;
        break;
      case "1month":
        days = 30;
        break;
      default:
        days = 7;
        break;
    }

    const endDate = dayjs();
    const startDate = endDate.subtract(days, "day");

    return ventas_grafica.filter((data) =>
      dayjs(data.date).isBetween(startDate, endDate, "day", "[]")
    );
  };

  const filteredData = filterData();

  return (
    <div style={{ width: "100%", background: "white", boxShadow: 3 }}>
      <FormControl
        variant='outlined'
        style={{ marginBottom: "20px", minWidth: 120 }}
      >
        <InputLabel>Filtrar por</InputLabel>
        <Select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          label='Filtrar por'
        >
          <MenuItem value='7days'>Últimos 7 días</MenuItem>
          <MenuItem value='14days'>Últimas 2 semanas</MenuItem>
          <MenuItem value='1month'>Último mes</MenuItem>
        </Select>
      </FormControl>

      <ResponsiveContainer width='100%' height={400}>
        <LineChart data={filteredData}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='date' />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type='monotone'
            dataKey='ventas'
            stroke='#8884d8'
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SalesChart;
