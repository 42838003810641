import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
import Layout from "../../../components/Layout/Layout";
import { AppBar, Grid } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import IndexPendingVerification from "./IndexPendingVerification";
import IndexVerificate from "./IndexVerificate";
import PreparingIndex from "./PreparingIndex";
import SendIndex from "./SendIndex";
import IndexValidatePayment from "./IndexValidatePayment";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function IndexOrdersAdmin() {
  const [value, setValue] = useState(0);
  const history = useHistory();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          display='flex'
          justifyContent='center'
        >
          <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
            <AppBar position='static'>
              <Tabs
                value={value}
                onChange={handleChange}
                //indicatorColor="secondary"
                textColor='inherit'
                //centered
                variant='scrollable'
                scrollButtons
                allowScrollButtonsMobile
                aria-label='scrollable force tabs example'
                sx={{ backgroundColor: "#FFA489" }}
              >
                <Tab label='Ventas Pendientes de pago' {...a11yProps(0)} />
                <Tab label='Ventas en validación de pago' {...a11yProps(1)} />
                <Tab label='Ventas verificadas' {...a11yProps(2)} />
                <Tab label='Ventas en preparacion' {...a11yProps(3)} />
                <Tab label='Ventas enviadas' {...a11yProps(4)} />
              </Tabs>
            </AppBar>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TabPanel value={value} index={0}>
            <IndexPendingVerification />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <IndexValidatePayment />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <IndexVerificate />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PreparingIndex />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <SendIndex />
          </TabPanel>
        </Grid>
      </Grid>
    </Layout>
  );
}
