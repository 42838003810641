import React, { useContext, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Paper,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { PersonIcon } from "../../../components/icons/PersonIcon";
import { PhoneIcon } from "../../../components/icons/PhoneIcon";
import { ShippingCostIcon } from "../../../components/icons/ShippingCostIcon";
import { DayIcon } from "../../../components/icons/DayIcon";
import { TotalIcon } from "../../../components/icons/TotalIcon";
import { DetalleIcon } from "../../../components/icons/DetalleIcon";
import { AprobePaymentIcon } from "../../../components/icons/AprobePaymentIcon";
import OrdersContext from "../../../Context/Orders/OrdersContext";
import { PackageIcon } from "../../icons/PackageIcon";
import { IconPdf } from "../../icons/IconPdf";
import { ShippIcon } from "../../icons/ShippIcon";
import ModalShipping from "../../../containers/Orders/OrdersAdmin/ModalShipping";
import AuthContext from "../../../Context/auth/AuthContext";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ModalTicketPayment from "../../Cart/ModalTicketPayment";
const CardOrder = ({ order }) => {
  const { AproveOrder, TakeOrder, PdfOrder } = useContext(OrdersContext);
  const [open, setOpen] = useState(false);
  const [order_id, setOrderId] = useState(null);
  const { usuario } = useContext(AuthContext);
  const handleClickOpen = (id) => {
    setOrderId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOrderId(null);
    setOpen(false);
  };

  const [openM, setOpenM] = React.useState(false);
  const [total, setTotal] = useState(null);
  const [envio, setEnvio] = useState(null);
  const handleClickOpenM = (id, total, envio) => {
    setOrderId(id);
    setTotal(total);
    setEnvio(envio);
    setOpenM(true);
  };
  const handleCloseM = () => {
    setOrderId(null);
    setTotal(null);
    setOpenM(false);
  };
  return (
    <>
      <Card
        sx={{
          borderTopRightRadius: 45,
          borderBottomLeftRadius: 50,
          boxShadow: 8,
        }}
      >
        <Paper
          elevation={4}
          sx={{
            width: "55%",
            mt: 2,
            bgcolor: "#FFA489",
            ml: -4,
            paddingLeft: 8,
            borderRadius: 50,
            fontSize: "40px",
          }}
        >
          #{order.id}
        </Paper>
        <CardContent>
          {/* <Paper
            elevation={3}
            sx={{
              padding: 2,
              borderTopRightRadius: 45,
              borderBottomLeftRadius: 45,
            }}
          > */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} sx={{ justifyContent: "center" }}>
              <Tooltip title='Cliente' placement='top'>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <PersonIcon width='45px' />
                  <span
                    style={{ fontSize: "20px", marginTop: 5, marginLeft: 5 }}
                  >
                    {order.name}
                  </span>
                </div>
              </Tooltip>
              {/* <Typography sx={{ fontSize: "20px" }}>{order.name}</Typography> */}
            </Grid>
            <Grid item xs={12} sm={12} sx={{ justifyContent: "center" }}>
              <Tooltip title='Telefono' placement='top'>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <PhoneIcon width='45px' />
                  <span
                    style={{ fontSize: "20px", marginTop: 5, marginLeft: 5 }}
                  >
                    {order.number_phone}
                  </span>
                </div>
              </Tooltip>
              <Typography sx={{ fontSize: "25px" }}></Typography>
            </Grid>
            <Grid item xs={12} sm={12} sx={{ justifyContent: "center" }}>
              <Tooltip title='Costo Envio' placement='top'>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <ShippingCostIcon width='45px' />
                  <span
                    style={{ fontSize: "20px", marginTop: 5, marginLeft: 5 }}
                  >
                    $ {order.envio ?? 0}
                  </span>
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={12} sx={{ justifyContent: "center" }}>
              <Tooltip title='Fecha Compra' placement='top'>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <DayIcon width='45px' />
                  <span
                    style={{ fontSize: "20px", marginTop: 5, marginLeft: 5 }}
                  >
                    {order.fecha}
                  </span>
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={12} sx={{ justifyContent: "center" }}>
              <Tooltip title='total' placement='top'>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <TotalIcon width='45px' />
                  <span
                    style={{
                      fontSize: "20px",
                      marginTop: 5,
                      fontWeight: "bold",
                      marginLeft: 5,
                    }}
                  >
                    $ {Number(order.total + order.envio).toFixed(2)}
                  </span>
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={12} sx={{ justifyContent: "center" }}>
              <Tooltip title='total' placement='top'>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  Vendido por:
                  <span
                    style={{
                      fontSize: "20px",
                      marginTop: -5,
                      fontWeight: "bold",
                      marginLeft: 5,
                    }}
                  >
                    {order.vendedor ?? "N/A"}
                  </span>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
          {/* </Paper> */}
        </CardContent>
        <Paper
          elevation={4}
          sx={{
            width: "115%",
            display: "flex",
            justifyContent: "center",
            mt: 2,
            ml: -3,
            mb: 3,
            bgcolor: "#FFBEA0",
            paddingLeft: 0,
            borderRadius: 50,
            fontSize: "40px",
          }}
        >
          <Link to={`Detalle-venta/${order.id}`}>
            <Tooltip title='Detalle venta' placement='top'>
              <IconButton>
                <DetalleIcon width='45px' />
              </IconButton>
            </Tooltip>
          </Link>
          {order.status === 1 && order.date_validate_payment === null && (
            <Tooltip title='Aprobar pago' placement='top'>
              <IconButton onClick={() => AproveOrder(order.id)}>
                <AprobePaymentIcon width='45px' />
              </IconButton>
            </Tooltip>
          )}
          {order.status === 1 && order.date_validate_payment !== null && (
            <>
              <Tooltip title='Tomar Pedido' placement='top'>
                <IconButton onClick={() => TakeOrder(order.id, usuario.name)}>
                  <PackageIcon width='45px' />
                </IconButton>
              </Tooltip>
            </>
          )}
          <Tooltip title='Descargar Detalle orden' placement='top'>
            <IconButton onClick={() => PdfOrder(order.id)}>
              <IconPdf width='45px' />
            </IconButton>
          </Tooltip>
          {order.status === 2 && (
            <>
              <Tooltip title='Enviar' placement='top'>
                <IconButton onClick={() => handleClickOpen(order.id)}>
                  <ShippIcon width='45px' />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Paper>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {order.status === 0 &&
            order.payments
              .map((payment) => payment.amount)
              .reduce((total, amount) => total + amount, 0) < order.total && (
              <Tooltip title='Subir comprobante' placement='top'>
                <IconButton
                  onClick={() =>
                    handleClickOpenM(order.id, order.total, order.envio)
                  }
                >
                  <Button variant='contained'>
                    Subir comprobante{""} <UploadFileIcon />{" "}
                  </Button>
                </IconButton>
              </Tooltip>
            )}
        </div>
      </Card>
      {order_id && (
        <ModalShipping
          open={open}
          handleClose={handleClose}
          order_id={order_id}
        />
      )}

      {order_id !== null && total !== null && (
        <ModalTicketPayment
          open={openM}
          handleClose={handleCloseM}
          order_id={order_id}
          total={total}
          envio={envio}
          total_pagado={
            order.payments
              ? order.payments
                  .map((payment) => payment.amount)
                  .reduce((total, amount) => total + amount, 0)
              : 0
          }
        />
      )}
    </>
  );
};

export default CardOrder;
