import { React, useState, useContext, useEffect, useCallback } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Button, ButtonGroup, IconButton, CardContent } from "@mui/material";
import "react-slideshow-image/dist/styles.css";
import CartContext from "../../Context/Cart/CartContext";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ReducerCartIcon } from "../icons/ReduceCartIcon";
import { AddCartIcon } from "../icons/AddCartIcon";
import { DeleteProductCartIcon } from "../icons/DeleteProductCart";
import FormatCurrency from "../../utils/FormatCurrency";
import PercentageIcon from "../icons/PercentageIcon";
const useStyles = makeStyles({
  img: {
    width: 200,
    height: 150,
  },
  jss59: {
    width: "100%",
    border: "2px solid #ffa489  ",
    height: "15%",
    outline: "none",
    padding: "10px 10px",
    borderRadius: "5px",
    justifyContent: "center",
    justifyItems: "center",
    marginTop: 12,
  },
});
export default function CardProduct({ product }) {
  const classes = useStyles();
  const { img } = product;
  const miImagen = require("../../assets/img/default.webp");

  const {
    AddProductCart,
    ReduceQuantityProduct,
    ModifyQuantityProduct,
    IncreaseQuantityProduct,
    DeleteProductCart,
  } = useContext(CartContext);

  return (
    <Card>
      <Link
        to={`/Detalle-producto/${product.id}`}
        style={{ textDecoration: "none" }}
      >
        <CardMedia
          component='img'
          height='auto'
          image={img[0]?.image ?? miImagen}
        />
        <CardContent>
          <Typography variant='subtitle1' fontWeight='bold' color='black'>
            {product.name}
          </Typography>
          <Typography variant='subtitle2' fontWeight='semibold' color='gray'>
            {product.subcategory.name}
          </Typography>
          {/* {product.subcategory.name === "kawaii" ? ( */}
          <>
            <div style={{ display: "flex" }}>
              <PercentageIcon />
              <Typography
                variant='subtitle1'
                color='black'
                fontSize={25}
                style={{
                  textDecoration: "line-through",
                }}
                // fontFamily='monospace'
              >
                {FormatCurrency(product.price)}
              </Typography>
            </div>
            <div style={{ display: "block", justifyContent: "start" }}>
              <Typography
                variant='subtitle1'
                color='black'
                fontSize={50}
                // fontFamily='monospace'
              >
                {FormatCurrency(product.price_purchase)}
              </Typography>
            </div>
          </>
          {/* ) : (

          )} */}
          <Typography align='end' color='green' fontWeight='bold'>
            Disponible: {product.stock}
          </Typography>
        </CardContent>
      </Link>
      <CardActions sx={{ display: "flex", justifyContent: "center" }}>
        {product.quantity === 0 ? (
          <Button
            variant='outlined'
            sx={{
              color: "#ffa489",
              borderColor: "#ffa489",
              "&:hover": { color: "#ffa489", borderColor: "#ffa489" },
            }}
            onClick={() => {
              AddProductCart(product.id);
            }}
          >
            Agregar
          </Button>
        ) : (
          <ButtonGroup sx={{ display: "flex", justifyContent: "center" }}>
            {product.quantity === 1 ? (
              <IconButton onClick={() => DeleteProductCart(product.id)}>
                <DeleteProductCartIcon />
              </IconButton>
            ) : (
              product.quantity > 1 && (
                <IconButton onClick={() => ReduceQuantityProduct(product.id)}>
                  <ReducerCartIcon />
                </IconButton>
              )
            )}
            <input
              className={classes.jss59}
              min={0}
              max={product.stock}
              value={product.quantity}
              disabled
              onChange={(e) => e} // Maneja el cambio de cantidad
            />
            <IconButton onClick={() => AddProductCart(product.id)}>
              <AddCartIcon />
            </IconButton>
          </ButtonGroup>
        )}
      </CardActions>
    </Card>
  );
}
