import {
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
// import Header from "../../components/Header/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MethodGet from "../../config/Service";
import { useState } from "react";
import SpinnerComponent from "../../components/loading/SpinnerComponent";
import ModalImage from "react-modal-image-responsive";
import Layout from "../../components/Layout/Layout";
import SpinnerComponentSanhoo from "../../components/loading/SpinnerComponentSanhoo";
import Direction from "./Direction";
const ShowOrder = (props) => {
  const { id } = props.match.params;
  const [order, saveOrder] = useState([]);
  const [payments, setPayments] = useState(null);
  const [client, setClient] = useState(null);
  const [products, setProducts] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [direction, setDirection] = useState(null);
  useEffect(() => {
    let url = `/Sales/${id}`;
    MethodGet(url)
      .then((res) => {
        console.log(res);
        saveOrder(res.data.venta);
        setPayments(res.data.venta.payments);
        setClient(res.data.venta.client);
        setProducts(res.data.venta.details);
        setDiscount(res.data.venta.business);
        setDirection(res.data.venta.direction);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        {/* <Header /> */}
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
          <Typography fontSize='55px' textAlign='center'>
            Detalle de venta{" "}
          </Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} sx={{ marginTop: 2 }}>
          <IconButton
            sx={{
              backgroundColor: "black",
              color: "white",
              "&:hover": { backgroundColor: "black", color: "white" },
            }}
            onClick={() => props.history.goBack()}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {order ? (
            <Card boxShadow={10} sx={{ padding: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                  <b>Fecha Compra:</b>
                  {order.fecha}{" "}
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                  <b>Folio:</b>
                  {order.id}{" "}
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                  <b>Cliente:</b>
                  {client ? client.name : ""}
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                  <b>Subtotal:</b>$ {order.subtotal}
                </Grid>
                {order.aditional_discount !== 0 && (
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                    <b>Descuento Adicional:</b> {order.aditional_discount} %
                  </Grid>
                )}
                <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                  <b>Total:</b>$ {order.total}
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                  <b>Descuento:</b>
                  {discount ? discount.discount : 0} %
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                  <b>Costo Envio:</b>$ {order.shipping_cost ?? 0}
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                  <b>Total + G.E:</b>$ {order.total + order.shipping_cost}
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                  <b>N° Guia:</b> {order.no_guide}
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                  <b>Paqueteria:</b> {order ? order.package : "N/A"}
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                  <b>Recibio apoyo de un vendedor:</b>{" "}
                  {order ? order.help_for_seller : "N/A"}
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                  <b>Requiere Factura:</b> {order ? order.invoice : "N/A"}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Divider>
                    <Chip
                      label='Dirección de envío'
                      sx={{ fontWeight: "bold" }}
                    />
                  </Divider>
                </Grid>
                <Divider />
                {direction !== null && <Direction direction={direction} />}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Divider>
                    <Chip
                      label='Detalle de pedido'
                      sx={{ fontWeight: "bold" }}
                    />
                  </Divider>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 350 }} aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <TableCell>C.Barras</TableCell>
                          <TableCell align='left'>Producto</TableCell>
                          <TableCell align='center'>cantidad</TableCell>
                          <TableCell align='center'>P.Unitario</TableCell>
                          <TableCell align='center'>subtotal</TableCell>
                          <TableCell align='center'>Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {products &&
                          products.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component='th' scope='row'>
                                {row.product.tag}
                              </TableCell>
                              <TableCell align='left'>
                                {row.product.name}
                              </TableCell>
                              <TableCell align='center'>
                                {row.quantity}{" "}
                                {row.quantity === 1 ? "pza" : "pzas"}
                              </TableCell>
                              <TableCell align='center'>
                                $ {row.product.price_purchase}
                              </TableCell>
                              <TableCell align='center'>
                                $ {row.product.price_purchase * row.quantity}
                              </TableCell>
                              <TableCell align='center'>
                                $ {row.final_price * row.quantity}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          ) : (
            <SpinnerComponentSanhoo />
          )}
        </Grid>
        {payments !== null && payments.length > 0 && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant='h4' fontWeight='bold' textAlign='center'>
                Detalle de pagos
              </Typography>
            </Grid>
            {payments.map((payment) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <Card>
                  <CardContent>
                    <Typography variant='subtitle1' fontWeight='bold'>
                      Monto Pagado: $ {payment.amount}
                    </Typography>
                    <Typography variant='subtitle1' fontWeight='bold'>
                      Referencia: {payment.reference}
                    </Typography>
                    <Typography variant='subtitle1' fontWeight='bold'>
                      Comprobante:
                    </Typography>
                    <ModalImage
                      small={payment.image}
                      large={payment.image}
                      alt={payment.reference}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Layout>
  );
};

export default ShowOrder;
