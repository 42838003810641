import React, { useEffect, useContext, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";

import DirectionsContext from "../../Context/Directions/DirectionsContext";

export default function DirectionSelect(props) {
  const { directions, GetDirections } = useContext(DirectionsContext);

  useEffect(() => {
    GetDirections();
  }, []);

  useEffect(() => {
    if (directions.length > 0) {
      const defaultDirection = directions.find(
        (direction) => direction.is_default
      );
      props.setDirection(
        props.directionId || (defaultDirection ? defaultDirection.id : "")
      );
    }
  }, [directions, props.directionId]);

  const handleChange = (e) => {
    props.setDirection(e.target.value);
    props.detectarCambiosDirection(e);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <label>Direccion de envio:</label>
        <br />
        <FormControl fullWidth>
          <Select
            label='Categorias'
            value={props.direction}
            onChange={handleChange}
          >
            {directions &&
              directions.map((direction) => (
                <MenuItem key={direction.id} value={direction.id}>
                  {direction.street}, {direction.between_street},{" "}
                  {direction.zone}, {direction.municipality.name},{" "}
                  {direction.state.name}, {direction.postal_code},{" "}
                  {direction.country},{""}{" "}
                  {direction.phone ?? "Telefono no registrado"},{" "}
                  {direction.email ?? "correo no registrado"} {""}
                  {direction.is_default ? (
                    <StarIcon sx={{ color: "gold" }} />
                  ) : null}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>Campo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
