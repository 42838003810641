export const ShippingCostIcon = ({
  fill = "currentColor",
  filled,
  size,
  height,
  width,
  label,
  ...props
}) => {
  return (
    <svg
      width={width}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      fill='#000000'
    >
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        stroke-linecap='round'
        stroke-linejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        {" "}
        <path
          style={{ fill: "#999999" }}
          d='M256,0C114.616,0,0,114.616,0,256s114.616,256,256,256s256-114.616,256-256S397.384,0,256,0z'
        ></path>{" "}
        <path
          style={{ fill: "#FFFFFF" }}
          d='M72,144.408v182.848h228.576V144.408H72z'
        ></path>{" "}
        <path d='M243.48,303.416c-17.984,0-32.576,14.448-32.576,32.256s14.592,32.256,32.576,32.256s32.576-14.448,32.576-32.256 S261.464,303.416,243.48,303.416L243.48,303.416z'></path>{" "}
        <path d='M129.096,303.416c-17.984,0-32.576,14.448-32.576,32.256s14.592,32.256,32.576,32.256s32.576-14.448,32.576-32.256 S147.08,303.416,129.096,303.416L129.096,303.416z'></path>{" "}
        <path
          style={{ fill: "#FFFFFF" }}
          d='M440,327.376v-74.592l-49.04-65.08h-55.448v139.552L440,327.376z'
        ></path>{" "}
        <path d='M387.76,303.416c-17.984,0-32.576,14.448-32.576,32.256s14.592,32.256,32.576,32.256c17.984,0,32.576-14.448,32.576-32.256 S405.744,303.416,387.76,303.416L387.76,303.416z'></path>{" "}
        <path
          style={{ fill: "#F00000" }}
          d='M185.312,108.128c-27.96,29.384-74.408,22.752-74.408,22.752s0,32.704,0,79.624 s74.408,79.624,74.408,79.624s74.408-32.704,74.408-79.624s0-79.624,0-79.624S213.28,137.512,185.312,108.128z'
        ></path>{" "}
        <path
          style={{ fill: "#FFFFFF" }}
          d='M179.552,243.608V233.28c-7.288-0.328-14.36-2.288-18.496-4.68l3.272-12.728 c4.568,2.496,10.984,4.784,18.064,4.784c6.2,0,10.448-2.392,10.448-6.744c0-4.136-3.48-6.752-11.536-9.472 c-11.64-3.912-19.592-9.352-19.592-19.904c0-9.576,6.752-17.08,18.392-19.368v-10.336h10.656v9.576 c7.288,0.328,12.192,1.84,15.776,3.592l-3.152,12.296c-2.824-1.2-7.832-3.704-15.672-3.704c-7.072,0-9.352,3.048-9.352,6.096 c0,3.592,3.808,5.872,13.056,9.352c12.944,4.568,18.168,10.552,18.168,20.352c0,9.68-6.856,17.952-19.368,20.128v11.096h-10.664 V243.608z'
        ></path>{" "}
      </g>
    </svg>
  );
};
