export const TotalIcon = ({
  fill = "currentColor",
  filled,
  size,
  height,
  width,
  label,
  ...props
}) => {
  return (
    <svg
      width={width}
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 297.5 297.5'
      fill='#000000'
    >
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        stroke-linecap='round'
        stroke-linejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        {" "}
        <g>
          {" "}
          <g id='XMLID_46_'>
            {" "}
            <g>
              {" "}
              <path
                style={{ fill: "#BDC3C7" }}
                d='M196.945,265.12c3.49,0,6.33,2.84,6.33,6.33c0,3.49-2.84,6.32-6.33,6.32h-96.4 c-3.49,0-6.33-2.83-6.33-6.32c0-3.49,2.84-6.33,6.33-6.33H196.945z'
              ></path>{" "}
              <path d='M291.895,29.26v174.91c0,15.99-13.02,29.01-29.01,29.01h-75.96l2.08,12.46h7.94c14.23,0,25.81,11.58,25.81,25.81 c0,14.22-11.58,25.8-25.81,25.8h-96.4c-14.23,0-25.8-11.58-25.8-25.8c0-14.23,11.57-25.81,25.8-25.81h7.95l2.08-12.46h-75.96 c-16,0-29.01-13.02-29.01-29.01V29.26c0-16,13.01-29.01,29.01-29.01h228.27C278.875,0.25,291.895,13.26,291.895,29.26z M272.415,204.17v-19.39H25.075v19.39c0,5.25,4.28,9.53,9.54,9.53h87.45h53.36h87.46 C268.135,213.7,272.415,209.42,272.415,204.17z M272.415,165.3v-12.03h-171.58c-5.38,0-9.74-4.36-9.74-9.74V48.8 c0-5.38,4.36-9.74,9.74-9.74h171.58v-9.8c0-5.26-4.28-9.53-9.53-9.53H34.615c-5.26,0-9.54,4.27-9.54,9.53V165.3H272.415z M272.415,133.8V58.54h-135.21c-3.47,12.95-13.68,23.17-26.64,26.64v20.46c13.77,3.54,24.62,14.39,28.16,28.16H272.415z M203.275,271.45c0-3.49-2.84-6.33-6.33-6.33h-96.4c-3.49,0-6.33,2.84-6.33,6.33c0,3.49,2.84,6.32,6.33,6.32h96.4 C200.435,277.77,203.275,274.94,203.275,271.45z M169.255,245.64l-2.07-12.46h-36.87l-2.08,12.46H169.255z M110.565,133.8h13.82 c-2.59-6.24-7.58-11.23-13.82-13.82V133.8z M122.795,58.54h-12.23v12.22C116.005,68.34,120.375,63.97,122.795,58.54z'></path>{" "}
              <path
                style={{ fill: "#BDC3C7" }}
                d='M272.415,184.78v19.39c0,5.25-4.28,9.53-9.53,9.53h-87.46h-53.36h-87.45 c-5.26,0-9.54-4.28-9.54-9.53v-19.39H272.415z'
              ></path>{" "}
              <path
                style={{ fill: "#ECF0F1" }}
                d='M272.415,153.27v12.03H25.075V29.26c0-5.26,4.28-9.53,9.54-9.53h228.27 c5.25,0,9.53,4.27,9.53,9.53v9.8h-171.58c-5.38,0-9.74,4.36-9.74,9.74v94.73c0,5.38,4.36,9.74,9.74,9.74H272.415z'
              ></path>{" "}
              <path
                style={{ fill: "#2ECC71" }}
                d='M272.415,58.54v75.26h-133.69c-3.54-13.77-14.39-24.62-28.16-28.16V85.18 c12.96-3.47,23.17-13.69,26.64-26.64H272.415z M254.775,96.17c0-3.27-2.65-5.92-5.92-5.92c-3.27,0-5.92,2.65-5.92,5.92 c0,3.27,2.65,5.92,5.92,5.92C252.125,102.09,254.775,99.44,254.775,96.17z M228.985,96.17c0-18.43-14.99-33.43-33.42-33.43 s-33.42,15-33.42,33.43s14.99,33.42,33.42,33.42S228.985,114.6,228.985,96.17z M148.195,96.17c0-3.27-2.65-5.92-5.92-5.92 c-3.27,0-5.92,2.65-5.92,5.92c0,3.27,2.65,5.92,5.92,5.92C145.545,102.09,148.195,99.44,148.195,96.17z'
              ></path>{" "}
              <circle cx='248.855' cy='96.17' r='5.92'></circle>{" "}
              <path d='M195.565,62.74c18.43,0,33.42,15,33.42,33.43s-14.99,33.42-33.42,33.42s-33.42-14.99-33.42-33.42 S177.135,62.74,195.565,62.74z M209.515,96.17c0-7.69-6.26-13.95-13.95-13.95s-13.94,6.26-13.94,13.95s6.25,13.94,13.94,13.94 S209.515,103.86,209.515,96.17z'></path>{" "}
              <path
                style={{ fill: "#A5C63B" }}
                d='M195.565,82.22c7.69,0,13.95,6.26,13.95,13.95s-6.26,13.94-13.95,13.94s-13.94-6.25-13.94-13.94 S187.875,82.22,195.565,82.22z'
              ></path>{" "}
              <polygon
                style={{ fill: "#BDC3C7" }}
                points='167.185,233.18 169.255,245.64 128.235,245.64 130.315,233.18 '
              ></polygon>{" "}
              <circle cx='142.275' cy='96.17' r='5.92'></circle>{" "}
            </g>{" "}
            <g> </g>{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};
