import React, { useContext, useEffect, useState } from "react";
import ProductsContext from "../../../Context/Products/ProductsContext";
import { useForm } from "react-hook-form";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Button, Grid, TextField } from "@mui/material";
import CardProductAdmin from "../../../components/Products/CardProductAdmin";
import NoSearchResult from "../../../components/loading/NoResultsComponent";
import SearchingComponent from "../../../components/loading/SearchingComponent";
const SearchProduct = () => {
  const { products, searchProductsAdmin } = useContext(ProductsContext); //importacion context
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  useEffect(() => {
    searchProductsAdmin(query);
  }, []);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery().get("search");
  const [palabra, setPalabra] = useState(query);
  const history = useHistory();

  const onSubmit = (data) => {
    if (data.palabra !== null) {
      history.push({
        pathname: history.location.pathname,
        search: `search=${data.palabra}`,
      });
      searchProductsAdmin(data.palabra);
      setPalabra(data.palabra);
    } else {
      history.push({
        pathname: history.location.params,
        search: `search=${data.palabra}`,
      });
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
        <Grid container spacing={2} sx={{ padding: 2, marginTop: -5 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              fullWidth
              label='Buscar Producto'
              id='filled-size-small'
              variant='outlined'
              size='large'
              name='palabra'
              defaultValue={query}
              {...register("palabra", {
                required: {
                  value: false,
                  message: "Ingresa una palabra para buscar",
                },
              })}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Button type='submit' value='Buscar' sx={{ display: "none" }}>
              Buscar
            </Button>
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={2}>
        {palabra &&
          (products.length ? (
            products.map((product, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={3}
                key={index}
                justifyContent='center'
              >
                {products.length ? (
                  <CardProductAdmin product={product} search={true} />
                ) : (
                  <SearchingComponent />
                )}
              </Grid>
            ))
          ) : (
            <NoSearchResult />
          ))}
      </Grid>
    </>
  );
};

export default SearchProduct;
