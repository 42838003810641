import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../Context/auth/AuthContext";
import CartContext from "../../Context/Cart/CartContext";
import PedidosContext from "../../Context/Pedidos/PedidosContext";
import {
  Box,
  Badge,
  AppBar,
  Toolbar,
  IconButton,
  styled,
  Tooltip,
  Container,
  Alert,
  Button,
} from "@mui/material/";
import { AvatarIcon } from "../../components/icons/AvatarIcon";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
//app
import { CartIcon } from "../icons/CartIcon";
import { ExitSesionIcon } from "../icons/ExitSesionIcon";
//app
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(2),
    marginTop: theme.spacing(14),
    marginBottom: theme.spacing(15),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  })
);
const useStyles = makeStyles({});
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid #FFA489`,
    padding: "15px",
    borderRadius: "100%",
    fontSize: "15px",
    fontWeight: "bold",
  },
}));
export default function Header({ children }) {
  const { GetCartUserLogged, cart } = useContext(CartContext);
  useEffect(() => {
    GetCartUserLogged();
  }, []);
  const { orders_client, GetPedidosPendientesDePago } =
    useContext(PedidosContext);
  useEffect(() => {
    GetPedidosPendientesDePago();
  }, []);

  let pendingPay = orders_client.filter((orders) => orders.status === 0);

  const classes = useStyles();

  const { usuario, cerrarSesion } = useContext(AuthContext);

  if (!usuario) return <> </>;
  const { name } = usuario;

  return (
    <div>
      <AppBar
        position='fixed'
        sx={{ backgroundColor: "#FFA489", height: "110px", width: "100%" }}
      >
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Link to='/' className='brand-link'>
                <>
                  <img
                    src={require("../../assets/img/merryBlack.png")}
                    alt=' '
                    className={classes.logo}
                    style={{ marginTop: 7 }}
                  />
                </>
              </Link>
            </Box>
            <Box></Box>
            <Box
              sx={{ display: { xs: "flex", md: "none" }, mr: 1, flexGrow: 1 }}
            >
              <Link to='/' className='brand-link'>
                <img
                  src={require("../../assets/img/flor contallo merry color.png")}
                  alt='AdminLTE Logo'
                  height='auto'
                  width='100px'
                />
              </Link>
            </Box>
            <Box
              sx={{
                flexGrow: 1,

                display: { xs: "none", md: "flex" },
              }}
            ></Box>
            <Box
              x={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
              }}
            >
              <Link to={"/MiCuenta"}>
                <Tooltip title='Mi Cuenta'>
                  <IconButton
                    sx={{
                      marginRight: { lg: 4, xs: 1 },
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <AvatarIcon /> {""}
                    <Box
                      component='b'
                      sx={{
                        marginTop: 2,
                        marginLeft: 1,
                        display: { xs: "none", lg: "flex" },
                      }}
                    >
                      {name}
                    </Box>
                  </IconButton>
                </Tooltip>
              </Link>
              {usuario.type_user === 1 && (
                <Link to={"/carrito"}>
                  <Tooltip title='Carrito'>
                    <IconButton
                      sx={{
                        "&:hover": {
                          color: "transparent",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <StyledBadge
                        color='secondary'
                        badgeContent={cart ? cart.length : 0}
                      >
                        <CartIcon />
                      </StyledBadge>
                    </IconButton>
                  </Tooltip>
                </Link>
              )}
              <Tooltip title='Cerrar Sesion'>
                <IconButton
                  onClick={() => cerrarSesion()}
                  sx={{
                    "&:hover": {
                      color: "transparent",
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <ExitSesionIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Main>
        {pendingPay.length > 0 && (
          <Alert
            variant='outlined'
            severity='warning'
            sx={{ display: "flex", justifyItems: "" }}
          >
            Recuerda que tienes {pendingPay.length} pendiente(s) de pagar{" "}
            <Link to={`/pedidos`}>
              <Button
                variant='contained'
                sx={{
                  backgroundColor: "#ff9800",
                  "&:hover": { backgroundColor: "#ff9800" },
                }}
              >
                ver
              </Button>
            </Link>
          </Alert>
        )}
        {children}
      </Main>
    </div>
  );
}
