import React, { useContext, useEffect, useState } from "react";
import OrdersContext from "../../../Context/Orders/OrdersContext";
import { Grid, CircularProgress } from "@mui/material";
import NoDataComponent from "../../../components/loading/NoDataComponents";
import CardOrder from "../../../components/Cards/orders/CardOrder";
import AuthContext from "../../../Context/auth/AuthContext";
import SearchBar from "../../../components/Search/SearchOrder";

const SendIndex = () => {
  const { usuario } = useContext(AuthContext);
  const { type_user, name } = usuario;
  const { orders, GetAllOrdersShipping, GetAllOrdersShippingSeller } =
    useContext(OrdersContext);

  const [filteredData, setFilteredData] = useState(orders);
  const [isLoading, setIsLoading] = useState(false); // Estado para el spinner

  useEffect(() => {
    if (type_user === 0) {
      GetAllOrdersShipping();
    } else if (type_user === 2) {
      GetAllOrdersShippingSeller(name);
    } else if (type_user === 3) {
      GetAllOrdersShipping();
    }
  }, []);

  // Función para manejar los resultados de búsqueda
  const handleSearchResults = (results) => {
    setFilteredData(results);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SearchBar
          orders={orders}
          onSearch={handleSearchResults}
          setIsLoading={setIsLoading} // Pasa setIsLoading correctamente aquí
        />
      </Grid>
      {isLoading ? (
        <Grid item xs={12} style={{ textAlign: "center", marginTop: "20px" }}>
          <CircularProgress />
        </Grid>
      ) : filteredData.length > 0 ? (
        filteredData.map((order) => (
          <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={order.id}>
            <CardOrder order={order} />
          </Grid>
        ))
      ) : (
        <NoDataComponent />
      )}
    </Grid>
  );
};

export default SendIndex;
