export const types = {
  MOSTRAR_ALERTA: "[AUTH] mostrar alerta",
  OCULTAR_ALERTA: "[AUTH] ocultar alerta",
  LOGIN_ERROR: "[AUTH] login error",
  LOGIN_EXITOSO: "[AUTH] login existoso",
  REGISTRO_EXITOSO: "[AUTH] registro exitoso",
  REGISTRO_ERROR: "[AUTH registro error]",
  OBTENER_USUARIO: "[AUTH] obtener usuario",
  CERRAR_SESION: "[AUTH] cerrar sesion",
  INICIAR_SESION: "[AUTH] inicia sesion",
  USER_CHANGEPHOTO: "[AUTH] USER_CHANGEPHOTO",
  RESET_PASSWORD: "[AUTH] RESET_PASSWORD",
};

export const GET_ALL_ORDER = "GET_ALL_ORDERS";
export const MAKE_AS_READY = "MAKE_AS_READY";
export const CREATE_ORDER = "CREATE_ORDER";
export const APROVE_ORDER = "APROVE_ORDER";
export const TAKE_ORDER = "TAKE_ORDER";
export const GET_ALL_ORDERS_COMPLETED = "GET_ALL_ORDER_COMPLETED";
export const GET_ALL_ORDERS_VALIDATING_PAYMENT =
  "GET_ALL_ORDERS_VALIDATING_PAYMENT";
export const GET_ALL_ORDERS_PREPARING = "GET_ALL_ORDERS_PREPARING";
export const GET_ALL_ORDERS_SHIPPING = "GET_ALL_ORDERS_SHIPPING";
export const GET_ALL_ORDERS_PENDING = "GET_ALL_ORDERS_PENDING";
export const GET_ALL_ORDERS_APROVE = "GET_ALL_ORDERS_APROVE";

/**vendedores */
export const GET_SALES_PREPARING_FOR_SELLER = "GET_SALES_PREPARING_FOR_SELLER";
export const GET_SALES_SHIPPING_FOR_SELLER = "GET_SALES_SHIPPING_FOR_SELLER";

export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_PRODUCTS_PAGINATION = "GET_ALL_PRODUCTS_PAGINATION";
export const GET_PRODUCTS_WITHOUT_IMAGE = "GET_PRODUCTS_WITHOUT_IMAGE";
export const GET_PRODUCTS_WITH_STOCK_LOW = "GET_PRODUCTS_WITH_STOCK_LOW";
export const PRODUCTS_NO_AUTH = "PRODUCTS_NO_AUTH";
export const ADD_MULTIMEDIA_PRODUCT = "ADD_MULTIMEDIA_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const PRODUCTS_CATEGORY = "PRODUCTS_CATEGORY";
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const SEARCH_PRODUCTS_ADMIN = "SEARCH_PRODUCTS_ADMIN";
export const INCREASE_STOCK_PRODUCT = "INCREASE_STOCK_PRODUCT";
export const GET_PRODUCTS_DESACTIVATE = "GET_PRODUCTS_DESACTIVATE";
/**Entradas de productos */
export const CREATE_ENTRY_PRODUCTS = "CREATE_ENTRY_PRODUCTS";
export const GET_ENTRY_PRODUCTS = "GET_ENTRY_PRODUCTS";

export const GET_CART_USER = "GET_CART_USER";
export const ADD_PRODUCT_CART = "ADD_PRODUCT_CART";
export const REDUCE_QUANTITY_PRODUCT_CART = "REDUCE_QUANTITY_PRODUCT_CART";
export const INCREASE_QUANTITY_PRODUCT_CART = "INCREASE_QUANTITY_PRODUCT_CART";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const MODIFY_QUANTITY_PRODUCT = "MODIFY_QUANTITY_PRODUCT";
export const GET_TOTAL_CART = "GET_TOTAL_CART";
export const GET_DISCOUNT = "GET_DISCOUNT";

/**Categorias */
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const STORE_CATEGORY = "STORE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

/**Subcategorias */
export const GET_ALL_SUBCATEGORIES = "GET_ALL_SUBCATEGORIES";
export const STORE_SUBCATEGORY = "STORE_SUBCATEGORY";
export const UPDATE_SUBCATEGORY = "UPDATE_SUBCATEGORY";
export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY";

/***Ordenes por usuario */
export const GET_ALL_ORDERS_PENDING_USER = "GET_ALL_ORDERS_PENDING_USER";
export const GET_ALL_ORDERS_COMPLETED_USER = "GET_ALL_ORDERS_COMPLETED_USER";
export const UPLOAD_TICKET = "UPLOAD_TICKET";
export const PEDIDOS_ENVIADOS = "PEDIDOS_ENVIADOS";
export const PEDIDOS_EN_PREPARACION = "PEDIDOS_EN_PREPARACION";
export const PEDIDOS_PENDIENTES_DE_PAGO = "PEDIDOS_PENDIENTES_DE_PAGO";
export const PEDIDOS_CANCELADOS = "PEDIDOS_CANCELADOS";
export const VALIDANDO_PAGO_PEDIDO = "VALIDANDO_PAGO_PEDIDO";
export const RANKING_SALES_MUNICIPALITIES = "RANKING_SALES_MUNICIPALITIES";
/**AYUDA */
export const GET_ALL_HELP = "GET_ALL_HELP";
/**direcciones */
export const GET_ALL_DIRECTIONS_USER = "GET_ALL_DIRECTIONS_USER";
export const STORE_DIRECTION = "STORE_DIRECTION";
export const UPDATE_DIRECTION = "UPDATE_DIRECTION";
export const DELETE_DIRECTION = "DELETE_DIRECTION";
export const SET_DIRECTION_DEFAULT = "SET_DIRECTION_DEFAULT";
/**Brands */
export const GET_ALL_BRANDS = "GET_ALL_BRANDS";
export const CREATE_BRAND = "CREATE_BRAND";
export const DELETE_BRAND = "DELETE_BRAND";
export const PRODUCTS_BRAND = "PRODUCTS_BRAND";
//Dasboard
export const VENTAS_DEL_DIA = "VENTAS_DEL_DIA";
export const VENTAS_DE_LA_SEMANA = "VENTAS_DE_LA_SEMANA";
export const VENTAS_DEL_MES = "VENTAS_DEL_MES";
export const INGRESOS_DEL_DIA = "INGRESOS_DEL_DIA";
export const VENTAS_GRAFICA = "VENTAS_GRAFICA";
//Errors API
export const SHOW_ERRORS_API = "SHOW_ERRORS_API";
//funcion de los puntos en Lambda
export const CREATE_CLIENT = "CREATE_CLIENT";
export const ADD_POINTS = "ADD_POINTS";
export const DECREASE_POINTS = "DECREASE_POINTS";
export const GET_INFO = "GET_INFO";
