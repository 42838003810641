export const CartIcon = ({
  fill = "currentColor",
  filled,
  size,
  height,
  width,
  label,
  ...props
}) => {
  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 58 58'
      fill='#000000'
      width='50px'
      style={{ marginTop: 20 }}
    >
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        stroke-linecap='round'
        stroke-linejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        {" "}
        <g>
          {" "}
          <g>
            {" "}
            <rect
              x='9'
              y='17.5'
              style={{ fill: "#D1D4D1" }}
              width='48'
              height='24'
            ></rect>{" "}
            <path
              style={{ fill: "#556080" }}
              d='M58,42.5H8v-26h50V42.5z M10,40.5h46v-22H10V40.5z'
            ></path>{" "}
          </g>{" "}
          <path
            style={{ fill: "#556080" }}
            d='M9,9.5H4c-0.552,0-1-0.447-1-1s0.448-1,1-1h5c0.552,0,1,0.447,1,1S9.552,9.5,9,9.5z'
          ></path>{" "}
          <path
            style={{ fill: "#556080" }}
            d='M9,18.5c-0.552,0-1-0.447-1-1v-9c0-0.553,0.448-1,1-1s1,0.447,1,1v9C10,18.053,9.552,18.5,9,18.5z'
          ></path>{" "}
          <path
            style={{ fill: "#556080" }}
            d='M9,49.5c-0.552,0-1-0.447-1-1v-7c0-0.553,0.448-1,1-1s1,0.447,1,1v7C10,49.053,9.552,49.5,9,49.5z'
          ></path>{" "}
          <circle style={{ fill: "#556080" }} cx='19' cy='53.5' r='4'></circle>{" "}
          <circle style={{ fill: "#556080" }} cx='43' cy='53.5' r='4'></circle>{" "}
          <path
            style={{ fill: "#556080" }}
            d='M52,49.5H9c-0.552,0-1-0.447-1-1s0.448-1,1-1h43c0.552,0,1,0.447,1,1S52.552,49.5,52,49.5z'
          ></path>{" "}
          <path
            style={{ fill: "#556080" }}
            d='M57,26.5H9c-0.552,0-1-0.447-1-1s0.448-1,1-1h48c0.552,0,1,0.447,1,1S57.552,26.5,57,26.5z'
          ></path>{" "}
          <path
            style={{ fill: "#556080" }}
            d='M57,34.5H9c-0.552,0-1-0.447-1-1s0.448-1,1-1h48c0.552,0,1,0.447,1,1S57.552,34.5,57,34.5z'
          ></path>{" "}
          <path
            style={{ fill: "#556080" }}
            d='M17,42.5c-0.552,0-1-0.447-1-1v-24c0-0.553,0.448-1,1-1s1,0.447,1,1v24 C18,42.053,17.552,42.5,17,42.5z'
          ></path>{" "}
          <path
            style={{ fill: "#556080" }}
            d='M25,42.5c-0.552,0-1-0.447-1-1v-24c0-0.553,0.448-1,1-1s1,0.447,1,1v24 C26,42.053,25.552,42.5,25,42.5z'
          ></path>{" "}
          <path
            style={{ fill: "#556080" }}
            d='M33,42.5c-0.552,0-1-0.447-1-1v-24c0-0.553,0.448-1,1-1s1,0.447,1,1v24 C34,42.053,33.552,42.5,33,42.5z'
          ></path>{" "}
          <path
            style={{ fill: "#556080" }}
            d='M41,42.5c-0.552,0-1-0.447-1-1v-24c0-0.553,0.448-1,1-1s1,0.447,1,1v24 C42,42.053,41.552,42.5,41,42.5z'
          ></path>{" "}
          <path
            style={{ fill: "#556080" }}
            d='M49,42.5c-0.552,0-1-0.447-1-1v-24c0-0.553,0.448-1,1-1s1,0.447,1,1v24 C50,42.053,49.552,42.5,49,42.5z'
          ></path>{" "}
          <circle style={{ fill: "#D8625E" }} cx='3' cy='8.5' r='3'></circle>{" "}
          <rect
            x='14'
            y='8.5'
            style={{ fill: "#F0C419" }}
            width='14'
            height='8'
          ></rect>{" "}
          <rect
            x='28'
            y='0.5'
            style={{ fill: "#8697CB" }}
            width='16'
            height='16'
          ></rect>{" "}
          <rect
            x='44'
            y='3.5'
            style={{ fill: "#FB7B76" }}
            width='10'
            height='13'
          ></rect>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};
