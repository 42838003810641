import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DirectionsContext from "../../Context/Directions/DirectionsContext";
import { useContext } from "react";
import { Grid } from "@mui/material";
import StateSelect from "../SelectOptions/StateSelect";
import MunicipalitySelect from "../SelectOptions/MunicipalitySelect";
import { useState } from "react";
import Swal from "sweetalert2";
export default function AddDirection({ open, modal, handleClose }) {
  const { storeDirection } = useContext(DirectionsContext);
  const [state, setState] = useState(null);
  const [municipality, setMunicipality] = useState(null);
  const detectarCambiosState = (e) => {
    setState(e.target.value);
  };
  const detectarCambiosMunicipality = (e) => {
    setMunicipality(e.target.value);
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const name = formJson.name;
            const country = formJson.country;
            const street = formJson.street;
            const between_street = formJson.between_street;
            const and_between_street = formJson.and_between_street;
            const number_int = formJson.number_int;
            const number_ext = formJson.number_ext;
            const city = formJson.city;
            const zone = formJson.zone;
            const postal_code = formJson.postal_code;
            const references = formJson.references;
            const email = formJson.email;
            const phone = formJson.phone;
            const data = {};
            data.name = name;
            data.street = street;
            data.country = country;
            data.between_street = between_street;
            data.and_between_street = and_between_street;
            data.number_int = number_int;
            data.number_ext = number_ext;
            data.state_id = state;
            data.municipality_id = municipality;
            data.city = city;
            data.zone = zone;
            data.postal_code = postal_code;
            data.phone = phone;
            data.email = email;
            data.references = references;
            if (state === null) {
              handleClose();
              Swal.fire({
                title: "¡Cuidado!",
                text: "El campo estado es requerido",
                timer: 2000,
                showConfirmButton: false,
                icon: "warning",
              });
              return;
            }
            if (municipality === null) {
              handleClose();
              Swal.fire({
                title: "¡Cuidado!",
                text: "El campo municipio es requerido",
                timer: 2000,
                showConfirmButton: false,
                icon: "warning",
              });
              return;
            }
            storeDirection(data);
            handleClose();
          },
        }}
      >
        <DialogTitle>Agregar nueva dirección</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>
              Todos los campos con <b>*</b> son obligatorios
            </p>
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField
                fullWidth
                label='Nombre de direccion'
                name='name'
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField fullWidth label='Pais' name='country' required />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField fullWidth label='Calle' name='street' required />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField
                fullWidth
                label='Entre calle'
                name='between_street'
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField
                fullWidth
                label='Y entre calle'
                name='and_between_street'
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField fullWidth label='Numero Interior' name='number_int' />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField
                fullWidth
                label='Numero ext.'
                name='number_ext'
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <StateSelect detectarCambiosState={detectarCambiosState} />
            </Grid>
            {state !== null && (
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <MunicipalitySelect
                  detectarCambiosMunicipality={detectarCambiosMunicipality}
                  state_id={state}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField fullWidth label='Ciudad*' name='city' required />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField fullWidth label='zona / barrio' name='zone' required />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField
                fullWidth
                label='Codigo Postal'
                name='postal_code'
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField
                fullWidth
                label='Numero de telefono'
                name='phone'
                placeholder='se utiliza para paqueteria'
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField
                fullWidth
                label='Correo Electronico'
                name='email'
                placeholder='se utiliza para paqueteria'
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                fullWidth
                label='referencias'
                name='references'
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='error' variant='contained'>
            Cancelar
          </Button>
          <Button type='submit' color='success' variant='contained'>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
