import { Route, Switch } from "react-router-dom";
import Products from "../containers/Products/Index";
import Categories from "../containers/Categorias/Categories";
import IndexCategoriesAdmin from "../containers/Categorias/CategoriasAdmin/IndexCategoriasAdmin";
import IndexProductsAdmin from "../containers/Products/ProductsAdmin/IndexProductsAdmin";
import AyudaAdmin from "../containers/Ayuda/AyudaAdmin/IndexAyudaAdmin";
import OrdersAdmin from "../containers/Orders/OrdersAdmin/IndexOrdersAdmin";
import InicioAdmin from "../containers/Admin/InicioAdmin";
import Ayuda from "../containers/Ayuda/Ayuda";
import Directions from "../containers/Directions/Directions";
import ProductsList from "../containers/ProductsList/ProductsList";
import EditarProduct from "../containers/Products/ProductsAdmin/EditarProduct";
import ShowOrder from "../containers/Control/ShowOrder";
import ProductsCategory from "../containers/Categorias/ProductsCategory";
import StoreProduct from "../containers/Products/ProductsAdmin/StoreProduct";
import IndexPedidos from "../containers/Pedidos/IndexPedidos";
import ProductsShow from "../containers/Products/ProductsShow";
import Brands from "../containers/Brands/Brands";
import ProductsBrand from "../containers/Brands/ProductsBrand";
import BrandsAdmin from "../containers/Brands/BrandsAdmin/Index";
import Discounts from "../containers/Discounts/Discounts";
import Information from "../containers/Admin/ContactInformation/Information";
import Account from "../containers/Account/Account";
import CompanyInformation from "../containers/Admin/ContactInformation/CompanyInformation";
import Reports from "../containers/Admin/Reports/Reports";
import Policies from "../containers/Policies";
import Devoluciones from "../containers/Devoluciones";
import IndexVerificate from "../containers/Orders/OrdersAdmin/IndexVerificate";
import IndexOrdersSeller from "../containers/Orders/OrdersAdmin/OrdersSellers/IndexOrdersSeller";
import Points from "../containers/Points/Points";
import IndexCont from "../containers/Orders/OrdersContabilidad/IndexCont";
export const EcommerceRoutes = () => {
  const type_user = localStorage.getItem("type_user");
  let component = Products;

  if (type_user == 0) {
    component = InicioAdmin;
  } else if (type_user == 2) {
    component = IndexOrdersSeller;
  } else if (type_user == 3) {
    component = IndexCont;
  }

  return (
    <Switch>
      {/**Rutas Admin */}
      <Route exact path='/inicio-admin' component={InicioAdmin} />
      <Route exact path='/TomarPedidos' component={IndexVerificate} />
      <Route exact path='/Marcas-admin' component={BrandsAdmin} />
      <Route exact path='/categorias-admin' component={IndexCategoriesAdmin} />
      <Route exact path='/productos-admin' component={IndexProductsAdmin} />
      <Route exact path='/ayuda-admin' component={AyudaAdmin} />
      <Route exact path='/ventas-admin' component={OrdersAdmin} />
      <Route exact path='/Editar-producto/:id' component={EditarProduct} />
      <Route exact path='/Registrar-producto' component={StoreProduct} />
      <Route exact path='/informacion-Contacto' component={Information} />
      <Route exact path='/Reportes-Admin' component={Reports} />
      {/**Rutas suario */}
      <Route exact path='/' component={component} />
      <Route exact path='/categorias' component={Categories} />
      <Route exact path='/direcciones' component={Directions} />
      <Route exact path='/pedidos' component={IndexPedidos} />
      <Route exact path='/ayuda' component={Ayuda} />
      <Route exact path='/Carrito' component={ProductsList} />
      <Route exact path='/Detalle-venta/:id' component={ShowOrder} />
      <Route exact path='/Descuentos' component={Discounts} />
      <Route exact path='/Detalle-producto/:id' component={ProductsShow} />
      <Route
        exact
        path='/productos/:id/:category'
        component={ProductsCategory}
      />
      <Route exact path='/Marcas' component={Brands} />
      <Route exact path='/productos/:name' component={ProductsBrand} />
      <Route exact path='/MiCuenta' component={Account} />
      <Route exact path='/society' component={CompanyInformation} />
      <Route exact path='/puntos' component={Points} />
      <Route
        exact
        path='/Terminos-condiciones-MerryColor'
        component={Policies}
      />
      <Route
        exact
        path='/Terminos-condiciones-Reembolsos-Devoluciones-MerryColor'
        component={Devoluciones}
      />
    </Switch>
  );
};
