import * as React from "react";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import { CardMedia, CardContent, Typography } from "@mui/material";
import "react-slideshow-image/dist/styles.css";
import ModalImage from "react-modal-image-responsive";
import TextoCorto from "../../utils/TextoCorto";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const useStyles = makeStyles({
  img: {
    width: 200,
    height: 150,
  },
  jss59: {
    width: "20%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    marginLeft: "10px",
    borderRadius: "10px",
  },
});

export default function ProductCartNoAuth({ product }) {
  const classes = useStyles();

  const { img } = product;
  const miImagen = require("../../assets/img/default.webp");

  return (
    <Card>
      <Link to={`/products/${product.id}`} style={{ textDecoration: "none" }}>
        <CardMedia
          component='img'
          height='auto'
          image={img[0]?.image ?? miImagen}
        />
        <CardContent>
          <Typography variant='subtitle1' fontWeight='bold' color='black'>
            {product.name}
          </Typography>
          <Typography variant='subtitle2' fontWeight='semibold' color='gray'>
            {product.subcategory.name}
          </Typography>
          <Typography
            variant='subtitle1'
            // fontFamily='monospace'
            sx={{
              "&::before": {
                content: '"$"', // Cambia esto al símbolo de tu moneda
                marginRight: "4px",
              },
              fontWeight: "bold",
              color: "#333",
            }}
          >
            {product.price_purchase.toFixed(2)}
          </Typography>
          <Typography align='end' color='green' fontWeight='bold'>
            Disponible: {product.stock}
          </Typography>
        </CardContent>
      </Link>
    </Card>
  );
}
