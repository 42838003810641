export const SalesWeekIcon = ({
  fill = "currentColor",
  filled,
  size,
  height,
  width,
  label,
  ...props
}) => {
  return (
    <svg
      height="200px"
      width="200px"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 53.079 53.079"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g>
          {" "}
          <path
            style={{ fill: "#3d94e6" }}
            d="M51.351,15.822c-0.957-2.953-2.267-5.063-5.063-5.063H41.96V8.518c0-2.099-2.059-3.742-4.688-3.742 c-2.448,0-4.4,2.572-4.657,5.983h-8.792V8.518c0-2.099-2.059-3.742-4.688-3.742c-2.448,0-4.4,2.572-4.657,5.983H6.919 c-2.796,0-4.234,2.29-5.064,5.063c0,0-1.855,6.27-1.855,14.709S1.855,43.24,1.855,43.24c0.865,2.831,2.268,5.063,5.064,5.063 h39.368c2.796,0,4.178-2.203,5.063-5.063c0,0,1.811-4.945,1.726-12.709C53.03,21.838,51.351,15.822,51.351,15.822z M42.377,26.115 c1.104,0,2,0.895,2,2v2.832c0,1.105-0.896,2-2,2H39.21c-1.104,0-2-0.895-2-2v-2.832c0-1.105,0.896-2,2-2H42.377z M37.272,7.775 c1.107,0,1.688,0.552,1.688,0.742v2.241h-3.333C35.823,8.939,36.68,7.775,37.272,7.775z M19.135,7.775 c1.107,0,1.688,0.552,1.688,0.742v2.241H17.49C17.686,8.939,18.543,7.775,19.135,7.775z M7.836,28.114c0-1.105,0.896-2,2-2h3.167 c1.104,0,2,0.896,2,2v2.833c0,1.104-0.896,2-2,2H9.836c-1.104,0-2-0.896-2-2C7.836,30.947,7.836,28.114,7.836,28.114z M15.094,40.822c0,1.104-0.895,2-2,2H9.928c-1.105,0-2-0.896-2-2v-2.833c0-1.104,0.895-2,2-2h3.166c1.105,0,2,0.896,2,2V40.822z M13.032,16.775c0-1.06,0.571-1.979,1.416-2.488v2.488c0,0.828,0.671,1.5,1.5,1.5s1.5-0.672,1.5-1.5v-2.488 c0.845,0.51,1.416,1.429,1.416,2.488c0,1.61-1.306,2.916-2.916,2.916S13.032,18.385,13.032,16.775z M17.502,28.115 c0-1.105,0.896-2,2-2h3.167c1.104,0,2,0.895,2,2v2.832c0,1.105-0.896,2-2,2h-3.167c-1.104,0-2-0.895-2-2V28.115z M24.76,40.822 c0,1.105-0.895,2-2,2h-3.166c-1.105,0-2-0.895-2-2V37.99c0-1.105,0.895-2,2-2h3.166c1.105,0,2,0.895,2,2V40.822z M27.585,28.115 c0-1.105,0.896-2,2-2h3.167c1.104,0,2,0.895,2,2v2.832c0,1.105-0.896,2-2,2h-3.167c-1.104,0-2-0.895-2-2V28.115z M34.843,40.822 c0,1.105-0.895,2-2,2h-3.166c-1.105,0-2-0.895-2-2V37.99c0-1.105,0.895-2,2-2h3.166c1.105,0,2,0.895,2,2V40.822z M34.085,19.691 c-1.61,0-2.916-1.306-2.916-2.916c0-1.06,0.571-1.979,1.416-2.488v2.488c0,0.828,0.671,1.5,1.5,1.5s1.5-0.672,1.5-1.5v-2.488 c0.845,0.51,1.416,1.429,1.416,2.488C37.001,18.385,35.695,19.691,34.085,19.691z"
          ></path>{" "}
          <path
            style={{ fill: "#3d94e6" }}
            d="M37.573,42.693v-4.418c0-1.104,0.895-2,2-2h4.583L37.573,42.693z"
          ></path>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};
