import React, { useReducer } from "react";
import AuthContext from "./AuthContext";
import AuthReducer from "./AuthReducer";
import MethodGet, { MethodPost, MethodPut } from "../../config/Service";
import headerConfig from "../../config/imageHeaders";
import Swal from "sweetalert2";

/**Importar componente token headers */
import tokenAuth from "../../config/TokenAuth";

import { SHOW_ERRORS_API, types } from "../../types";

const AuthState = (props) => {
  //Agregar state inicial
  const initialState = {
    token: localStorage.getItem("token"),
    autenticado: false,
    usuario: {},
    cargando: true,
    success: false,
    directions: [],
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);
  //funciones
  //Retorna el usuario autenticado
  const usuarioAutenticado = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      tokenAuth(token);
    }

    MethodGet("/client")
      .then(({ data }) => {
        dispatch({
          type: types.OBTENER_USUARIO,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.LOGIN_ERROR,
        });
      });
  };

  const resetPassword = (data) => {
    let url = "/reset-password";
    MethodPost(url, data)
      .then((res) => {
        Swal.fire({
          Title: "Actualizada!",
          text: "La contraseña se ha restablecido correctamente!",
          icon: "success",
          timer: 2500,
          showConfirmButton: false,
        }).then(function () {
          window.location = "/iniciar-sesion";
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "Ocurrio un error al recuperar la contraseña, intenta más tarde!",
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };

  const registerUser = (data) => {
    console.log(data, "la data que se envia");
    let url = "/auth/register";
    MethodPost(url, data)
      .then((res) => {
        dispatch({
          type: types.REGISTRO_EXITOSO,
          payload: res.data,
        });
        Swal.fire({
          title: "Registrado",
          text: "Te has registrado de manera exitosa",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          showConfirmButton: false,
        });
      });
  };

  //cuando el usuario inicia sesion
  const iniciarSesion = (datos) => {
    let url = "/auth/login";
    MethodPost(url, datos)
      .then((res) => {
        dispatch({
          type: types.LOGIN_EXITOSO,
          payload: res.data,
        });
        usuarioAutenticado();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: error.response.data.message,
        });
        dispatch({
          type: SHOW_ERRORS_API,
        });
      });
  };

  //cuando el usuario Ccambia de contraseña
  const ChangePasswordUser = (datos) => {
    let url = "/admin/auth/changePassword";
    MethodPost(url, datos)
      .then((res) => {
        Swal.fire({
          title: "Contraseña!",
          text: "Modificada Correctamente",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch({
          type: types.USER_CHANGEPASSWORD,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
        dispatch({
          type: SHOW_ERRORS_API,
        });
      });
  };

  //Cambiar Imagen de Perfil
  const ChangePhoto = (datos) => {
    let url = "/admin/auth/update-profile-image";
    const formData = new FormData();
    formData.append("profileImage", datos.image);
    MethodPut(url, formData, { headerConfig })
      .then((res) => {
        Swal.fire({
          title: "Usuario!!",
          text: res.data.message,
          timer: 3000,
          showConfirmButton: false,
          icon: "success",
        });
        dispatch({
          type: types.USER_CHANGEPHOTO,
          payload: res.data,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: error.response.data.message,
        });
        dispatch({
          type: SHOW_ERRORS_API,
        });
      });
  };

  //Cierrra sesion del usuario
  const cerrarSesion = () => {
    dispatch({
      type: types.CERRAR_SESION,
    });
  };

  const eliminarCuenta = (id) => {
    Swal.fire({
      title: "Eliminar mi cuenta",
      allowOutsideClick: false,
      html: `
      <label>Ingresa el texto <b>Eliminar mi cuenta</b></label>
      <input type="text" id="delete" class="swal2-input" placeholder="Eliminar mi cuenta">`,
      confirmButtonText: "Confirmar",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      focusConfirm: false,
      preConfirm: () => {
        var delete_account = Swal.getPopup().querySelector("#delete").value;
        if (!delete_account) {
          Swal.showValidationMessage(
            `Por favor ingresa ingresa el texto para confirmar`
          );
        } else if (delete_account !== "Eliminar mi cuenta") {
          Swal.showValidationMessage(
            `El texto ingresado debe ser igual a Eliminar mi cuenta`
          );
        }
        return { delete_account: delete_account };
      },
    }).then((result) => {
      if (result.value) {
        // console.log(result.value);
        // delete_account = result.value.delete_account;
        let url = `/cliente/eliminar/${id}`;
        const formData = new FormData();
        formData.append("argument", result.value.delete_account);
        MethodPost(url, formData)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: `Eliminado `,
              text: "Su cuenta se ha borrado correctamente!",
              timer: 1500,
              showConfirmButton: false,
            });
            cerrarSesion();
            // dispatch({
            //   type: INCREASE_STOCK_PRODUCT,
            //   payload: res.data,
            // });
          })
          .catch((error) => {
            Swal.fire({
              Title: "Error",
              icon: "error",
              text: error.response.data.message,
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };
  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        autenticado: state.autenticado,
        usuario: state.usuario,
        success: state.success,
        cargando: state.cargando,
        directions: state.directions,
        iniciarSesion,
        usuarioAutenticado,
        cerrarSesion,
        registerUser,
        ChangePasswordUser,
        ChangePhoto,
        resetPassword,
        eliminarCuenta,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
