import { Box, Button, Card, Grid, Tooltip, Typography } from "@mui/material";
import React, { useContext } from "react";
import ModalImage from "react-modal-image-responsive";
import { EditIcon } from "../../icons/EditIcon";
import { DeleteIcon } from "../../icons/DeleteIcon";
import { useState } from "react";
import EditCategory from "../../../containers/Categorias/CategoriasAdmin/EditCategory";
import CategoriesContext from "../../../Context/Categories/CategoriesContext";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  titleProduct: {
    color: "white",
  },
  ojito: {
    color: "red",
  },
  imgproduct: {
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
  },
});

const CardCategory = ({ category }) => {
  const { DeleteCategory } = useContext(CategoriesContext);
  const image = require("../../../assets/img/default .png");
  /**modal actualiza */
  const [modalUpdate, abrirModalUpdate] = useState(false);
  const [category_id, setCategory_id] = useState(null);
  const handleClickOpenModalUpdate = (id) => {
    setCategory_id(id);
    abrirModalUpdate(true);
  };
  const handeCloseModalUpdate = () => {
    abrirModalUpdate(false);
    setCategory_id(null);
  };
  const classes = useStyles();
  return (
    <Card>
      <Box
        fullWidth
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        sx={{
          backgroundColor: "white",
          color: "black",
        }}
      >
        <Typography
          variant='su arrowbtitle2'
          noWrap
          sx={{ fontWeight: "bold", fontSize: "17px", m: 2 }}
          className='blink-1'
        >
          {category.name}
        </Typography>
      </Box>
      <Box sx={{ width: "100%", display: "flex" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box sx={{ pt: "100%", position: "relative" }}>
              {category.image ? (
                <ModalImage
                  className={classes.imgproduct}
                  small={category.image}
                  large={category.image}
                  alt={category.name}
                />
              ) : (
                <ModalImage
                  className={classes.imgproduct}
                  small={image}
                  large={image}
                  alt={category.name}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={4} display='flex' justifyContent='center'>
          <Tooltip title='Editar' placement='top'>
            <Button onClick={() => handleClickOpenModalUpdate(category.id)}>
              <EditIcon />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={4} display='flex' justifyContent='center'>
          <Tooltip title='Eliminar' placement='top'>
            <Button onClick={() => DeleteCategory(category.id)}>
              <DeleteIcon />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      {category_id && (
        <EditCategory
          modal={modalUpdate}
          handleClose={handeCloseModalUpdate}
          id={category_id}
        />
      )}
    </Card>
  );
};

export default CardCategory;
