import React from "react";
import {
  INGRESOS_DEL_DIA,
  RANKING_SALES_MUNICIPALITIES,
  VENTAS_DEL_DIA,
  VENTAS_DEL_MES,
  VENTAS_DE_LA_SEMANA,
  VENTAS_GRAFICA,
} from "../../types";

export default function DashboardReducer(state, action) {
  switch (action.type) {
    case RANKING_SALES_MUNICIPALITIES:
      return {
        ...state,
        salesMunicipality: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case VENTAS_DEL_DIA:
      return {
        ...state,
        ventas_del_dia: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case VENTAS_DE_LA_SEMANA:
      return {
        ...state,
        ventas_de_la_semana: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case VENTAS_DEL_MES:
      return {
        ...state,
        ventas_mes: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case INGRESOS_DEL_DIA:
      return {
        ...state,
        ingresos_dia: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case VENTAS_GRAFICA:
      return {
        ...state,
        ventas_grafica: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    default:
      return state;
  }
}
