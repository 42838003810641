import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useForm } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AuthContext from "../../Context/auth/AuthContext";
import { useFormik } from "formik";

const useStyles = makeStyles({
  textlogin: {
    fontSize: "15px",
    color: "black",
    fontWeight: "bold",
    fontStyle: "oblique",
    letterSpacing: "1px",
  },
  caja: {
    background: "#FFBEA0",
    borderRadius: "600px 10px 600px 10px",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(9.2px)",
    border: "0px solid rgba(53, 70, 93, 0.3)",
  },
  caja1: {
    background: "rgba(255, 255, 255, 0.2)",
    borderRadius: "16px",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropfilter: "blur(5px)",
    border: "1px solid rgba(255, 255, 255, 0.3)",
  },
});

const ResetPassword = () => {
  const classes = useStyles();
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const [valuesConfirm, setValuesConfirm] = useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPasswordConfirm = () => {
    setValuesConfirm({
      ...valuesConfirm,
      showPassword: !valuesConfirm.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  const { resetPassword } = useContext(AuthContext);
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      resetPassword(formData);
    },
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const benefits = [
    { name: "Descuentos Exclusivos" },
    { name: "Promociones" },
    { name: "Publicidad" },
    { name: "Excelentes Ganancias" },
    { name: "Precio de distribuidor" },
  ];

  return (
    <Box
      sx={{
        position: "fixed", // Fija el contenedor en la pantalla
        top: 0,
        left: 0,
        width: "100vw", // Ocupa el ancho completo de la pantalla
        height: "100vh", // Ocupa el alto completo de la pantalla
        backgroundColor: "#ffa489", // Ruta de la imagen de fondo
        backgroundSize: "cover", // La imagen cubre toda la pantalla
        backgroundPosition: "center", // Centra la imagen
        zIndex: -1, // Se coloca detrás de otros elementos
      }}
    >
      <div className={classes.backgroundLogin}>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Box
              sx={{
                boxShadow: 3,
                mr: 2,
                ml: 2,
                padding: 4,
                position: "relative",
                marginTop: 10,
              }}
              className={classes.caja1}
            >
              <Box
                sx={{
                  boxShadow: 3,
                  mr: 2,
                  ml: 2,
                  padding: 4,
                  position: "relative",
                  marginTop: 5,
                }}
                className={classes.caja}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={require("../../assets/img/merryBlack.png")} />
                </Box>
                <div
                  style={{
                    textAlign: "center",
                    color: "#FFF",
                    marginBottom: "10px",
                    fontSize: "20px",
                    // fontFamily: "monospace",
                    fontWeight: "bold",
                  }}
                >
                  Recuperar Contraseña
                </div>

                <Box
                  component='form'
                  onSubmit={formik.handleSubmit}
                  sx={{
                    mt: 3,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                      <TextField
                        fullWidth
                        id='number_phone'
                        label='Número Telefónico:'
                        name='number_phone'
                        autoComplete='off'
                        type='tel'
                        value={formik.values.number_phone}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.number_phone &&
                          Boolean(formik.errors.number_phone)
                        }
                        helperText={
                          formik.touched.number_phone &&
                          formik.errors.number_phone
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                      <FormControl
                        fullWidth
                        variant='outlined'
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                      >
                        <InputLabel
                          htmlFor='password'
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                        >
                          Nueva Contraseña:
                        </InputLabel>
                        <OutlinedInput
                          id='password'
                          name='password'
                          type={values.showPassword ? "text" : "password"}
                          value={formik.values.password}
                          onChange={(event) => {
                            setValues({
                              ...values,
                              password: event.target.value,
                            });
                            formik.handleChange(event);
                          }}
                          onBlur={formik.handleBlur} // Añade manejo de pérdida de foco
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge='end'
                              >
                                {values.showPassword ? (
                                  <VisibilityOff sx={{ color: "#FFF" }} />
                                ) : (
                                  <Visibility sx={{ color: "#FFF" }} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label='Contraseña:'
                        />
                        {formik.touched.password && formik.errors.password && (
                          <FormHelperText error>
                            {formik.errors.password}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                      <FormControl
                        fullWidth
                        variant='outlined'
                        error={
                          formik.touched.password_confirmation &&
                          Boolean(formik.errors.password_confirmation)
                        }
                      >
                        <InputLabel
                          htmlFor='password_confirmation'
                          error={
                            formik.touched.password_confirmation &&
                            Boolean(formik.errors.password_confirmation)
                          }
                        >
                          Confirmar Contraseña:
                        </InputLabel>
                        <OutlinedInput
                          id='password_confirmation'
                          name='password_confirmation'
                          type={
                            valuesConfirm.showPassword ? "text" : "password"
                          }
                          value={formik.values.password_confirmation}
                          onChange={(event) => {
                            setValues({
                              ...values,
                              password_confirmation: event.target.value,
                            });
                            formik.handleChange(event);
                          }}
                          onBlur={formik.handleBlur} // Añade manejo de pérdida de foco
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleClickShowPasswordConfirm}
                                onMouseDown={handleMouseDownConfirmPassword}
                                edge='end'
                              >
                                {valuesConfirm.showPassword ? (
                                  <VisibilityOff sx={{ color: "#FFF" }} />
                                ) : (
                                  <Visibility sx={{ color: "#FFF" }} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label='Contraseña:'
                        />
                        {formik.touched.password_confirmation &&
                          formik.errors.password_confirmation && (
                            <FormHelperText error>
                              {formik.errors.password_confirmation}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    sx={{
                      backgroundColor: "#ffa489",
                      color: "white",
                      fontWeight: "bold",
                      mt: 3,
                      mb: 2,
                      "&:hover": {
                        backgroundColor: "#ffa489",
                      },
                    }}
                  >
                    <Typography
                      // fontFamily='monospace'
                      fontWeight='bold'
                      variant='subtitle1'
                    >
                      Recuperar contraseña
                    </Typography>
                  </Button>
                  <br />
                  <Typography textAlign='end'>
                    La has recordado{" "}
                    <Link
                      style={{
                        textDecoration: "none",
                        fontWeight: "bold",
                        color: "#fff                  ",
                      }}
                      to='/iniciar-sesion'
                    >
                      Iniciar Sesión
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default ResetPassword;
function initialValues() {
  return {
    number_phone: "",
    password: "",
    password_confirmation: "",
  };
}

function validationSchema() {
  return {
    number_phone: Yup.string()
      .length(10, "El número de teléfono debe tener exactamente 10 dígitos")
      .required("Teléfono requerido"),
    password: Yup.string().min(8).required("Contraseña requerida"),
    password_confirmation: Yup.string()
      .min(8)
      .required("El campo confirmar Contraseña es requerido"),
  };
}
