import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Paper,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
} from "@mui/material";
import Layout from "../../components/Layout/Layout";
import { Link } from "react-router-dom/cjs/react-router-dom";
import AuthContext from "../../Context/auth/AuthContext";
import { useContext } from "react";
import { BackIcon } from "../../components/icons/BackIcon";
import Points from "../Points/Points";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Account(props) {
  const { usuario, eliminarCuenta } = useContext(AuthContext);
  return (
    <Layout>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid xs={10}>
            <Item>
              <Typography fontWeight='bold' fontSize='40px'>
                Mi Cuenta
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={2}>
            <IconButton aria-label='' onClick={() => props.history.goBack()}>
              <BackIcon />
            </IconButton>
          </Grid>
          {usuario && usuario.type_user === 1 && (
            <>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <Link to={`/pedidos`} style={{ textDecoration: "none" }}>
                  <Card>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        justifyItems: "center",
                      }}
                    >
                      <CardMedia
                        component='img'
                        sx={{ width: "150px" }}
                        image={require("../../assets/img/delivery-man-svgrepo-com.png")}
                        alt='pedidos'
                      />
                    </Box>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Typography component='div' variant='h5'>
                        Mis Pedidos
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        color='text.secondary'
                        component='div'
                      >
                        Ver, listar, Rastrear pedidos, subir comprobante y
                        más...
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <Link to={`/direcciones`} style={{ textDecoration: "none" }}>
                  <Card>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <CardMedia
                        component='img'
                        sx={{ width: "150px" }}
                        image={require("../../assets/img/map-location-share-location-svgrepo-com.png")}
                        alt='pedidos'
                      />
                    </Box>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Typography component='div' variant='h5'>
                        Mis Direcciones
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        color='text.secondary'
                        component='div'
                      >
                        Agregar, Editar, Eliminar, direccion predeterminada,
                        etc.
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <Points />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={3}
                sx={{ justifyContent: "center" }}
              >
                <Link to={`/ayuda`} style={{ textDecoration: "none" }}>
                  <Card>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <CardMedia
                        component='img'
                        sx={{
                          width: "125px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        image={require("../../assets/img/help-information-question-svgrepo-com.png")}
                        alt='pedidos'
                      />
                    </Box>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Typography component='div' variant='h5'>
                        Ayuda
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        color='text.secondary'
                        component='div'
                        textAlign='justify'
                      >
                        Registro, Inicio sesión, compras, descuentos, subir
                        comprobante, etc...
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            </>
          )}

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={3}
            sx={{ justifyContent: "center" }}
          >
            <Link
              to={`/informacion-Contacto`}
              style={{ textDecoration: "none" }}
            >
              <Card>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CardMedia
                    component='img'
                    sx={{
                      width: "150px",
                    }}
                    image={require("../../assets/img/contact-business-card-svgrepo-com.png")}
                    alt='pedidos'
                  />
                </Box>
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography component='div' variant='h5'>
                    Contacto
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    color='text.secondary'
                    component='div'
                  >
                    Consultar información de contacto
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          {/* <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={3}
            sx={{ justifyContent: "center" }}
          >
            <Link to={`/Politicas`} style={{ textDecoration: "none" }}>
              <Card>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CardMedia
                    component='img'
                    sx={{
                      width: "150px",
                    }}
                    image={require("../../assets/img/document-file-page-paper-svgrepo-com.png")}
                    alt='pedidos'
                  />
                </Box>
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography component='div' variant='h5'>
                    Politicas, Terminos & condiciones
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    color='text.secondary'
                    component='div'
                  >
                    Consulta las politicas, terminos y condiciones, etc.
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid> */}
          {usuario && usuario.type_user === 0 && (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
              <Link to={`/society`} style={{ textDecoration: "none" }}>
                <Card>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CardMedia
                      component='img'
                      sx={{ width: "150px" }}
                      image={require("../../assets/img/docs-opened-svgrepo-com.png")}
                      alt='pedidos'
                    />
                  </Box>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography component='div' variant='h5'>
                      Administrar información
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      color='text.secondary'
                      component='div'
                    >
                      Administrar información de la sociedad
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
            <Card onClick={() => eliminarCuenta(usuario?.id)}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CardMedia
                  component='img'
                  sx={{ width: "150px", mt: 2 }}
                  image={require("../../assets/img/user-delete-svgrepo-com.png")}
                  alt='eliminar cuenta'
                />
              </Box>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography component='div' variant='h5'>
                  Eliminar mi cuenta
                </Typography>
                <Typography
                  variant='subtitle1'
                  color='text.secondary'
                  component='div'
                >
                  Cerrar mi cuenta, de forma definitiva
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
}
