import React from "react";
import Select from "react-select";
const SelectInvoice = (props) => {
  const values = [
    { name: "Si", value: "si" },
    { name: "No", value: "no" },
  ];
  const detectarCambiosInvoice = (value) => {
    props.detectarCambiosInvoice(value);
  };
  return (
    <Select
      onChange={(value) => detectarCambiosInvoice(value)}
      className='basic-single'
      classNamePrefix='select'
      // isLoading={isLoading}
      name='invoice_option'
      placeholder='Selecciona una opcion'
      options={values.map((value) => {
        let attribute = {
          label: value?.name,
          value: value?.value,
        };
        return attribute;
      })}
    />
  );
};

export default SelectInvoice;
