import {
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
  Card,
  Box,
  CardContent,
} from "@mui/material";
import ModalImage from "react-modal-image-responsive";
import FormatCurrency from "../../utils/FormatCurrency";
import React, { useContext, useEffect, useState } from "react";
import CartContext from "../../Context/Cart/CartContext";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import OrdersContext from "../../Context/Orders/OrdersContext";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MethodGet from "../../config/Service";
import SelectDirection from "../../containers/SelectOptions/DirectionSelect";
import AddDirection from "../../containers/Directions/AddDirection";
import Swal from "sweetalert2";
import HelpForSeller from "../../containers/SelectOptions/HelpForSeller";
import SelectInvoice from "../../containers/SelectOptions/SelectInvoice";
import CupponComponent from "../../containers/ProductsList/CupponComponent";
const useStyles = makeStyles({
  img: {
    width: 200,
    height: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  jss59: {
    width: "100%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 3px",
    borderRadius: "10px",
  },
});
const TableProductsCart = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    GetCartUserLogged,
    cart,
    ModifyQuantityProduct,
    GetTotalCart,
    totalCart,
    DeleteProductCart,
  } = useContext(CartContext);
  const [spinnerCargando, setSpinnerCargando] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [id_business, setIdBusiness] = useState(null);
  const [total, setTotal] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [helpSeller, setHelpSellet] = useState("");
  const [direction, setDirection] = useState(null);
  const [invoice, setInvoice] = useState("");
  const detectarCambiosDirection = (e) => {
    setDirection(e.target.value);
  };

  const detectarCambiosHelpForSeller = (value) => {
    setHelpSellet(value.value);
  };

  const detectarCambiosInvoice = (value) => {
    setInvoice(value.value);
  };
  useEffect(() => {
    GetCartUserLogged();
  }, []);
  const handleChangeQuantity = async (e, id) => {
    const data = {};
    data.product_id = id;
    data.quantity = Number(e.target.value);

    await ModifyQuantityProduct(data);
  };

  const handleClickDelete = async (id) => {
    setSpinnerCargando(true);
    await DeleteProductCart(id);
  };

  const subtotal = cart.map((c) => c.quantity * c.price);
  var sumaSubtotal = subtotal.reduce(function (acc, numero) {
    return acc + numero;
  }, 0);

  useEffect(() => {
    let url = `/Discount/${sumaSubtotal}`;
    MethodGet(url)
      .then((res) => {
        setDiscount(res.data.discount);
        setIdBusiness(res.data.id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [sumaSubtotal]);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const calculateTotal = (sumaSubtotal, discount) => {
    setTotal(sumaSubtotal * (1 - discount / 100));
  };
  useEffect(() => {
    calculateTotal(sumaSubtotal, discount);
  }, [sumaSubtotal, cart, discount]);
  useEffect(() => {
    if ((total > 0) & (total < 7999)) {
      setShippingCost(150);
    } else if (total > 8000) {
      setShippingCost(350);
    }
  }, [total]);
  const { CreateOrder } = useContext(OrdersContext);
  const onSubmit = (data) => {
    data.status = 0;
    data.subtotal = sumaSubtotal;
    data.id_business_rule = id_business;
    data.shippingCost = shippingCost;
    data.total = total;
    data.direction_id = direction;
    data.help_for_seller = helpSeller;
    data.invoice = invoice;
    // console.log(data);
    if (direction === null) {
      Swal.fire({
        title: "Cuidado",
        text: "Para finalizar la compra, debes de seleccionar una direccion de envio",
        icon: "warning",
        timer: 2500,
        showConfirmButton: false,
      });
      return;
    }
    if (helpSeller === "") {
      Swal.fire({
        title: "Cuidado",
        text: "Para continuar selecciona una opcion si es que recibiste ayuda de parte de algún vendedor",
        icon: "warning",
        timer: 1800,
        showConfirmButton: false,
      });
      return;
    }
    if (invoice === "") {
      Swal.fire({
        title: "Atención",
        text: "Para continuar selecciona una opcion si es que necesitas factura de tu compra",
        icon: "warning",
        timer: 1800,
        showConfirmButton: false,
      });
      return;
    }
    CreateOrder(data);
    history.push("/pedidos");
  };
  const [openAddDirection, setOpenAddDirection] = useState(false);
  const handleClickOpenAddDirection = () => {
    setOpenAddDirection(true);
  };
  const handleCloseAddDirection = () => {
    setOpenAddDirection(false);
  };
  const miImagen = require("../../assets/img/default.webp");
  return (
    <>
      <div
        style={{
          width: "100%",
          minWidth: "380px",
          minHeight: 100,
          maxHeight: 600,
          overflowY: "auto",
          padding: 5,
        }}
      >
        {cart.map((product) => (
          <Card
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: 0,
              boxShadow: 3,
              borderRadius: 10,
              marginBottom: 3,
            }}
            key={product.id}
          >
            <Box sx={{ display: "flex", mr: 3 }}>
              {product.image.image ? (
                <ModalImage
                  className={classes.img}
                  small={product.image?.image}
                  large={product.image?.image}
                  alt={product.name}
                />
              ) : (
                <ModalImage
                  className={classes.img}
                  small={miImagen}
                  large={miImagen}
                  alt={product.name}
                />
              )}
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "95%" }}
            >
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography component='div' variant='h5'>
                  {product.name}
                </Typography>
                <Typography
                  variant='subtitle1'
                  component='div'
                  sx={{ color: "green" }}
                >
                  Disponibles: {product.stock}
                </Typography>
                <Typography
                  variant='subtitle'
                  component='div'
                  sx={{ color: "black" }}
                >
                  Precio: {FormatCurrency(product.price)}
                </Typography>
                <Typography
                  variant='subtitle'
                  component='div'
                  sx={{ color: "black" }}
                >
                  Subtotal: {FormatCurrency(product.price * product.quantity)}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  width: "95%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <input
                  className={classes.jss59}
                  placeholder='Agrega la Cantidad Inicial'
                  name='quantityProduct'
                  min={1}
                  max={product.stock}
                  value={product.quantity}
                  onChange={(e) => handleChangeQuantity(e, product.id)}
                />
                <IconButton
                  aria-label='next'
                  onClick={() => DeleteProductCart(product.id)}
                >
                  <DeleteIcon sx={{ fontSize: 45, color: "red" }} />
                </IconButton>
              </Box>
            </Box>
          </Card>
        ))}
      </div>

      {cart.length > 0 && (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: 1,
            }}
          >
            <Typography fontWeight='bold'>Subtotal:</Typography>
            <Typography> {FormatCurrency(sumaSubtotal)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: 1,
            }}
          >
            <Typography fontWeight='bold'>Descuento:</Typography>
            <Typography>{discount ? discount : 0} %</Typography>
          </Grid>
          {/* <Grid item xs={12}>
            <Divider />
          </Grid> */}
          {/* <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: 1,
            }}
          >
            <Typography fontWeight='bold'>Total:</Typography>
            <Typography> {FormatCurrency(total)} </Typography>
          </Grid> */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: 1,
            }}
          >
            <Typography fontWeight='bold'>Costo de Envio:</Typography>
            <Typography> {FormatCurrency(shippingCost)} </Typography>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <CupponComponent total={total} setTotal={setTotal} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: 1,
            }}
          >
            <Typography fontWeight='bold'>Total + G.E:</Typography>
            <Typography> {FormatCurrency(total + shippingCost)} </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                <SelectDirection
                  setDirection={setDirection}
                  direction={direction}
                  detectarCambiosDirection={detectarCambiosDirection}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                sx={{ justifyContent: "center" }}
              >
                <Button
                  variant='contained'
                  onClick={() => handleClickOpenAddDirection()}
                  sx={{
                    background: "#FFBEA0",
                    "&:hover": { background: "#FFBEA0" },
                  }}
                >
                  Agregar dirección
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {/* <Divider /> */}
            <label style={{ marginTop: 3 }}>
              ¿Recibiste ayuda de parte de algún vendedor?
            </label>
            <HelpForSeller
              detectarCambiosHelpForSeller={detectarCambiosHelpForSeller}
            />
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {/* <Divider /> */}
            <label style={{ marginTop: 3 }}>
              ¿Necesitas Facturar esta compra?
            </label>
            <SelectInvoice detectarCambiosInvoice={detectarCambiosInvoice} />
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter")
                  e.preventDefault();
              }}
              autoComplete='off'
            >
              <Button
                variant='contained'
                size='large'
                type='submit'
                fullWidth
                sx={{
                  backgroundColor: "#FFA489",
                  marginBottom: 10,
                  color: "white",
                  "&:hover": { backgroundColor: "#FFA489", color: "white" },
                }}
              >
                Terminar compra
              </Button>
            </form>
          </Grid>
        </Grid>
      )}
      <AddDirection
        open={openAddDirection}
        handleClose={handleCloseAddDirection}
      />
    </>
  );
};

export default TableProductsCart;
