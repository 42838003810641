import React, { useReducer } from "react";
import DashboardReducer from "./DashboardReducer";
import MethodGet, { MethodPost } from "../../config/Service";
import {
  RANKING_SALES_MUNICIPALITIES,
  VENTAS_DEL_DIA,
  VENTAS_DE_LA_SEMANA,
  VENTAS_DEL_MES,
  INGRESOS_DEL_DIA,
  VENTAS_GRAFICA,
} from "../../types";
import { saveAs } from "file-saver";
import DashboardContext from "./DashboardContext";
import fileDownload from "js-file-download";
import clienteAxios from "../../config/Axios";
const DashboardState = ({ children }) => {
  const initialState = {
    salesMunicipality: [],
    ErrorsAPI: [],
    success: false,
    ventas_del_dia: 0,
    ventas_de_la_semana: 0,
    ventas_mes: 0,
    ingresos_dia: 0,
    ventas_grafica: [],
  };
  const [state, dispatch] = useReducer(DashboardReducer, initialState);
  const salesForMunicipality = (startDate, endDate) => {
    let url = `/SalesForMunicipalityRanking/${startDate}/${endDate}`;
    const formData = new FormData();
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    MethodPost(url)
      .then((res) => {
        dispatch({
          type: RANKING_SALES_MUNICIPALITIES,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const obtenerVentasDelDia = () => {
    let url = "/ventas_del_dia";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: VENTAS_DEL_DIA,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const obtenerVentasDelaSemana = () => {
    let url = "/ventas_semana";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: VENTAS_DE_LA_SEMANA,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const obtenerVentasDelMes = () => {
    let url = "/ventas_mes";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: VENTAS_DEL_MES,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const obtenerIngresosDelDia = () => {
    let url = "/ingresos_dia";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: INGRESOS_DEL_DIA,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const ObtenerVentasMesGrafica = () => {
    let url = "/ventas_grafica";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: VENTAS_GRAFICA,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const downloadProductsMoreSaledPDF = (fecha_inicio, fecha_fin) => {
    let url = `/exportar-productos-pdf?fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}`;
    clienteAxios
      .get(url, { responseType: "blob" })
      .then((res) => {
        console.log(res);
        fileDownload(res.data, "productos_mas_vendidos.pdf");
      })
      .catch((error) => {
        console.error("Error al descargar el archivo:", error);
      });
  };

  const downloadStockProducts = () => {
    let url = "exportar-stock";
    clienteAxios
      .get(url, { responseType: "blob" })
      .then((res) => {
        fileDownload(res.data, "stock_productos.xlsx");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadExcelSalesReport = (fecha_inicio, fecha_fin) => {
    let url = `/exportar-ventas?fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}`;
    clienteAxios
      .get(url, { responseType: "blob" })
      .then((res) => {
        fileDownload(res.data, `ventas_${fecha_inicio}_${fecha_fin}.xlsx`);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const downloadExcelSalesReportSeller = (fecha_inicio, fecha_fin) => {
    let url = `/export-ventas-seller?fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}`;
    clienteAxios
      .get(url, { responseType: "blob" })
      .then((res) => {
        fileDownload(
          res.data,
          `ventas_vendedoras_${fecha_inicio}_${fecha_fin}.xlsx`
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <DashboardContext.Provider
      value={{
        salesMunicipality: state.salesMunicipality,
        ventas_del_dia: state.ventas_del_dia,
        ventas_de_la_semana: state.ventas_de_la_semana,
        ventas_mes: state.ventas_mes,
        ventas_grafica: state.ventas_grafica,
        ingresos_dia: state.ingresos_dia,
        ErrorsAPI: state.ErrorsAPI,
        succes: state.success,
        salesForMunicipality,
        obtenerVentasDelDia,
        obtenerVentasDelaSemana,
        obtenerVentasDelMes,
        obtenerIngresosDelDia,
        ObtenerVentasMesGrafica,
        downloadProductsMoreSaledPDF,
        downloadStockProducts,
        downloadExcelSalesReport,
        downloadExcelSalesReportSeller,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardState;
