import React, { useContext, useEffect } from "react";
import { SalesMontlyIcon } from "../icons/SalesMontlyIcon";
import DashboardContext from "../../Context/Dashboard/DashboardContext";
const CardSalesMonthly = () => {
  const { ventas_mes, obtenerVentasDelMes } = useContext(DashboardContext);
  useEffect(() => {
    obtenerVentasDelMes();
  }, []);
  return (
    <div className='card'>
      <div className='container'>
        <div className='cloud front'>
          <SalesMontlyIcon />
        </div>
        <div className='cloud back'></div>
      </div>

      <div className='card-header'>
        <span>Ventas del Mes</span>
        <span>{ventas_mes.rango_fechas}</span>
        <span>{ventas_mes.total_pagos}</span>
      </div>

      <span className='temp'>{ventas_mes.ventas_del_mes}</span>
    </div>
  );
};

export default CardSalesMonthly;
