import { Card, CardMedia, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import BrandsContext from "../../Context/Brands/BrandsContext";
import { CardHeader } from "react-bootstrap";
import { Link } from "react-router-dom";
const Brands = () => {
  const { brands, GetBrands } = useContext(BrandsContext);
  useEffect(() => {
    GetBrands();
  }, []);
  return (
    <Layout>
      <Grid container spacing={2} sx={{ margin: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            textAlign="center"
            variant="h5"
            fontWeight="bold"
            fontSize="50px"
            color="#FFBEA0"
          >
            Marcas
          </Typography>
        </Grid>
        {brands.map((brand, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
            <Link
              to={`/productos/${brand.name}`}
              style={{ textDecoration: "none" }}
            >
              <Card>
                <CardHeader>
                  <Typography
                    textAlign="center"
                    fontWeight="bold"
                    variant="subtitle1"
                    fontSize="40px"
                    color="#FFBEA0"
                  >
                    {brand.name}
                  </Typography>
                </CardHeader>
                <CardMedia
                  component="img"
                  height="auto"
                  width="auto"
                  image={brand.image}
                  alt={brand.name}
                />
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};

export default Brands;
