import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";

const CupponComponent = ({ total, setTotal }) => {
  const [coupon, setCoupon] = useState("");
  const [isApplying, setIsApplying] = useState(false);
  const [couponApplied, setCouponApplied] = useState(false);
  const [discountMessage, setDiscountMessage] = useState("");
  const [previousTotal, setPreviousTotal] = useState(total); // Para comparar cambios en el total

  const validCoupons = {
    MERRY20: 20, // 20% de descuento
  };

  const applyCoupon = () => {
    if (couponApplied) return; // Si ya se aplicó, no hacer nada

    if (!validCoupons[coupon]) {
      setDiscountMessage("Cupón no válido");
      return;
    }

    setIsApplying(true);
    setDiscountMessage("Aplicando cupón...");

    setTimeout(() => {
      const discount = validCoupons[coupon];
      const newTotal = total - (total * discount) / 100;
      setTotal(newTotal);
      setIsApplying(false);
      setCouponApplied(true);
      setDiscountMessage("Cupón aplicado");
    }, 1500); // Simula un tiempo de espera
  };

  // Detecta si el total cambia para restablecer el estado del cupón
  useEffect(() => {
    if (total !== previousTotal) {
      setCouponApplied(false); // Habilita nuevamente el uso de un cupón
      setDiscountMessage(""); // Limpia el mensaje de descuento
      setCoupon(""); // Resetea el campo de cupón
    }
    setPreviousTotal(total);
  }, [total, previousTotal]);

  return (
    <div style={{ padding: "2px", fontFamily: "Arial, sans-serif" }}>
      <h3>Total de tu compra: ${total.toFixed(2)}</h3>

      {!couponApplied && (
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Grid item xs={11} sm={11}>
              <input
                type='text'
                value={coupon}
                onChange={(e) => setCoupon(e.target.value.toUpperCase())}
                placeholder='Ingresa tu cupón'
                style={{
                  padding: "8px",
                  marginRight: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <button
                onClick={applyCoupon}
                disabled={isApplying}
                style={{
                  padding: "8px 12px",
                  backgroundColor: isApplying ? "#ccc" : "#007BFF",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  width: "100%",
                  cursor: isApplying ? "not-allowed" : "pointer",
                }}
              >
                {isApplying ? "Aplicando..." : "Aplicar cupón"}
              </button>
            </Grid>
          </Grid>
        </Grid>
      )}

      {discountMessage && (
        <p
          style={{
            marginTop: "10px",
            color: couponApplied ? "green" : "red",
            fontWeight: "bold",
          }}
        >
          {discountMessage}
        </p>
      )}
    </div>
  );
};

export default CupponComponent;
