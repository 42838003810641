import { Grid } from "@mui/material";
import React from "react";

const Direction = ({ direction }) => {
  console.log(direction);
  return (
    <Grid container spacing={2} sx={{ margin: 2 }}>
      <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
        <b>Pais:</b>

        {direction.country ?? "N/A"}
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
        <b>Estado:</b>
        {direction.state.name ?? "N/A"}
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
        <b>Municipio:</b>
        {direction.municipality.name ?? "N/A"}
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
        <b>zona/barrio:</b>
        {direction.zone ?? "N/A"}
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
        <b>CP:</b>
        {direction.postal_code ?? "N/A"}
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
        <b>Calle:</b>
        {direction.street ?? "N/A"}
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
        <b>Entre calle:</b>
        {direction.between_street ?? "N/A"}
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
        <b>Y calle:</b>
        {direction.and_between_street ?? "N/A"}
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
        <b>N° Exterior:</b>
        {direction.number_ext ?? "N/A"}
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
        <b>N° Interior:</b>
        {direction.number_int ?? "N/A"}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <b>Referencias:</b>
        {direction.references ?? "N/A"}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <b>Telefono:</b>
        {direction.phone ?? "Telefono no registrado"}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <b>Correo:</b>
        {direction.email ?? "Correo no registrado"}
      </Grid>
    </Grid>
  );
};

export default Direction;
