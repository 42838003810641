import React from "react";
import {
  ADD_POINTS,
  CREATE_CLIENT,
  DECREASE_POINTS,
  GET_INFO,
} from "../../types";

const PointsReducer = (state, action) => {
  switch (action.type) {
    case GET_INFO:
      return {
        ...state,
        info: action.payload,
        success: false,
        errorsAPI: [],
      };
    case CREATE_CLIENT:
      return {
        ...state,
        info: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case ADD_POINTS:
      return {
        ...state,
        info: action.payload,
        success: false,
        errorsAPI: [],
      };
    case DECREASE_POINTS:
      return {
        ...state,
        info: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    default:
      return state;
  }
};

export default PointsReducer;
