import React from "react";
import Layout from "../components/Layout/Layout";

const TermsAndConditions = () => {
  return (
    // <Layout>
    <div style={styles.container}>
      <h1 style={styles.title}>
        Términos y Condiciones - Distribuidores Merrycolor
      </h1>

      <h2 style={styles.sectionTitle}>1. Introducción</h2>
      <p style={styles.text}>
        Bienvenido a Merrycolor. Estos términos y condiciones establecen las
        reglas y regulaciones para los distribuidores de nuestros productos. Al
        convertirse en distribuidor de Merrycolor, aceptas cumplir con estos
        términos en su totalidad.
      </p>

      <h2 style={styles.sectionTitle}>2. Registro de Distribuidores</h2>
      <p style={styles.text}>
        Para ser un distribuidor autorizado de Merrycolor, debes completar el
        proceso de registro. Al registrarte, confirmas que toda la información
        proporcionada es veraz y precisa. Merrycolor se reserva el derecho de
        rechazar cualquier solicitud de registro sin previo aviso.
      </p>

      <h2 style={styles.sectionTitle}>3. Pedidos y Entregas</h2>
      <p style={styles.text}>
        Todos los pedidos realizados por los distribuidores estarán sujetos a la
        disponibilidad de productos. Merrycolor se compromete a procesar y
        enviar los pedidos en un plazo de 3 a 5 días hábiles desde la
        confirmación del pedido.
      </p>

      <h2 style={styles.sectionTitle}>4. Precios y Pagos</h2>
      <p style={styles.text}>
        Los precios de los productos serán los establecidos en la lista de
        precios de Merrycolor vigente en el momento de realizar el pedido. El
        pago debe realizarse en su totalidad antes del envío de los productos.
      </p>

      <h2 style={styles.sectionTitle}>5. Devoluciones y Reembolsos</h2>
      <p style={styles.text}>
        Merrycolor acepta devoluciones de productos defectuosos dentro de los 7
        días posteriores a la recepción del pedido. Para iniciar un proceso de
        devolución, por favor contacta a nuestro equipo de atención al cliente.
      </p>

      <h2 style={styles.sectionTitle}>6. Responsabilidad</h2>
      <p style={styles.text}>
        Merrycolor no se hace responsable por daños indirectos, incidentales o
        consecuentes que resulten del uso de nuestros productos. La
        responsabilidad de Merrycolor, en todo caso, estará limitada al valor de
        los productos comprados por el distribuidor.
      </p>

      <h2 style={styles.sectionTitle}>7. Modificaciones de los Términos</h2>
      <p style={styles.text}>
        Merrycolor se reserva el derecho de modificar estos términos y
        condiciones en cualquier momento. Cualquier cambio será notificado a los
        distribuidores y entrará en vigor inmediatamente después de su
        publicación.
      </p>

      <h2 style={styles.sectionTitle}>8. Ley Aplicable</h2>
      <p style={styles.text}>
        Estos términos y condiciones se regirán e interpretarán de acuerdo con
        las leyes del país donde Merrycolor tiene su sede.
      </p>

      <p style={styles.footer}>Gracias por ser parte de Merrycolor.</p>
    </div>
    // </Layout>
  );
};

const styles = {
  container: {
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  sectionTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "10px",
  },
  text: {
    fontSize: "16px",
    marginBottom: "15px",
    lineHeight: "1.5",
  },
  footer: {
    marginTop: "20px",
    fontSize: "16px",
    fontStyle: "italic",
  },
};

export default TermsAndConditions;
