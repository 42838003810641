import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, Grid } from "@mui/material";
import { MethodPost } from "../../config/Service";
import Swal from "sweetalert2";
const InfoForPoints = ({ open, handleClose }) => {
  const handleSubmit = (data) => {
    let url = `https://apipoints.wapizima.com/api/points/create_client`;
    MethodPost(url, data)
      .then((res) => {
        Swal.fire({
          title: "Excelente",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const name = formJson.name;
            const telefono = formJson.telefono;
            const ap_paterno = formJson.ap_pat;
            const ap_materno = formJson.ap_mat;
            const data = {};
            data.name = name;
            data.telefono = telefono;
            data.ap_paterno = ap_paterno;
            data.ap_materno = ap_materno;
            handleSubmit(data);
            handleClose();
          },
        }}
      >
        <DialogTitle>
          Proporciona la información solicitada
          <p style={{ fontSize: "12px" }}>
            Por cada compra que realices podras ganar puntos que podras canjear
            por productos gratis
          </p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>
              Todos los campos con <b>*</b> son obligatorios
            </p>
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField fullWidth label='Nombre' name='name' required />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                label='Apellido Paterno'
                name='ap_pat'
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                label='Apellido Materno'
                name='ap_mat'
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                label='Numero de telefono'
                name='telefono'
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='error' variant='contained'>
            Cancelar
          </Button>
          <Button type='submit' color='success' variant='contained'>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default InfoForPoints;
