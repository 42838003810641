import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Tooltip,
  Typography,
  Grid,
} from "@mui/material";
import fileDownload from "js-file-download";
import FormatCurrency from "../../../utils/FormatCurrency";
import MethodGet from "../../../config/Service";
import { Link } from "react-router-dom";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ModalTicketPayment from "../../Cart/ModalTicketPayment";
import Swal from "sweetalert2";
import clienteAxios from "../../../config/Axios";
const CardPedido = ({ order }) => {
  const [open, setOpen] = React.useState(false);
  const [order_id, setOrderId] = useState(null);
  const [total, setTotal] = useState(null);
  const [envio, setEnvio] = useState(null);
  const handleClickOpen = (id, total, envio) => {
    setOrderId(id);
    setTotal(total);
    setEnvio(envio);
    setOpen(true);
  };

  const handleClose = () => {
    setOrderId(null);
    setTotal(null);
    setOpen(false);
  };

  const handleDownload = (id) => {
    Swal.fire({
      title: "¿Descargar PDF de la orden?",
      text: "Se descargará el pdf de la orden",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let timerInterval;
        Swal.fire({
          title: "Generando",
          html: "El PDF se esta generando!",

          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        });
        let url = `/DataPayment/${order.id}`;
        clienteAxios
          .get(url, { responseType: "blob" })
          .then((res) => {
            fileDownload(res.data, `Orden_${order.id}.pdf`);
            // const safeFileName = encodeURIComponent(`Orden_${order.id}.pdf`);
            // fileDownload(res.data, safeFileName);
            Swal.fire({
              title: "Descargado",
              text: "El PDF de la orden se ha descargado correctamente",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              title: "Error",
              icon: "error",
              text: "Se ha producido un error al descargar el PDF",
            });
          });
      }
    });
  };

  return (
    <>
      <Card>
        {order.status === 0 && order.payments.length === 0 && (
          <Button
            variant='outlined'
            color='success'
            fullWidth
            onClick={() => handleDownload(order.id)}
          >
            Descargar orden de pago de esta venta
          </Button>
        )}
        <CardHeader
          title={`Folio Venta: ${order.id}`}
          subheader={order.client.name}
        />
        <CardContent>
          <Typography fontWeight='bold' fontSize='15px'>
            Subtotal: {FormatCurrency(order.subtotal)}
          </Typography>
          <Typography fontWeight='bold' fontSize='15px'>
            Descuento:{order.business.discount} %
          </Typography>
          <Typography fontWeight='bold' fontSize='15px'>
            Costo de Envio: {FormatCurrency(order.shipping_cost ?? 0)}
          </Typography>
          <Typography fontWeight='bold' fontSize='15px'>
            total + G.E : {FormatCurrency(order.total + order.shipping_cost)}
          </Typography>
          <Typography fontWeight='bold' fontSize='15px'>
            Total pagado:{" "}
            {FormatCurrency(
              order.payments
                ? order.payments
                    .map((payment) => payment.amount)
                    .reduce((total, amount) => total + amount, 0)
                : 0
            )}
          </Typography>
        </CardContent>
        <CardActions>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {order.status === 0 &&
                order.payments
                  .map((payment) => payment.amount)
                  .reduce((total, amount) => total + amount, 0) <
                  order.total && (
                  <Tooltip title='Subir comprobante' placement='top'>
                    <IconButton
                      onClick={() =>
                        handleClickOpen(
                          order.id,
                          order.total,
                          order.shipping_cost
                        )
                      }
                    >
                      <Button variant='contained'>
                        Subir comprobante{""} <UploadFileIcon />{" "}
                      </Button>
                    </IconButton>
                  </Tooltip>
                )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Link to={`/Detalle-venta/${order.id}`}>
                <Button variant='contained'>Ver Detalle</Button>
              </Link>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      {order_id !== null && total !== null && (
        <ModalTicketPayment
          open={open}
          handleClose={handleClose}
          order_id={order_id}
          total={total}
          envio={envio}
          total_pagado={
            order.payments
              ? order.payments
                  .map((payment) => payment.amount)
                  .reduce((total, amount) => total + amount, 0)
              : 0
          }
        />
      )}
    </>
  );
};

export default CardPedido;
