import React, { useEffect, useState } from "react";
import { Grid, InputAdornment, Paper, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/styles";
import { FormControl } from "@mui/material";
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#7b1fa2",
    borderRadius: "25px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#7b1fa2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#7b1fa2",
      borderRadius: "25px",
    },
    "&:hover fieldset": {
      borderColor: "#7b1fa2",
      borderRadius: "25px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7b1fa2",
      borderRadius: "25px",
    },
  },
});
export const SearchProducts = ({ setSearch, setSearchTerm }) => {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ margin: 2 }}>
      <FormControl fullWidth>
        <CssTextField
          fullWidth
          label="Buscar"
          // type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#7b1fa2" }} />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setSearch(true);
          }}
          name="search"
        />
      </FormControl>
    </Grid>
  );
};
