import React from "react";

const Devoluciones = () => {
  return (
    <div style={{ margin: 24, textAlign: "justify" }}>
      <h1>Política de Devoluciones y Reembolsos de Merry Color</h1>
      <p>
        <b>Última actualización: Octubre 2024</b>
      </p>

      <p style={{ fontSize: "20px" }}>
        En Merry Color nos preocupamos por tu satisfacción. Si no estás
        completamente satisfecho con tu compra, ofrecemos una política de
        devoluciones y reembolsos de acuerdo con las siguientes condiciones:
      </p>
      <br />
      <h2>1. Requisitos para la Devolución</h2>
      <ul style={{ fontSize: "19px" }}>
        <li>
          Las devoluciones deben solicitarse dentro de los 14 días posteriores a
          la recepción del pedido.
        </li>
        <li>
          Los productos deben estar sin usar, en su empaque original, con todos
          los sellos intactos y en condiciones aptas para la reventa.
        </li>
        <li>
          No aceptamos devoluciones de productos en oferta o de uso personal,
          como productos de belleza abiertos.
        </li>
      </ul>
      <br />
      <h2>2. Proceso de Devolución</h2>
      <ul style={{ fontSize: "19px" }}>
        <li>
          Para iniciar el proceso, envíanos un correo a {""}
          <a href='mailto:ventasmerrycolor@gmail.com'>
            ventasmerrycolor@gmail.com
          </a>{" "}
          {""}y al número <a href='tel:5618391680'>5618391680</a> con el número
          de pedido y una breve descripción del motivo de la devolución
          añadiendo un video de la apertura de tu pedido sin cortes y enfocado a
          todo el contexto del producto.
        </li>
        <li>
          Una vez aprobada la devolución, te proporcionaremos las instrucciones
          para el envío del producto.
        </li>
      </ul>
      <br />
      <h2>3. Gastos de Envío</h2>
      <ul style={{ fontSize: "19px" }}>
        <li>
          Los gastos de envío para las devoluciones correrán por cuenta del
          cliente, a menos que el producto esté defectuoso o haya habido un
          error en el pedido por parte de la empresa una vez aprobada.
        </li>
        <li>Los gastos originales de envío no serán reembolsables.</li>
      </ul>
      <br />
      <h2>4. Reembolsos</h2>
      <ul style={{ fontSize: "19px" }}>
        <li>
          Una vez que recibamos el producto devuelto y verifiquemos su estado,
          procesaremos el reembolso en un plazo de 7 a 10 días hábiles a través
          del mismo método de pago utilizado.
        </li>
        <li>
          Los reembolsos sólo cubren el valor del producto; los costos de envío
          no serán reembolsados.
        </li>
      </ul>
      <br />
      <h2>5. Productos Dañados o Defectuosos</h2>
      <p style={{ fontSize: "19px" }}>
        Si recibes un producto dañado o defectuoso, por favor notifícalo dentro
        de los 3 días posteriores a la entrega. Nos encargaremos de reemplazar
        el producto defectuoso.
      </p>
      <br />
      <h2>6. Excepciones</h2>
      <ul style={{ fontSize: "19px" }}>
        <li>
          No aceptamos devoluciones de productos personalizados o hechos a
          pedido.
        </li>
        <li>
          Los productos en liquidación o con descuentos especiales no son
          elegibles para devoluciones o reembolsos.
        </li>
      </ul>
      <br />
      <h2>7. Contacto</h2>
      <p style={{ fontSize: "19px" }}>
        Para cualquier pregunta o inquietud sobre devoluciones y reembolsos,
        puedes contactarnos en{" "}
        <a href='mailto:ventasmerrycolor@gmail.com'>
          ventasmerrycolor@gmail.com
        </a>
        {""} o llamarnos al <a href='tel:5618391680'>5618391680</a>.
      </p>
    </div>
  );
};

export default Devoluciones;
