import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Layout from "../../../components/Layout/Layout";
import { Grid, IconButton, AppBar } from "@mui/material";
import ProductsWithoutImage from "./ProductsWitoutImages";
import AllProductsAdmin from "./AllProductsAdmin";
import SearchProduct from "./SearchProduct";
import { BackIcon } from "../../../components/icons/BackIcon";
import ProductsDesactivated from "./ProductsDesactivated";
import EnterProducts from "../EnterProducts/EnterProducts";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function IndexProductsAdmin(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Layout>
      <Grid item xs={2} sx={{ display: "flex", justifyContent: "end" }}>
        <IconButton aria-label='' onClick={() => props.history.goBack()}>
          <BackIcon />
        </IconButton>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        display='flex'
        justifyContent='center'
      >
        <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
          <AppBar position='static'>
            <Tabs
              value={value}
              onChange={handleChange}
              //indicatorColor="secondary"
              textColor='inherit'
              //centered
              variant='scrollable'
              scrollButtons
              allowScrollButtonsMobile
              aria-label='scrollable force tabs example'
              sx={{ backgroundColor: "#FFA489" }}
            >
              <Tab label='Buscar productos' {...a11yProps(0)} />
              <Tab label='Productos Activos' {...a11yProps(1)} />
              <Tab label='Productos Desactivados' {...a11yProps(2)} />
              <Tab label='Entradas de productos' {...a11yProps(3)} />
              <Tab label='Salidas de productos' {...a11yProps(4)} />
            </Tabs>
          </AppBar>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TabPanel value={value} index={0}>
          <SearchProduct />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AllProductsAdmin />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ProductsDesactivated />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <EnterProducts />
        </TabPanel>
        {/* <TabPanel value={value} index={4}>
          <SendIndex />
        </TabPanel> */}
      </Grid>
    </Layout>
  );
}
