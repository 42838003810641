import React, { useContext, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import CategoriesContext from "../../Context/Categories/CategoriesContext";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { BackIcon } from "../../components/icons/BackIcon";
const Categories = (props) => {
  const { GetCategories, categories } = useContext(CategoriesContext);
  useEffect(() => {
    GetCategories();
  }, []);
  const image = require("../../assets/img/merryBlack.png");
  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={10}>
          <Typography
            fontWeight='bold'
            textAlign='center'
            fontSize='35px'
            color='#ffa489'
          >
            Categorias
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton aria-label='' onClick={() => props.history.goBack()}>
            <BackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            {categories &&
              categories.map((category) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                  key={category.id}
                >
                  <Link
                    to={`/productos/${category.id}/${category.name}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Card>
                      {category.image ? (
                        <CardMedia
                          component='img'
                          // height='192'
                          image={category.image}
                          alt={category.name}
                          sx={{ opacity: 0.8, width: "100%", height: "100%" }}
                        />
                      ) : (
                        <CardMedia
                          component='img'
                          height='192'
                          image={image}
                          alt={category.name}
                          sx={{ opacity: 0.5, width: "100%", height: "100%" }}
                        />
                      )}
                      {/* <Typography
                        sx={{
                          // position: "absolute",
                          mt: -15,
                          display: "flex",
                          justifyContent: "center",
                          color: "#FFBEA0",
                        }}
                        textAlign='center'
                        fontWeight='bold'
                        fontSize='40PX'
                      >
                        {category.name}
                      </Typography> */}
                    </Card>
                  </Link>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Categories;
