import React, { useEffect, useContext } from "react";

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";

export default function ShippSelect(props) {
  const shipps = [
    { name: "Fedex", value: "Fedex" },
    { name: "Estafeta", value: "Estafeta" },
  ];
  const detectarCambiosShipp = (e) => {
    props.detectarCambiosShipp(e);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id='demo-simple-select-label'>Paqueteria</InputLabel>
          {props.value ? (
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              label='Paqueteria'
              onChange={detectarCambiosShipp}
              defaultValue={props.value}
            >
              {shipps &&
                shipps.map((shipp) => (
                  <MenuItem key={shipp.value} value={shipp.value}>
                    {shipp.name}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              label='Paqueteria'
              onChange={detectarCambiosShipp}
            >
              {shipps &&
                shipps.map((shipps) => (
                  <MenuItem key={shipps.value} value={shipps.value}>
                    {shipps.name}
                  </MenuItem>
                ))}
            </Select>
          )}
          <FormHelperText>Campo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
