import React from "react";
import Layout from "../../components/Layout/Layout";
import { Grid, Typography, IconButton } from "@mui/material";
import Help from "../../components/Help/Help";
import { BackIcon } from "../../components/icons/BackIcon";
const Ayuda = (props) => {
  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2, marginTop: 2 }}>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
          <Typography variant='h4' textAlign='center'>
            Ayuda
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton aria-label='' onClick={() => props.history.goBack()}>
            <BackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Help />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Ayuda;
