import React, { useContext } from "react";
import {
  Alert,
  AlertTitle,
  Grid,
  Paper,
  Typography,
  AppBar,
  Toolbar,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import AuthContext from "../../Context/auth/AuthContext";
const Footer = () => {
  const { autenticado, usuario } = useContext(AuthContext);
  const enviarMensajeWhatsApp = () => {
    // Número de teléfono al que enviarás el mensaje (con código de país)
    const numeroTelefono = "5618391680";

    // Mensaje con texto y emojis
    const mensaje = `¡Hola!, Necesito de su ayuda, para resolver algunas dudas!`;

    // Codifica el mensaje para que sea válido en una URL, incluyendo el emoji
    const mensajeCodificado = encodeURIComponent(mensaje);

    // Construye la URL de WhatsApp
    const urlWhatsApp = `https://wa.me/${numeroTelefono}?text=${mensajeCodificado}`;

    // Abre la URL en una nueva pestaña o ventana
    window.open(urlWhatsApp);
  };
  return (
    <div>
      <AppBar
        position='fixed'
        sx={{ top: "auto", bottom: 0, backgroundColor: "#FFBEA0" }}
      >
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {/* <Toolbar sx={{ display: "flex", justifyContent: "center" }}> */}
          <Grid
            item
            sx={{ display: "flex", justifyContent: "center" }}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
          >
            {autenticado === false ? (
              <Link to={"/Politicas"}>
                <Button
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Terminos y Condiciones
                </Button>
              </Link>
            ) : (
              <Link to={"/Terminos-condiciones-MerryColor"}>
                <Button
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Terminos y Condiciones
                </Button>
              </Link>
            )}
          </Grid>
          <Grid
            item
            sx={{ display: "flex", justifyContent: "center" }}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
          >
            {autenticado === false ? (
              <Link to={"/DevolucionesYReembolsos"}>
                <Button
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Politicas de devolución y Reembolsos
                </Button>
              </Link>
            ) : (
              <Link
                to={"/Terminos-condiciones-Reembolsos-Devoluciones-MerryColor"}
              >
                <Button
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Politicas de devolución y Reembolsos
                </Button>
              </Link>
            )}
          </Grid>
          <Grid
            item
            sx={{ display: "flex", justifyContent: "center" }}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
          >
            <Button
              sx={{
                color: "white",
                fontWeight: "bold",
              }}
              onClick={enviarMensajeWhatsApp}
            >
              Soporte
            </Button>
          </Grid>
          {/* </Toolbar> */}
        </Grid>
      </AppBar>
    </div>
  );
};

export default Footer;
