export const ReducerCartIcon = ({
  fill = "currentColor",
  filled,
  size,
  height,
  width,
  label,
  ...props
}) => {
  return (
    <svg
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
      fill='#000000'
      width='50px'
    >
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        stroke-linecap='round'
        stroke-linejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        {" "}
        <g fill='none' fill-rule='evenodd'>
          {" "}
          <path d='m0 0h32v32h-32z'></path>{" "}
          <path
            d='m16 0c8.836556 0 16 7.163444 16 16s-7.163444 16-16 16-16-7.163444-16-16 7.163444-16 16-16zm0 2c-7.7319865 0-14 6.2680135-14 14s6.2680135 14 14 14 14-6.2680135 14-14-6.2680135-14-14-14zm5 13c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1h-10c-.5522847 0-1-.4477153-1-1s.4477153-1 1-1z'
            fill='#ffa489'
            fill-rule='nonzero'
          ></path>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};
