import React, { useEffect, useContext } from "react";

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useState } from "react";
import MethodGet from "../../config/Service";
import BrandsContext from "../../Context/Brands/BrandsContext";
export default function SelectCategory(props) {
  const { GetBrands, brands } = useContext(BrandsContext);
  useEffect(() => {
    GetBrands();
  }, []);
  const detectarCambiosBrand = (e) => {
    props.detectarCambiosBrand(e);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Marcas</InputLabel>
          {props.brandId ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Marcas"
              onChange={detectarCambiosBrand}
              defaultValue={props.brandId}
            >
              {brands &&
                brands.map((brands) => (
                  <MenuItem key={brands.id} value={brands.id}>
                    {brands.name}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Marcas"
              onChange={detectarCambiosBrand}
            >
              {brands &&
                brands.map((brands) => (
                  <MenuItem key={brands.id} value={brands.id}>
                    {brands.name}
                  </MenuItem>
                ))}
            </Select>
          )}
          <FormHelperText>Campo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
