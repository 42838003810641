import React, { useContext, useEffect, useState } from "react";
// import Header from "../../components/Header/Header";
import { Grid, Typography, IconButton } from "@mui/material";
import TableProductsCart from "../../components/Cart/TableProductsCart";
import CartContext from "../../Context/Cart/CartContext";
import Layout from "../../components/Layout/Layout";
import { BackIcon } from "../../components/icons/BackIcon";
const ProductsList = (props) => {
  const { GetCartUserLogged, cart } = useContext(CartContext);
  useEffect(() => {
    GetCartUserLogged();
  }, []);
  return (
    <Layout>
      {/* <Header /> */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          lg={10}
          xl={10}
          sx={{ marginTop: 5 }}
        >
          <Typography fontWeight='bold' textAlign='center' variant='h5'>
            Carrito de compras
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ marginTop: 5 }}>
          <IconButton aria-label='' onClick={() => props.history.goBack()}>
            <BackIcon />
          </IconButton>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}> */}
        <TableProductsCart cart={cart} />
        {/* </Grid> */}
      </Grid>
    </Layout>
  );
};

export default ProductsList;
