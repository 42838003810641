import {
  CREATE_BRAND,
  DELETE_BRAND,
  GET_ALL_BRANDS,
  PRODUCTS_BRAND,
} from "../../types";

export default function BrandsReducer(state, action) {
  switch (action.type) {
    case GET_ALL_BRANDS:
      return {
        ...state,
        brands: action.payload,
        success: false,
        ErrorsApi: [],
      };

    case PRODUCTS_BRAND:
      return {
        ...state,
        products_b: action.payload.products,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        links: action.payload.links,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsApi: [],
      };
    case CREATE_BRAND:
      return {
        ...state,
        brands: [action.payload, ...state.brands],
        success: false,
        ErrorsApi: [],
      };
    case DELETE_BRAND:
      return {
        ...state,
        brands: state.brands.filter((brand) => brand.id !== action.payload),
        success: false,
        ErrorsApi: [],
      };

    default:
      return state;
  }
}
