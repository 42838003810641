import {
  GET_ALL_ORDERS_COMPLETED_USER,
  GET_ALL_ORDERS_PENDING_USER,
  PEDIDOS_CANCELADOS,
  PEDIDOS_ENVIADOS,
  PEDIDOS_EN_PREPARACION,
  PEDIDOS_PENDIENTES_DE_PAGO,
  UPLOAD_TICKET,
  VALIDANDO_PAGO_PEDIDO,
} from "../../types";

export default function PedidosReducer(state, action) {
  switch (action.type) {
    case GET_ALL_ORDERS_PENDING_USER:
      return {
        ...state,
        orders_client: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case PEDIDOS_CANCELADOS:
      return {
        ...state,
        orders_client: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case PEDIDOS_ENVIADOS:
      return {
        ...state,
        orders_client: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case PEDIDOS_EN_PREPARACION:
      return {
        ...state,
        orders_client: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case PEDIDOS_PENDIENTES_DE_PAGO:
      return {
        ...state,
        orders_client: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case VALIDANDO_PAGO_PEDIDO:
      return {
        ...state,
        orders_client: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_ORDERS_COMPLETED_USER:
      return {
        ...state,
        orders_client: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case UPLOAD_TICKET:
      return {
        ...state,
        orders_client: state.orders_client.map((order) =>
          order.id === action.payload.id ? { ...action.payload } : order
        ),
        ErrorsApi: [],
      };
    default:
      return state;
  }
}
