import React from "react";

const PercentageIcon = () => {
  return (
    <svg
      width={40}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 511.999 511.999'
      fill='#000000'
    >
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g id='SVGRepo_tracerCarrier'></g>
      <g id='SVGRepo_iconCarrier'>
        <path
          style={{ fill: "#F53000" }}
          d='M324.408,126.954L208.43,31.152c-8.608-7.111-21.052-7.111-29.661,0L62.79,126.954 c-5.355,4.424-8.456,11.008-8.456,17.953v343.805c0,12.861,10.426,23.287,23.287,23.287h231.957 c12.861,0,23.287-10.426,23.287-23.287V144.907C332.865,137.961,329.764,131.378,324.408,126.954z M193.6,149.676 c-21.721,0-39.33-17.609-39.33-39.33s17.609-39.33,39.33-39.33s39.33,17.609,39.33,39.33S215.321,149.676,193.6,149.676z'
        ></path>{" "}
        <g>
          {" "}
          <path
            style={{ fill: "#BF2717" }}
            d='M309.578,477.321H77.621c-12.861,0-23.287-10.426-23.287-23.287v34.678 c0,12.861,10.426,23.287,23.287,23.287h231.957c12.861,0,23.287-10.426,23.287-23.287v-34.678 C332.865,466.895,322.438,477.321,309.578,477.321z'
          ></path>{" "}
          <path
            style={{ fill: "#BF2717" }}
            d='M324.408,126.954L208.43,31.152c-4.305-3.555-9.567-5.333-14.83-5.333v45.198 c21.721,0,39.33,17.609,39.33,39.33s-17.609,39.33-39.33,39.33v362.323h115.978c12.861,0,23.287-10.426,23.287-23.287V144.907 C332.865,137.961,329.764,131.378,324.408,126.954z'
          ></path>{" "}
        </g>{" "}
        <path
          style={{ fill: "#9D2217" }}
          d='M193.599,166.456c-30.939,0-56.109-25.171-56.109-56.109s25.171-56.109,56.109-56.109 s56.109,25.171,56.109,56.109S224.538,166.456,193.599,166.456z M193.599,87.796c-12.434,0-22.55,10.116-22.55,22.55 s10.116,22.55,22.55,22.55c12.434,0,22.55-10.116,22.55-22.55S206.034,87.796,193.599,87.796z'
        ></path>{" "}
        <path
          style={{ fill: "#3E3E42" }}
          d='M198.075,116.838c-1.748,0-3.527-0.275-5.279-0.856c-8.797-2.914-13.566-12.408-10.652-21.205 c2.612-7.885,26.832-77.46,72.325-92.085c12.787-4.112,32.791-5.546,55.483,10.956c6.273,4.563,11.876,8.757,17.293,12.813 c38.588,28.889,53.027,39.698,112.215,34.663c9.232-0.793,17.356,6.062,18.142,15.296c0.785,9.233-6.063,17.357-15.298,18.142 c-9.862,0.839-18.802,1.247-26.98,1.247c-51.452,0.001-73-16.135-108.193-42.484c-5.326-3.987-10.834-8.11-16.918-12.537 c-12.254-8.911-20.408-7.777-25.473-6.148c-24.963,8.025-45.511,54.901-50.74,70.69 C211.668,112.376,205.113,116.838,198.075,116.838z'
        ></path>{" "}
        <path
          style={{ fill: "#FFFFFF" }}
          d='M106.868,319.965v-33.994c0-23.7,15.562-32.319,35.91-32.319c20.11,0,35.911,8.619,35.911,32.319 v33.995c0,23.701-15.801,32.319-35.911,32.319C122.43,352.284,106.868,343.666,106.868,319.965z M154.749,285.971 c0-7.9-4.548-11.491-11.971-11.491c-7.421,0-11.729,3.591-11.729,11.491v33.995c0,7.901,4.309,11.491,11.729,11.491 c7.422,0,11.971-3.591,11.971-11.491V285.971z M252.901,256.525c0,1.436-0.239,3.112-0.956,4.548l-84.268,172.605 c-1.676,3.591-6.224,5.985-10.773,5.985c-8.139,0-13.406-6.703-13.406-12.688c0-1.436,0.479-3.112,1.197-4.548l84.027-172.605 c1.915-4.07,5.745-5.985,10.054-5.985C245.479,243.837,252.901,248.865,252.901,256.525z M216.752,397.051v-33.995 c0-23.701,15.56-32.319,35.91-32.319c20.11,0,35.91,8.618,35.91,32.319v33.995c0,23.701-15.8,32.319-35.91,32.319 C232.313,429.37,216.752,420.751,216.752,397.051z M264.631,363.056c0-7.9-4.548-11.491-11.97-11.491s-11.73,3.591-11.73,11.491 v33.995c0,7.9,4.31,11.491,11.73,11.491s11.97-3.591,11.97-11.491V363.056z'
        ></path>{" "}
      </g>
    </svg>
  );
};

export default PercentageIcon;
