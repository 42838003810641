import React, { useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { Grid, Typography, IconButton } from "@mui/material";
import { useState } from "react";
import MethodGet from "../../config/Service";
import { BackIcon } from "../../components/icons/BackIcon";

const Discounts = (props) => {
  const [discounts, setDiscounts] = useState(null);
  useEffect(() => {
    if (discounts === null) {
      let url = "/discounts";
      MethodGet(url)
        .then((res) => {
          setDiscounts(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  return (
    <Layout>
      <Grid container spacing={4} sx={{ padding: 2 }}>
        <Grid item xs={10} sm={10} md={10}>
          <Typography variant='h5' color='initial' textAlign='center'>
            Descuentos
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton aria-label='' onClick={() => props.history.goBack()}>
            <BackIcon />
          </IconButton>
        </Grid>
        {discounts &&
          discounts.map((disc, index) => (
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={index}>
              <div class='containerD'>
                <div className='cardD'>
                  <div className='mainD'>
                    <div className='coD-img'>
                      <img
                        src={require("../../assets/img/merry1.png")}
                        alt='merry'
                        style={{
                          width: "  100%",
                          height: "auto",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      />
                    </div>
                    {/* <div className='verticalD'></div> */}
                    <div className='contentD'>
                      <h2 style={{ display: "flex", justifyContent: "center" }}>
                        MerryColor{" "}
                      </h2>
                      <h1 style={{ display: "flex", justifyContent: "center" }}>
                        {disc.discount} %
                      </h1>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        Descuento
                      </span>
                      <p style={{ marginLeft: 0 }}>
                        Desde ${disc.money_discount_min} {""}
                        hasta ${disc.money_discount_max}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          ))}
      </Grid>
    </Layout>
  );
};

export default Discounts;
