import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import {
  Grid,
  Box,
  Hidden,
  TextField,
  InputAdornment,
  Button,
  Typography,
  Paper,
} from "@mui/material";
import ProductsContext from "../../Context/Products/ProductsContext";
import CardProduct from "../../components/Products/CardProduct";
import { SearchProducts } from "../../components/SearchProducts";
import { useDebounce } from "use-debounce";
import { Paginate } from "../../components/Paginate";
import CartContext from "../../Context/Cart/CartContext";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import SearchingComponent from "../../components/loading/SearchingComponent";
import NoDataComponent from "../../components/loading/NoDataComponents";
import NoResultsComponent from "../../components/loading/NoResultsComponent";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/styles";
import CategoryIcon from "@mui/icons-material/Category";
import PercentIcon from "@mui/icons-material/Percent";
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#092635",
    borderRadius: "25px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#092635",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#092635",
      borderRadius: "25px",
    },
    "&:hover fieldset": {
      borderColor: "#092635",
      borderRadius: "25px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#092635",
      borderRadius: "25px",
    },
  },
});
const Index = () => {
  const [spinner, setSpinerCargando] = useState(false);
  const { GetAllProducts, products } = useContext(ProductsContext);
  useEffect(() => {
    setSpinerCargando(true);
    GetAllProducts();
  }, []);
  const { GetCartUserLogged, cart } = useContext(CartContext);
  useEffect(() => {
    GetCartUserLogged();
  }, []);

  const [productsWithQuantity, setProductsWithQuantity] = useState(
    products
      ? products.map((product) => {
          const cartItem = cart.find((item) => item.id === product.id);
          const quantity = cartItem ? cartItem.quantity : 0;
          return { ...product, quantity };
        })
      : []
  );

  //funcion para buscador
  const [magicWord, setMagicWord] = useState("");
  const [debounceWord] = useDebounce(magicWord, 1500);
  const [searchProducts, saveSearchProducts] = useState([]);

  const handleSearchProduct = () => {
    try {
      let searchResult = productsWithQuantity.filter((product) =>
        product.name
          .toString()
          .toLowerCase()
          .includes(debounceWord.toLowerCase())
      );
      saveSearchProducts(searchResult);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setSpinerCargando(true);
    setProductsWithQuantity(
      products.map((product) => {
        const cartItem = cart.find((item) => item.id === product.id);
        const quantity = cartItem ? cartItem.quantity : 0;
        return { ...product, quantity };
      })
    );
  }, [cart, products]);
  useEffect(() => {
    setSpinerCargando(true);
    handleSearchProduct();
  }, [debounceWord, productsWithQuantity]);
  // console.log(searchProducts);
  return (
    <Layout>
      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 2,
        }}
      >
        {productsWithQuantity.length > 0 && (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Link to={`/categorias`} style={{ textDecoration: "none" }}>
                <Paper
                  sx={{
                    padding: 1,
                    borderTopLeftRadius: 40,
                    borderBottomRightRadius: 40,
                    backgroundColor: "#ffa489",
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    color='white'
                    sx={{ fontSize: "45px" }}
                  >
                    {/* <CategoryIcon sx={{ fontSize: "45px" ,  }} /> */}
                    Categorias
                  </Typography>
                </Paper>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} sx={{ mt: 3 }}>
              <CssTextField
                fullWidth
                label='Buscar producto por nombre'
                // type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon sx={{ color: "#092635" }} />
                    </InputAdornment>
                  ),
                }}
                name='search'
                onChange={(e) => {
                  setMagicWord(e.target.value);
                  saveSearchProducts(null);
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Link to={`/descuentos`} style={{ textDecoration: "none" }}>
                <Paper
                  sx={{
                    padding: 1,
                    borderTopLeftRadius: 40,
                    borderBottomRightRadius: 40,
                    backgroundColor: "#ffa489",
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    color='white'
                    sx={{ fontSize: "45px" }}
                  >
                    {/* <PercentIcon sx={{ fontSize: "45px" }} /> */}
                    Descuentos
                  </Typography>
                </Paper>
              </Link>
            </Grid>
          </>
        )}
      </Grid>
      {!magicWord &&
        (!productsWithQuantity.length ? (
          <div sx={{ display: "flex", justifyContent: "center" }}>
            <NoDataComponent />
          </div>
        ) : (
          <Grid container spacing={1} sx={{ padding: 2 }}>
            {productsWithQuantity.map((product, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CardProduct product={product} />
              </Grid>
            ))}
          </Grid>
        ))}
      {magicWord &&
        (!searchProducts ? (
          <div sx={{ display: "flex", justifyContent: "center" }}>
            <SearchingComponent />
          </div>
        ) : searchProducts.length === 0 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <NoResultsComponent />
          </div>
        ) : (
          <Grid container spacing={1} sx={{ padding: 2 }}>
            {searchProducts.map((product, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CardProduct product={product} />
              </Grid>
            ))}
          </Grid>
        ))}
    </Layout>
  );
};

export default Index;
