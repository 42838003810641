import React from "react";
import Select from "react-select";
const HelpForSeller = (props) => {
  const values = [
    { name: "Si", value: "si" },
    { name: "No", value: "no" },
  ];
  const detectarCambiosHelpForSeller = (value) => {
    props.detectarCambiosHelpForSeller(value);
  };
  return (
    <Select
      onChange={(value) => detectarCambiosHelpForSeller(value)}
      className='basic-single'
      classNamePrefix='select'
      // isLoading={isLoading}
      name='help_for_seller'
      placeholder='Selecciona una opcion'
      options={values.map((value) => {
        let attribute = {
          label: value?.name,
          value: value?.value,
        };
        return attribute;
      })}
    />
  );
};

export default HelpForSeller;
