export const AvatarIcon = ({
  fill = "currentColor",
  filled,
  size,
  height,
  width,
  label,
  ...props
}) => {
  return (
    <svg
      viewBox='0 0 89.0482 89.0482'
      xmlns='http://www.w3.org/2000/svg'
      fill='#000000'
      width='50px'
      style={{ marginTop: 20 }}
    >
      <g id='SVGRepo_bgCarrier' strokeWidth={0} />
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <g id='SVGRepo_iconCarrier'>
        <defs>
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".a{fill:#f16272;}.b{fill:#231f20;}.b,.c,.e,.f{fill-rule:evenodd;}.c{fill:#ffffff;}.d,.e{fill:#fed09e;}.f{fill:#ffd53a;}",
            }}
          />
        </defs>
        <circle className='a' cx='44.5241' cy='44.5241' r='43.524' />
        <path
          className='b'
          d='M44.5241,0a44.5251,44.5251,0,1,1-31.483,13.0411A44.3855,44.3855,0,0,1,44.5241,0ZM74.5932,14.455A42.5246,42.5246,0,1,0,87.048,44.5241,42.392,42.392,0,0,0,74.5932,14.455Z'
        />
        <path
          className='b'
          d='M44.5241,16.1257c16.054,0,29.0682,12.4694,29.0682,27.851a26.9773,26.9773,0,0,1-5.8508,16.7587H21.3067a26.9773,26.9773,0,0,1-5.8508-16.7587C15.4559,28.5951,28.47,16.1257,44.5241,16.1257Z'
        />
        <path
          className='b'
          d='M44.5241,15.1257A30.6278,30.6278,0,0,1,65.77,23.5641a28.1856,28.1856,0,0,1,8.8225,20.4126,27.7542,27.7542,0,0,1-1.5534,9.1667,28.406,28.406,0,0,1-4.5122,8.2091l-.3018.383H20.8233l-.3018-.383a28.406,28.406,0,0,1-4.5122-8.2091,27.7542,27.7542,0,0,1-1.5534-9.1667,28.1856,28.1856,0,0,1,8.8225-20.4126,30.6278,30.6278,0,0,1,21.2457-8.4384ZM64.387,25.0019a28.9844,28.9844,0,0,0-39.7258,0A26.2022,26.2022,0,0,0,16.456,43.9767a25.77,25.77,0,0,0,1.436,8.51,26.3685,26.3685,0,0,0,3.9039,7.2484H67.2523a26.3685,26.3685,0,0,0,3.9039-7.2484,25.77,25.77,0,0,0,1.436-8.51A26.2022,26.2022,0,0,0,64.387,25.0019Z'
        />
        <path
          className='c'
          d='M64.0851,83.3771a43.573,43.573,0,0,1-39.122,0c-.0516-.5808-.08-1.1678-.08-1.762a19.6407,19.6407,0,0,1,39.2814,0C64.1648,82.2093,64.1367,82.7963,64.0851,83.3771Z'
        />
        <path
          className='b'
          d='M64.5344,84.2678a44.4677,44.4677,0,0,1-40.0206,0,.9972.9972,0,0,1-.548-.866c-.0258-.2991-.0456-.5856-.0581-.8575-.0159-.35-.0244-.6624-.0244-.9292a20.6408,20.6408,0,0,1,41.2816,0c0,.2668-.0085.5789-.0244.9292-.0133.29-.0349.5976-.0632.9187a.9953.9953,0,0,1-.5437.8031l.0008.0017ZM54.36,85.87a42.2232,42.2232,0,0,0,8.7738-3.1348l.0145-.2773c.0105-.2364.0164-.5159.0164-.843a18.6406,18.6406,0,1,0-37.2812,0c0,.3271.0059.6066.0164.843l.0145.2773A42.6845,42.6845,0,0,0,54.36,85.87Z'
        />
        <circle className='d' cx='44.5241' cy='66.439' r='7.8846' />
        <path
          className='b'
          d='M44.5241,57.5544a8.8855,8.8855,0,1,1-6.2821,2.6025,8.856,8.856,0,0,1,6.2821-2.6025Zm4.8682,4.0164a6.8851,6.8851,0,1,0,2.0163,4.8682A6.8629,6.8629,0,0,0,49.3923,61.5708Z'
        />
        <path
          className='e'
          d='M63.5386,47.3567a4.9312,4.9312,0,1,1-4.9311,4.9312A4.9311,4.9311,0,0,1,63.5386,47.3567Z'
        />
        <path
          className='b'
          d='M63.5386,46.3567a5.9317,5.9317,0,1,1-4.1938,1.7373,5.9133,5.9133,0,0,1,4.1938-1.7373Zm2.78,3.1512a3.9324,3.9324,0,1,0,1.1511,2.78A3.92,3.92,0,0,0,66.3186,49.5079Z'
        />
        <path
          className='e'
          d='M25.51,47.3567a4.9312,4.9312,0,1,1-4.9312,4.9312A4.931,4.931,0,0,1,25.51,47.3567Z'
        />
        <path
          className='b'
          d='M25.51,46.3567a5.9315,5.9315,0,1,1-4.1939,1.7373A5.913,5.913,0,0,1,25.51,46.3567Zm2.78,3.1512a3.9322,3.9322,0,1,0,1.1512,2.78A3.92,3.92,0,0,0,28.29,49.5079Z'
        />
        <circle className='d' cx='44.5241' cy='47.126' r='19.5001' />
        <path
          className='b'
          d='M44.5241,26.6258A20.5013,20.5013,0,1,1,30.0286,32.63a20.4361,20.4361,0,0,1,14.4955-6.0047Zm13.0816,7.4186A18.5006,18.5006,0,1,0,63.0241,47.126,18.4419,18.4419,0,0,0,57.6057,34.0444Z'
        />
        <path
          className='b'
          d='M36.18,49.7565a2.5314,2.5314,0,1,1-2.5313,2.5314A2.5313,2.5313,0,0,1,36.18,49.7565Z'
        />
        <path
          className='b'
          d='M52.8676,49.7565a2.5314,2.5314,0,1,1-2.5313,2.5314A2.5313,2.5313,0,0,1,52.8676,49.7565Z'
        />
        <polygon
          className='b'
          points='25.169 26.535 64.919 26.535 64.919 45.585 56.28 45.585 55.46 39.809 54.322 31.784 53.184 39.809 52.364 45.585 46.482 45.585 45.662 39.809 44.524 31.784 43.386 39.809 42.566 45.585 36.684 45.585 35.864 39.809 34.726 31.784 33.588 39.809 32.768 45.585 25.169 45.585 25.169 26.535'
        />
        <path
          className='b'
          d='M25.1691,26.2851h40v19.55H56.0631l-.03-.2157-.82-5.7756-.8912-6.2827-.8913,6.2824-.8195,5.7759-.0306.2157H46.2651l-.03-.2157-.8195-5.7756-.8912-6.2827-.8912,6.2824-.8195,5.7759-.03.2157H36.4675l-.0306-.2157-.8195-5.7756-.8913-6.2827-.8912,6.2824-.8195,5.7759-.03.2157h-8.066v-19.55Zm39.5.5h-39.25v18.55h7.1325l.7889-5.56L34.479,31.75h.4943l1.1384,8.0252.7889,5.56H42.35l.7889-5.56L44.2769,31.75h.4944L45.91,39.7752l.7889,5.56h5.449l.7889-5.56L54.0749,31.75h.4943l1.1384,8.0252.7889,5.56h8.1729Z'
        />
        <path
          className='f'
          d='M25.4926,56.5415a2.64,2.64,0,1,1-2.64,2.64A2.64,2.64,0,0,1,25.4926,56.5415Z'
        />
        <path
          className='b'
          d='M25.4926,55.5415A3.63,3.63,0,0,1,28.0642,56.61l.0019-.002A3.64,3.64,0,1,1,22.919,61.755c-.02-.02-.04-.0416-.0578-.0632a3.629,3.629,0,0,1,.0578-5.08l.002-.0017.0017-.002.0014-.0014a3.6285,3.6285,0,0,1,2.5685-1.065Zm1.16,2.4766a1.6494,1.6494,0,0,0-2.3193,0l-.0037.0037a1.65,1.65,0,0,0-.0368,2.281l.04.0383a1.64,1.64,0,0,0,2.3193-2.3193l.002-.002Z'
        />
        <path
          className='f'
          d='M63.5553,56.5415a2.64,2.64,0,1,1-2.64,2.64A2.64,2.64,0,0,1,63.5553,56.5415Z'
        />
        <path
          className='b'
          d='M63.5553,55.5415a3.6275,3.6275,0,0,1,2.57,1.0664l.0017.002.002.0017.0014.0014a3.6293,3.6293,0,0,1-.0034,5.14l.002.002a3.64,3.64,0,1,1-5.1471-5.1471c.02-.02.0416-.04.0632-.0578a3.627,3.627,0,0,1,2.51-1.0086Zm1.16,2.4766a1.65,1.65,0,0,0-2.281-.0368l-.0383.0405a1.64,1.64,0,0,0,2.3193,2.3193l.002.002.0017-.002a1.6494,1.6494,0,0,0,0-2.3193Z'
        />
      </g>
    </svg>
  );
};
