import React, { useContext, useEffect } from "react";
import { SalesWeekIcon } from "../icons/SalesWeek";
import DashboardContext from "../../Context/Dashboard/DashboardContext";
const CardSalesWeek = () => {
  const { ventas_de_la_semana, obtenerVentasDelaSemana } =
    useContext(DashboardContext);
  useEffect(() => {
    obtenerVentasDelaSemana();
  }, []);
  return (
    <div className='card'>
      <div className='container'>
        <div className='cloud front'>
          <SalesWeekIcon />
        </div>
        <div className='cloud back'></div>
      </div>

      <div className='card-header'>
        <span>Ventas de la semana</span>
        <span>{ventas_de_la_semana.rango_fechas}</span>
      </div>

      <span className='temp'>{ventas_de_la_semana.ventas_de_la_semana}</span>
    </div>
  );
};

export default CardSalesWeek;
