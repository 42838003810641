import React, { useContext, useEffect } from "react";
import Layout from "../../../components/Layout/Layout";
import {
  Button,
  Card,
  CardHeader,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ModalAddBrand from "./ModalAddBrand";
import BrandsContext from "../../../Context/Brands/BrandsContext";
const Index = () => {
  //abrir modal
  const [modalAdd, AbrirModalAdd] = useState(false);
  const handleClickOpenAdd = () => {
    AbrirModalAdd(true);
  };
  const handleClickCloseAdd = () => {
    AbrirModalAdd(false);
  };
  const { GetBrands, brands, DeleteBrand } = useContext(BrandsContext);
  useEffect(() => {
    GetBrands();
  }, []);
  return (
    <Layout>
      <Grid container spacing={2} sx={{ margin: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            textAlign="center"
            variant="h5"
            fontWeight="bold"
            fontSize="50px"
            color="#FFBEA0"
          >
            Marcas
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ display: "flex", justifyContent: "end", margin: 2 }}
        >
          <Button variant="contained" onClick={handleClickOpenAdd}>
            Agregar
          </Button>
        </Grid>
        {brands.map((brand, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
            <>
              <Card>
                <Typography
                  textAlign="center"
                  fontWeight="bold"
                  variant="subtitle1"
                  fontSize="40px"
                  color="#FFBEA0"
                >
                  {brand.name}
                </Typography>
                <CardMedia
                  component="img"
                  height="auto"
                  width="auto"
                  image={brand.image}
                  alt={brand.name}
                />
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => DeleteBrand(brand.id)}
                    >
                      Eliminar
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </>
          </Grid>
        ))}
      </Grid>
      <ModalAddBrand
        modal={modalAdd}
        handleClickOpen={handleClickOpenAdd}
        handleClose={handleClickCloseAdd}
      />
    </Layout>
  );
};

export default Index;
