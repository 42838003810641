import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext } from "react";
import ModalImage from "react-modal-image-responsive";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import CreateIcon from "@mui/icons-material/Create";
import AddImageProduct from "../../containers/Products/ProductsAdmin/AddImageProduct";
import { useState } from "react";
import ModalShowProduct from "../../containers/Products/ProductsAdmin/ModalShowProduct";
import AddIcon from "@mui/icons-material/Add";
import ProductsContext from "../../Context/Products/ProductsContext";
const useStyles = makeStyles({
  img: {
    width: 200,
    height: 150,
  },
  jss59: {
    width: "20%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    marginLeft: "10px",
    borderRadius: "10px",
  },
});
const CardProductAdmin = ({ product }) => {
  const miImagen = require("../../assets/img/default.webp");
  const { increaseStock } = useContext(ProductsContext);
  const { img } = product;
  const classes = useStyles();
  const [modalMultimedia, openModalMultimedia] = useState(false);
  const [id_product, setIdProduct] = useState(null);
  const handleOpenMultimedia = (id) => {
    openModalMultimedia(true);
    setIdProduct(id);
  };
  const handleCloseMultimedia = () => {
    openModalMultimedia(false);
    setIdProduct(null);
  };
  const [openShow, setOpenShow] = useState(false);
  const handleOpenShow = (id) => {
    setOpenShow(true);
    setIdProduct(id);
  };
  const handleCloseShow = () => {
    setOpenShow(false);
    setIdProduct(null);
  };
  return (
    <>
      <Card>
        <CardContent>
          <Typography
            variant='su arrowbtitle2'
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className='blink-1'
          >
            <Typography> {product.name} </Typography>
          </Typography>
        </CardContent>
        {img?.length > 0 ? (
          <Box sx={{ width: "100%", display: "flex" }}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box sx={{ width: "50%", height: "50%", position: "static" }}>
                  <ModalImage
                    className={classes.imgproduct}
                    small={img[0]?.image}
                    large={img[0]?.image}
                    alt={product.name}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box sx={{ width: "100%", display: "flex" }}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box sx={{ width: "50%", height: "50%", position: "relative" }}>
                  <ModalImage
                    className={classes.imgproduct}
                    small={miImagen}
                    large={miImagen}
                    alt={product.name}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        <CardActions
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Tooltip title='ver' placement='top'>
            <IconButton onClick={() => handleOpenShow(product.id)}>
              <RemoveRedEyeIcon sx={{ color: "#f48fb1" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Actualizar multimedia' placement='top'>
            <IconButton onClick={() => handleOpenMultimedia(product.id)}>
              <VideoSettingsIcon sx={{ color: "#b39ddb" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title='Editar' placement='top'>
            <Link to={`/Editar-producto/${product.id}`}>
              <IconButton>
                <CreateIcon sx={{ color: "#a5d6a7" }} />
              </IconButton>
            </Link>
          </Tooltip>
          {product.stock === 0 && (
            <Tooltip title='Añadir Stock' placement='top'>
              <IconButton onClick={() => increaseStock(product.id)}>
                <AddIcon sx={{ color: "blue" }} />
              </IconButton>
            </Tooltip>
          )}
        </CardActions>
      </Card>
      {id_product !== null && (
        <AddImageProduct
          open={modalMultimedia}
          handleClose={handleCloseMultimedia}
          id={id_product}
        />
      )}
      {id_product !== null && (
        <ModalShowProduct
          open={openShow}
          id={id_product}
          handleClose={handleCloseShow}
        />
      )}
    </>
  );
};

export default CardProductAdmin;
