// components/SearchBar.js
import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";

const SearchBar = ({ orders, onSearch, setIsLoading }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    setIsLoading(true); // Asegúrate de llamar a setIsLoading aquí como una función
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const filteredData = orders.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      onSearch(filteredData);
      setIsLoading(false); // Llama a setIsLoading(false) después del debounce
    }, 300);

    return () => clearTimeout(timer);
  }, [searchTerm, orders, onSearch, setIsLoading]);

  return (
    <div>
      <TextField
        fullWidth
        type='text'
        placeholder='Buscar orden por nombre de cliente...'
        value={searchTerm}
        onChange={handleSearch}
      />
    </div>
  );
};

export default SearchBar;
