import {
  DELETE_DIRECTION,
  GET_ALL_DIRECTIONS_USER,
  SET_DIRECTION_DEFAULT,
  STORE_DIRECTION,
  UPDATE_DIRECTION,
} from "../../types";

export default function DirectionsReducer(state, action) {
  switch (action.type) {
    case GET_ALL_DIRECTIONS_USER:
      return {
        ...state,
        directions: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case STORE_DIRECTION:
      return {
        ...state,
        directions: [...state.directions, action.payload],
      };
    case UPDATE_DIRECTION:
      return {
        ...state,
        ErrorsAPI: [],
        directions: state.directions.map((direction) => {
          if (direction.id === action.payload.id) {
            direction = action.payload;
          }
          return direction;
        }),
      };
    case SET_DIRECTION_DEFAULT:
      return {
        ...state,
        directions: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case DELETE_DIRECTION:
      return {
        ...state,
        directions: state.directions.filter(
          (direction) => direction.id !== action.payload
        ),
      };
    default:
      return state;
  }
}
