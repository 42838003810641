import React, { useContext, useEffect } from "react";
import PedidosContext from "../../Context/Pedidos/PedidosContext";
import CardPedido from "../../components/Cards/pedidos/CardPedido";
import { Grid } from "@mui/material";
import NoDataComponents from "../../components/loading/NoDataComponents";
const PedidosEnviados = () => {
  const { orders_client, GetPedidosEnviados } = useContext(PedidosContext);
  useEffect(() => {
    GetPedidosEnviados();
  }, []);
  return (
    <Grid container spacing={2}>
      {orders_client.length > 0 ? (
        <>
          {orders_client.map((order, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
              <CardPedido order={order} />
            </Grid>
          ))}
        </>
      ) : (
        <NoDataComponents />
      )}
    </Grid>
  );
};

export default PedidosEnviados;
