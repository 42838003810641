export const AprobePaymentIcon = ({
  fill = "currentColor",
  filled,
  size,
  height,
  width,
  label,
  ...props
}) => {
  return (
    <svg
      version='1.1'
      width={width}
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      fill='#000000'
    >
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        stroke-linecap='round'
        stroke-linejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        {" "}
        <path
          style={{ fill: "#507C5C" }}
          d='M126.465,262.758v-36.906c14.73,2.582,16.946,10.749,17.28,15.551 c0.085,1.231,1.101,2.182,2.335,2.182h16.089c1.352,0,2.415-1.143,2.345-2.493c-1.044-20.242-14.918-33.266-38.049-36.191v-12.411 c0-1.294-1.049-2.342-2.342-2.342h-16.088c-1.294,0-2.342,1.049-2.342,2.342v12.411c-24.066,3.043-38.112,17.019-38.112,38.684 s14.045,35.639,38.112,38.684v36.906c-14.73-2.584-16.946-10.749-17.28-15.551c-0.085-1.231-1.101-2.182-2.335-2.182H69.989 c-1.352,0-2.415,1.143-2.345,2.493c1.044,20.242,14.918,33.266,38.049,36.191v12.411c0,1.294,1.049,2.342,2.342,2.342h16.088 c1.294,0,2.342-1.049,2.342-2.342v-12.411c24.066-3.043,38.112-17.019,38.112-38.684 C164.577,279.778,150.532,265.802,126.465,262.758z M105.691,261.316c-17.335-3.039-17.339-13.809-17.339-17.732 c0-3.921,0.003-14.693,17.339-17.732V261.316z M126.465,319.174v-35.465c17.335,3.04,17.339,13.811,17.339,17.732 C143.804,305.363,143.801,316.134,126.465,319.174z'
        ></path>{" "}
        <rect
          x='235.661'
          y='260.478'
          style={{ fill: "#CFF09E" }}
          width='198.608'
          height='83.563'
        ></rect>{" "}
        <path
          style={{ fill: "#507C5C" }}
          d='M354.661,215.376H231.824c-7.95,0-14.395-6.446-14.395-14.395s6.445-14.395,14.395-14.395h122.837 c7.95,0,14.395,6.446,14.395,14.395S362.611,215.376,354.661,215.376z'
        ></path>{" "}
        <rect
          x='434.27'
          y='41.032'
          style={{ fill: "#CFF09E" }}
          width='63.338'
          height='103.889'
        ></rect>{" "}
        <path
          style={{ fill: "#507C5C" }}
          d='M497.605,159.313c7.95,0,14.395-6.446,14.395-14.395V41.03c0-7.949-6.445-14.395-14.395-14.395 h-63.338l0,0l0,0c-7.95,0-14.395,6.446-14.395,14.395v88.571H14.395C6.445,129.601,0,136.048,0,143.997v257.032 c0,7.949,6.445,14.395,14.395,14.395h233.326c7.95,0,14.395-6.446,14.395-14.395s-6.445-14.395-14.395-14.395H28.79V158.393h391.08 v87.695H235.663c-7.95,0-14.395,6.446-14.395,14.395v83.565c0,7.949,6.445,14.395,14.395,14.395H419.87v28.193h-78.81 c-7.95,0-14.395,6.446-14.395,14.395s6.445,14.395,14.395,14.395h78.81v25.321c0,3.934,1.611,7.698,4.457,10.413l31.669,30.224 c2.781,2.654,6.36,3.982,9.938,3.982c3.579,0,7.157-1.327,9.938-3.982l31.669-30.224c2.846-2.716,4.457-6.479,4.457-10.413V214.934 c0-7.949-6.445-14.395-14.395-14.395c-7.95,0-14.395,6.446-14.395,14.395v219.651l-17.274,16.485l-17.274-16.485v-33.542 c0-0.004,0-0.009,0-0.009c0-0.01,0-0.014,0-0.014v-56.96c0,0,0-0.009,0-0.014v-83.565v-0.014V159.311h48.945V159.313z M483.21,55.425v74.176h-34.548V55.425H483.21z M250.058,329.651v-54.775H419.87v54.775H250.058z'
        ></path>{" "}
      </g>
    </svg>
  );
};
