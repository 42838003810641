import React, { useContext, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { Grid, Typography } from "@mui/material";
import CardSalesWeek from "../../components/Dashboard/CardSalesWeek";
import CardSalesMonthly from "../../components/Dashboard/CardSalesMonthly";
import CardSalesDay from "../../components/Dashboard/CardSalesDay";
import CardCashEntryDay from "../../components/Dashboard/CardCashEntryDay";
import SalesChart from "../../components/Graphs/SalesChart";
import MenuAdmin from "./MenuAdmin";
import AuthContext from "../../Context/auth/AuthContext";
import IndexVerificate from "../../containers/Orders/OrdersAdmin/IndexVerificate";
import IndexOrdersSeller from "../Orders/OrdersAdmin/OrdersSellers/IndexOrdersSeller";
import IndexCont from "../Orders/OrdersContabilidad/IndexCont";
const InicioAdmin = () => {
  const { usuario } = useContext(AuthContext);

  return (
    <Layout>
      <Grid container spacing={2} sx={{ margin: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography align='center' fontSize='30px' fontWeight='bold'>
            Bienvenido
          </Typography>
        </Grid>
        {usuario && usuario.type_user === 0 ? (
          <>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 3,
                marginBottom: 3,
              }}
            >
              <MenuAdmin />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <CardSalesWeek />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <CardSalesMonthly />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <CardSalesDay />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <CardCashEntryDay />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ display: "flex", marginTop: 4 }}
            >
              <SalesChart />
            </Grid>
          </>
        ) : usuario && usuario.type_user === 2 ? (
          <IndexOrdersSeller />
        ) : (
          usuario && usuario.type_user === 3 && <IndexCont />
        )}
      </Grid>
    </Layout>
  );
};

export default InicioAdmin;
