import { CardMedia, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MethodGet from "../../config/Service";

const ProductsShow = (props) => {
  const { id } = props.match.params;
  const [product, setProduct] = useState(null);
  const miImage = require("../../assets/img/default .png");
  useEffect(() => {
    if (product === null) {
      let url = `/products/${id}`;
      MethodGet(url)
        .then((res) => {
          setProduct(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper
            sx={{
              backgroundColor: "#ffa489",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={require("../../assets/img/merryBlack.png")}
              height={70}
              alt='logo_distri'
            />
          </Paper>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ padding: 2, display: "flex", justifyContent: "center" }}
      >
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <Paper elevation={0}>
            {product && (
              <CardMedia
                component='img'
                height='auto'
                image={product.img[0]?.image ?? miImage}
                alt={product.name}
              />
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <Paper elevation={0}>
            {product && (
              <>
                <Typography
                  textAlign='center'
                  fontWeight='bold'
                  fontSize='30px'
                  sx={{ color: "#ffa489" }}
                >
                  {product.name}
                </Typography>
                <Typography fontSize='28px' marginLeft='4px' color='#FFBEA0'>
                  ${product.price_purchase}
                </Typography>
                <Typography fontSize='28px' marginLeft='4px' color='#FFBEA0'>
                  sku: {product.sku ?? "N/A"}
                </Typography>
                <Typography
                  fontSize='20px'
                  marginLeft='4px'
                  sx={{ color: "#43a047" }}
                >
                  {product.stock} disponibles{" "}
                </Typography>
                <Typography fontSize='18px' marginLeft='4px'>
                  Categoría: <b>{product.category.name}</b>
                </Typography>
                <Typography fontSize='18px' marginLeft='4px'>
                  Subcategoría: <b>{product.subcategory.name}</b>
                </Typography>
                <Divider>
                  <Typography>Descripción</Typography>
                </Divider>
                <Typography
                  fontSize='16px'
                  marginLeft='4px'
                  color='gray'
                  textAlign='justify'
                >
                  {product.description}
                </Typography>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductsShow;
