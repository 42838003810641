import React from "react";
import PedidosReducer from "./PedidosReducer";
import PedidosContext from "./PedidosContext";
import MethodGet, { MethodPost } from "../../config/Service";
import headerConfig from "../../config/imageHeaders";
import {
  GET_ALL_ORDERS_COMPLETED_USER,
  GET_ALL_ORDERS_PENDING_USER,
  PEDIDOS_CANCELADOS,
  PEDIDOS_ENVIADOS,
  PEDIDOS_EN_PREPARACION,
  PEDIDOS_PENDIENTES_DE_PAGO,
  UPLOAD_TICKET,
  VALIDANDO_PAGO_PEDIDO,
} from "../../types";
import { useReducer } from "react";
import Swal from "sweetalert2";
const PedidosState = ({ children }) => {
  const initialState = {
    orders_client: [],
    ErrorsApi: [],
    success: false,
  };
  const [state, dispatch] = useReducer(PedidosReducer, initialState);
  const GetPedidosEnviados = () => {
    let url = "/enviados";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: PEDIDOS_ENVIADOS,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const GetPedidosEnPreparacion = () => {
    let url = "/preparando";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: PEDIDOS_EN_PREPARACION,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const GetPedidosPendientesDePago = () => {
    let url = "/sinPagos";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: PEDIDOS_PENDIENTES_DE_PAGO,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const GetPedidosCancelados = () => {
    let url = "/cancelados";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: PEDIDOS_CANCELADOS,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const GetValidandoPago = () => {
    let url = "/validandoPago";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: VALIDANDO_PAGO_PEDIDO,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const GetAllOrdersPendingUser = () => {
    let url = `/clientsales`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_ORDERS_PENDING_USER,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const GetAllOrdersCompletedUser = () => {
    let url = `/finalizedsales`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_ORDERS_COMPLETED_USER,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const UploadTicket = (data) => {
    let url = "/Payments";
    Swal.fire({
      title: "Subiendo imagen...!",
      showConfirmButton: false,
      showLoading: true,
    });
    Swal.showLoading();
    const formData = new FormData();
    formData.append("id_sale", data.id_sale);
    formData.append("reference", data.reference);
    formData.append("amount", data.amount);
    formData.append("image", data.image);
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        dispatch({
          type: UPLOAD_TICKET,
          payload: res.data,
        });
        Swal.fire({
          title: "Agregado",
          text: "El comprobante se ha cargado de manera correcta!",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "¡Atención!",
          icon: "warning",
          timer: 1500,
          showConfirmButton: false,
          text: error.response.data.message,
        });
        console.log(error);
      });
  };

  return (
    <PedidosContext.Provider
      value={{
        orders_client: state.orders_client,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetAllOrdersPendingUser,
        GetAllOrdersCompletedUser,
        UploadTicket,
        GetPedidosCancelados,
        GetPedidosEnPreparacion,
        GetPedidosEnviados,
        GetPedidosEnPreparacion,
        GetValidandoPago,
        GetPedidosPendientesDePago,
      }}
    >
      {children}
    </PedidosContext.Provider>
  );
};

export default PedidosState;
