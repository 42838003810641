import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Typography } from "@mui/material";

export default function Help() {
  const imgWelcome = require("../../assets/Help/welcome.png");
  const imgSwallAdd = require("../../assets/Help/swalAdd.png");
  const imgCart = require("../../assets/Help/iconCart.png");
  const imgCartDetail = require("../../assets/Help/cartDetail.png");
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          ¿Como agregar productos al carrito?
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>
                Una vez que ingresas al sistema se muestra una pantalla como la
                siguiente en la que se listan todos los productos disponibles
                cada producto cuenta con su precio final ya aplicandose el
                maximo descuento que es el del <b>40%</b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <img src={imgWelcome} width="100%" height="auto" alt="welcome" />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <Typography>
                Cada producto cuenta con dos botones los cuales estan
                identificados con los simbolos <b>+</b> y <b>-</b> cada boton
                hace la respectiva accion sumar o restar cantidad
              </Typography>
              <Typography>
                Para agregar productos al carrito es necesario darle clic, sobre
                el boton con el icono <b>+</b>, una vez de darle clic, saldra
                una alerta como la siguiente en la parte superior derecha de
                nuestra pantalla.
              </Typography>
              <img
                src={imgSwallAdd}
                width="100%"
                height="auto"
                alt="alerta_agregar"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <Typography>
                De esta manera es como se agregan los productos a nuestro
                carrito de compras, <b>Importante recordar:</b> al hacerlo de
                esta manera se ira sumando de 1 + 1 o restando dependiendo el
                boton sobre el que se haga el click
              </Typography>
              <Typography>
                Para ver los productos en nuestro carrito unicamente damos clic
                en el boton que se muestra esquina superior derecha con un icono
                de un carrito y un numero como el siguiente
              </Typography>
              <img src={imgCart} width="100%" height="auto" alt="cart" />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          ¿Como actualizo la cantidad de productos en el carrito?
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Typography>
                Una vez de haber dado clic en el icono del carrito de la parte
                superior derecha, nos mostrara una ventana como la siguiente
              </Typography>
              <img
                src={imgCartDetail}
                width="100%"
                height="auto"
                alt="cart_detail"
              />
              <Typography>
                En esta ventana se muestra el detalle de los productos agregados
                al carrito, asi como <b>Subtotoal, Descuento, Total.</b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Typography>
                Como se puede observar en la imagen hay varios datos de cada
                producto ej: El numero en color <b>verde</b> indica el stock
                disponible del producto. El sistema nos permitira agregar o
                modificar la cantidad del producto, hasta la cantidad que esta
                indicada en color <b>verde</b>
              </Typography>
              <Typography>
                Para modificar la cantidad es necesario dar clic, la cantidad
                que tiene el fondo blanco y modifcar la cantidad directamente
                ahi
              </Typography>
              <img
                src={require("../../assets/Help/modifyQuantity.png")}
                alt="modify_quantity"
                width="100%"
                height="auto"
              />
              <Typography>
                Una vez modificada la cantidad, se vuelve a calcular el total a
                pagar, y en este caso se recalcula el descuento obtenido
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          ¿Como termino mi compra?
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>
                Para concluir una compra, es necesario seleccionar una dirección
                de envio.
                <br />
                <b>
                  Si no se selecciona una direccion de envió, se muestra la
                  siguiente alerta
                </b>
                <img
                  src={require("../../assets/Help/errorDirection.png")}
                  alt="error_direction"
                />
              </Typography>
              <Typography>
                Una vez seleccionada la direccion solo basta con dar clic en el
                boton de terminar compra
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          ¿Como pago mi compra?
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Typography>
                Una vez concluida la compra, se redirige en automatico a la
                venta de <b>Mis Pedidos</b> en donde podra consultar todos sus
                pedidos.
                <img
                  src={require("../../assets/Help/MisPedidos.png")}
                  alt="mis_pedidos"
                />
              </Typography>
              <Typography>
                Como se muestra en la imagen anterior, se muestran los pedidos,
                separados por status{" "}
                <b>
                  <ul>Pedidos Pendientes de pago</ul>
                  <ul>Pedidos en validación de pago</ul>
                  <ul>Pedidos en preparación</ul>
                  <ul>Pedidos Enviados</ul>
                  <ul>Pedidos Cancelados</ul>
                </b>
              </Typography>
              <Typography>
                Para pagar una compra es importante esta situados en el Menu de{" "}
                <b>Pedidos pendiente de pago</b>
                <br />
                Como se observa hay dos opciones uno de <b>
                  Subir Comprobante
                </b>{" "}
                y otra <b>Ver detalle</b>, para realizar el pago unicamente nos
                vamos a centrar en el boton que dice <b>Subir Comprobante</b>
              </Typography>
              <Typography>
                Al dar clic en el boton, se abre el siguiente modal en el que se
                debe de subir el comprobante de la transferencia, folio, y
                cantidad depositada.
                <br /> Es importante mencionar que tu pago, pasa por un proceso
                de revision antes de aprobarlo.
                <img
                  src={require("../../assets/Help/uploadComprobante.png")}
                  alt="subir_comprobante"
                />
                <b>
                  Para finalizar unicamente damos clic en el boton de subir
                  comprobante
                </b>
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
