import {
  Alert,
  AlertTitle,
  Grid,
  Paper,
  Typography,
  AppBar,
  Toolbar,
  Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import React, { useContext, useEffect, useState } from "react";
import ProductsContext from "../../Context/Products/ProductsContext";
import ProductCartNoAuth from "../../components/Products/ProductCartNoAuth";
import { Link } from "react-router-dom";
import MethodGet from "../../config/Service";
import NoDataComponents from "../../components/loading/NoDataComponents";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import MoreIcon from "@mui/icons-material/MoreVert";
import Footer from "../../components/Layout/Footer";

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: "0 auto",
});
const Catalogo = () => {
  // Abre la URL en una nueva ventana o pestaña
  const { GetAllProductsNoAuth, products } = useContext(ProductsContext);
  useEffect(() => {
    GetAllProductsNoAuth();
  }, []);
  // const categories = [
  //   { name: "sin filtro", value: 0 },
  //   { name: "Nails", value: 1 },
  //   { name: "Makeup", value: 2 },
  //   { name: "SkinCare", value: 3 },
  // ];
  const [categories, setCategories] = useState();
  useEffect(() => {
    let url = "/cate";
    MethodGet(url)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const [filtro, setFiltro] = useState(3);
  const handleChangeFiltro = (e) => {
    setFiltro(e.target.value);
  };
  const filteredProducts =
    products?.filter((p) => p.id_categorie === filtro) || [];
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper
            sx={{
              backgroundColor: "#FFBEA0",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={require("../../assets/img/merryBlack.png")}
              height={70}
              alt='logo_distri'
            />
          </Paper>
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1, margin: 2 }}>
        <AppBar position='static'>
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#FFBEA0",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                color: "#fff",
                fontSize: "25px",
              }}
            >
              Realizar tu pedido nunca fue tan fácil.
            </Typography>
            <Link to={"/iniciar-sesion"} style={{ textDecoration: "none" }}>
              <Button
                variant='outlined'
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  backgroundColor: "#FFA489",
                  color: "white",
                  borderColor: "#FFBEA0",
                  "&:hover": {
                    backgroundColor: "#FFA489",
                    color: "white",
                    borderColor: "#FFBEA0",
                  },
                }}
              >
                Iniciar
              </Button>
            </Link>
          </Toolbar>
        </AppBar>
      </Box>

      <Grid container spacing={2} sx={{ padding: 2 }}>
        {categories && (
          <FormControl fullWidth>
            <label>Filtrar por categoría:</label>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              defaultValue={3}
              label=''
              onChange={handleChangeFiltro}
            >
              {categories.map((c) => (
                <MenuItem value={c.id}>{c.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={product.id}>
              <ProductCartNoAuth product={product} />
            </Grid>
          ))
        ) : (
          <NoDataComponents />
        )}
      </Grid>
      <Footer />
    </>
  );
};

export default Catalogo;
