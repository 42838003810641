export const PackageIcon = ({
  fill = "currentColor",
  filled,
  size,
  height,
  width,
  label,
  ...props
}) => {
  return (
    <svg
      width={width}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      fill='#000000'
    >
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        stroke-linecap='round'
        stroke-linejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        {" "}
        <polygon
          style={{ fill: "#E0B76E" }}
          points='376.811,187.88 256.003,512 29.001,384.007 29.001,127.997 '
        ></polygon>{" "}
        <polygon
          style={{ fill: "#A38251" }}
          points='256.003,0.004 29.001,127.997 256.003,256.002 256.003,512 483.005,127.997 '
        ></polygon>{" "}
        <polygon
          style={{ fill: "#F2CA7F" }}
          points='256.003,256.002 256.003,512 483.005,384.007 483.005,127.997 '
        ></polygon>{" "}
        <polygon
          style={{ fill: "#898686" }}
          points='402.959,270.659 369.019,247.073 333.995,309.437 359.779,294.939 359.779,400.591 377.722,390.471 377.722,284.849 '
        ></polygon>{" "}
        <polygon
          style={{ fill: "#E0B76E" }}
          points='321.283,159.537 94.281,31.535 28.995,128.001 255.998,256.003 '
        ></polygon>{" "}
        <polygon
          style={{ fill: "#F2CA7F" }}
          points='378.72,130.136 151.716,2.134 255.998,0 483.002,128.001 '
        ></polygon>{" "}
      </g>
    </svg>
  );
};
