import React, { useContext, useEffect } from "react";
import { SalesDayIcon } from "../icons/SalesDayIcon";
import DashboardContext from "../../Context/Dashboard/DashboardContext";
const CardSalesDay = () => {
  const { ventas_del_dia, obtenerVentasDelDia } = useContext(DashboardContext);
  useEffect(() => {
    obtenerVentasDelDia();
  }, []);
  return (
    <div className='card'>
      <div className='container'>
        <div className='cloud front'>
          <SalesDayIcon />
        </div>
        <div className='cloud back'></div>
      </div>

      <div className='card-header'>
        <span>Ventas del día</span>
        <span>{ventas_del_dia.dia}</span>
      </div>

      <span className='temp'>{ventas_del_dia.ventas_del_dia}</span>
    </div>
  );
};

export default CardSalesDay;
