import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import {
  Box,
  Grid,
  Typography,
  getLinearProgressUtilityClass,
} from "@mui/material";
import BrandsContext from "../../Context/Brands/BrandsContext";
import CartContext from "../../Context/Cart/CartContext";
import CardProduct from "../../components/Products/CardProduct";
import { Paginate } from "../../components/Paginate";

const ProductsBrand = (props) => {
  const { name } = props.match.params;
  const [cargando, setSpinerCargando] = useState(false);
  const { ProductsBrand, products_b, totalPages } = useContext(BrandsContext);
  const { GetCartUserLogged, cart } = useContext(CartContext);
  useEffect(() => {
    GetCartUserLogged();
  }, []);
  //paginacion
  const [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages, name };
  useEffect(() => {
    if (params.limite !== null && params.pages !== null) {
      ProductsBrand(params);
    }
  }, [rowsPerPage, pages]);
  const [productsWithQuantity, setProductsWithQuantity] = useState(
    products_b
      ? products_b.map((product) => {
          const cartItem = cart.find((item) => item.id === product.id);
          const quantity = cartItem ? cartItem.quantity : 0;
          return { ...product, quantity };
        })
      : []
  );
  useEffect(() => {
    setSpinerCargando(true);
    setProductsWithQuantity(
      products_b.map((product) => {
        const cartItem = cart.find((item) => item.id === product.id);
        const quantity = cartItem ? cartItem.quantity : 0;
        return { ...product, quantity };
      })
    );
  }, [cart, products_b]);
  return (
    <Layout>
      <Grid container spacing={2} sx={{ margin: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            textAlign="center"
            variant="h5"
            fontWeight="bold"
            fontSize="50px"
            color="#7b1fa2"
          >
            Productos {name}
          </Typography>
        </Grid>
        {productsWithQuantity.map((product, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={3}
            key={index}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CardProduct product={product} />
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
          {products_b.length === 0 ? (
            <></>
          ) : (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Paginate
                pages={pages}
                handleChangePage={handleChangePage}
                totalPages={totalPages}
              />
            </Grid>
          )}
        </Box>
      </Grid>
    </Layout>
  );
};

export default ProductsBrand;
