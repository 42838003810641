import React, { useContext } from "react";
import Layout from "../../../components/Layout/Layout";
import { Box, Button, Grid, Typography, IconButton } from "@mui/material";
import AddCategories from "./AddCategories";
import { useState } from "react";
import CategoriesContext from "../../../Context/Categories/CategoriesContext";
import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import CardCategory from "../../../components/Cards/Categories/CardCategory";
import { BackIcon } from "../../../components/icons/BackIcon";
const useStyles = makeStyles({
  img: {
    width: 200,
    height: 150,
  },
  jss59: {
    width: "100%",
    border: "2px solid #e4097f",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    borderRadius: "5px",
  },
});
const IndexCategoriasAdmin = (props) => {
  const [modalAdd, AbrirModalAdd] = useState(false);
  const handleClickOpenAdd = () => {
    AbrirModalAdd(true);
  };
  const handleClickCloseAdd = () => {
    AbrirModalAdd(false);
  };
  const miImagen = require("../../../assets/img/default.webp");

  const { categories, GetCategories, DeleteCategory } =
    useContext(CategoriesContext);
  useEffect(() => {
    GetCategories();
  }, []);
  const classes = useStyles();

  return (
    <Layout>
      <Grid item xs={2} sx={{ display: "flex", justifyContent: "end" }}>
        <IconButton aria-label='' onClick={() => props.history.goBack()}>
          <BackIcon />
        </IconButton>
      </Grid>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              textAling: "center",
            }}
          >
            <div className={classes.fab}>
              <Button
                onClick={handleClickOpenAdd}
                sx={{
                  color: "black",
                  backgroundColor: "#26c6da",
                  hoverColor: "white",
                  // fontFamily: "monospace",
                  fontWeight: "bolder",
                  fontSize: "15px",
                  textTransform: "none",
                  "&:hover": {
                    color: "black",
                    backgroundColor: "#67cedb",
                    hoverColor: "white",
                    // fontFamily: "monospace",
                    fontWeight: "bolder",
                    fontSize: "15px",
                  },
                }}
              >
                Agregar
              </Button>
            </div>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              paddingTop: 4,
              marginBottom: 10,
            }}
          >
            <Grid container spacing={2}>
              {categories.map((categorie, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                  <CardCategory category={categorie} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <AddCategories
        modal={modalAdd}
        handleClickOpen={handleClickOpenAdd}
        handleClose={handleClickCloseAdd}
      />
    </Layout>
  );
};

export default IndexCategoriasAdmin;
