import React, { useContext, useEffect } from "react";
import PedidosContext from "../../Context/Pedidos/PedidosContext";
import { Grid } from "@mui/material";
import CardPedido from "../../components/Cards/pedidos/CardPedido";
import NoDataComponents from "../../components/loading/NoDataComponents";
const PedidosPendientesDePago = () => {
  const { orders_client, GetPedidosPendientesDePago } =
    useContext(PedidosContext);
  useEffect(() => {
    GetPedidosPendientesDePago();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        {orders_client.length > 0 ? (
          <>
            {orders_client
              .filter((o) => o.status === 0)
              .map((order, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                  <CardPedido order={order} />
                </Grid>
              ))}
          </>
        ) : (
          <NoDataComponents />
        )}
      </Grid>
    </>
  );
};

export default PedidosPendientesDePago;
