import React, { useContext, useState } from "react";
import Layout from "../../../components/Layout/Layout";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Chip,
  IconButton,
  Button,
} from "@mui/material";
import ProductsMostSaled from "../../../components/ReportComponents/ProductsMostSaled";
import ReportSales from "../../../components/ReportComponents/ReportSales";
import { BackIcon } from "../../../components/icons/BackIcon";
import DashboardContext from "../../../Context/Dashboard/DashboardContext";
import ReportSalesSeller from "../../../components/ReportComponents/ReportSalesSeller";
const Reports = (props) => {
  const { downloadStockProducts } = useContext(DashboardContext);
  return (
    <Layout>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper
          elevation={3}
          fullWidth
          sx={{
            padding: 2,
            marginBottom: 4,
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} display='flex' justifyContent='end'>
            <IconButton
              aria-label=''
              onClick={() => props.history.goBack()}
              sx={{ marginTop: -2 }}
            >
              <BackIcon />
            </IconButton>
          </Grid>
          <Typography variant='h4' gutterBottom sx={{ marginTop: -5 }}>
            Panel de reportes
          </Typography>
        </Paper>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Divider>
              <Chip
                sx={{ fontWeight: "bold", fontSize: 18 }}
                label='Reporte Productos más vendido'
              />
            </Divider>
            <ProductsMostSaled />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Divider>
              <Chip
                sx={{ fontWeight: "bold", fontSize: 18 }}
                label='Reportes de ventas'
              />
            </Divider>
            <ReportSales />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Divider>
              <Chip
                sx={{ fontWeight: "bold", fontSize: 18 }}
                label='Ventas de vendedoras'
              />
            </Divider>
            <ReportSalesSeller />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Divider>
              <Chip
                sx={{ fontWeight: "bold", fontSize: 18 }}
                label='Reporte Stock'
              />
            </Divider>
            <Button
              variant='outlined'
              sx={{
                mt: 2,
                padding: 1,
                borderColor: "green",
                color: "green",
                "&:hover": {
                  borderColor: "green",
                  color: "green",
                },
              }}
              fullWidth
              onClick={() => downloadStockProducts()}
              startIcon={
                <svg
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  width='35px'
                >
                  <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                  <g
                    id='SVGRepo_tracerCarrier'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  ></g>
                  <g id='SVGRepo_iconCarrier'>
                    {" "}
                    <path
                      d='M4 4C4 3.44772 4.44772 3 5 3H14H14.5858C14.851 3 15.1054 3.10536 15.2929 3.29289L19.7071 7.70711C19.8946 7.89464 20 8.149 20 8.41421V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V4Z'
                      stroke='#289a2a'
                      stroke-width='2'
                      stroke-linecap='round'
                    ></path>{" "}
                    <path
                      d='M20 8H15V3'
                      stroke='#289a2a'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    ></path>{" "}
                    <path
                      d='M12 9L12 17'
                      stroke='#289a2a'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    ></path>{" "}
                    <path
                      d='M9 14L12 17L15 14'
                      stroke='#289a2a'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    ></path>{" "}
                  </g>
                </svg>
              }
            >
              Descargar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Reports;
