import React from "react";
import ProductsReducer from "./ProductsReducer";
import ProductsContext from "./ProductsContext";
import MethodGet, { MethodPost, MethodPut } from "../../config/Service";
import headerConfig from "../../config/imageHeaders";
import { useReducer } from "react";
import {
  ADD_MULTIMEDIA_PRODUCT,
  ADD_PRODUCT,
  CREATE_ENTRY_PRODUCTS,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_PAGINATION,
  GET_ENTRY_PRODUCTS,
  GET_PRODUCTS_DESACTIVATE,
  GET_PRODUCTS_WITHOUT_IMAGE,
  GET_PRODUCTS_WITH_STOCK_LOW,
  INCREASE_STOCK_PRODUCT,
  PRODUCTS_CATEGORY,
  PRODUCTS_NO_AUTH,
  SEARCH_PRODUCTS,
  UPDATE_PRODUCT,
} from "../../types";
import Swal from "sweetalert2";
const ProductsState = ({ children }) => {
  const initialState = {
    products: [],
    search_products: [],
    ErrorsApi: [],
    success: false,
  };
  const [state, dispatch] = useReducer(ProductsReducer, initialState);
  //Funcion para obtener todos los productos auth
  const GetAllProducts = () => {
    let url = `/Products`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_PRODUCTS,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetProductsDesactivate = () => {
    let url = "/products-des";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_PRODUCTS_DESACTIVATE,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const searchProduct = (text) => {
    let url = `/products/search?q=${text}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: SEARCH_PRODUCTS,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchProductsAdmin = (text) => {
    console.log(text, "el texto que se encia en el searchAdmin");
    let url = `/products/search?q=${text}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: SEARCH_PRODUCTS,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //funcion para obtener todos los productos no auth
  const GetAllProductsNoAuth = () => {
    let url = `/products/indexNoAuth`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: PRODUCTS_NO_AUTH,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //funcion para obtener los productos con paginacion
  const GetProductsPaginate = (params) => {
    let url = `/paginate/${params.limit}`;
    MethodGet(url)
      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_ALL_PRODUCTS_PAGINATION,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //funcion para obtener los productos que no tienen imagen
  const GetProductsWithoutImage = () => {
    let url = `/products/noimage`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_PRODUCTS_WITHOUT_IMAGE,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //funcion para obtener los prductos con stock < 30
  const GetProductsLowStock = () => {
    let url = "";
    MethodGet(url)
      .then((res) => {
        console.log(res);
        dispatch({
          type: GET_PRODUCTS_WITH_STOCK_LOW,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const AddMultimediaProduct = (data) => {
    Swal.fire({
      title: "Agregar Imagen",
      text: "¿Estas seguro de Agregar esta imagen como foto del producto?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        const formData = new FormData();
        formData.append("id_product", data.id_product);
        formData.append("image", data.image);
        let url = `/Images`;
        Swal.fire({
          title: "Subiendo imagen...!",
          showConfirmButton: false,
          showLoading: true,
        });
        Swal.showLoading();
        MethodPost(url, formData, { headerConfig })
          .then((res) => {
            Swal.fire({
              title: "Agregado",
              text: "La imagen se ha agregado correctamente!",
              showConfirmButton: false,
              timer: 1000,
              icon: "success",
            });
            // setTimeout(() => {
            //   window.location.href = "/PropertiesPending";
            // }, 1000);
            dispatch({
              type: ADD_MULTIMEDIA_PRODUCT,
              payload: res.data,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              icon: "error",
              text: error.response.data.message,
            });
          });
      }
    });
  };

  const UpdateProduct = (data) => {
    let url = `/Products/${data.id}`;
    MethodPut(url, data)
      .then((res) => {
        dispatch({
          tipe: UPDATE_PRODUCT,
          payload: res.data,
        });
        Swal.fire({
          title: "Actualizado",
          text: "El producto se ha actualizado correctamente!",
          timer: 1500,
          showConfirmButton: false,
          icon: "success",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ProductsCategory = (id) => {
    let url = `/ProductsCategory/${id}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: PRODUCTS_CATEGORY,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const AddProduct = (data) => {
    let url = "/Products";
    MethodPost(url, data)
      .then((res) => {
        dispatch({
          type: ADD_PRODUCT,
          payload: res.data,
        });
        Swal.fire({
          title: "Registrado",
          text: "El producto se ha registrado correctamente!",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "error",
          showConfirmButton: false,
          icon: "error",
          text: error.response.data.message,
          timer: 1500,
        });
      });
  };

  const increaseStock = (id) => {
    Swal.fire({
      title: "Añadir stock al producto",
      allowOutsideClick: false,
      html: `<input type="number" id="quantity" class="swal2-input" placeholder="1258">`,
      confirmButtonText: "Guardar",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      focusConfirm: false,
      preConfirm: () => {
        var quantity = Swal.getPopup().querySelector("#quantity").value;
        if (!quantity) {
          Swal.showValidationMessage(
            `Por favor ingresa una cantidad para continuar`
          );
        } else if (quantity === 0) {
          Swal.showValidationMessage(
            `La cantidad que se asigne debe ser mayor a 0`
          );
        }
        return { quantity: quantity };
      },
    }).then((result) => {
      if (result.value) {
        // quantity = result.value.quantity;
        let url = `increaseStock/${id}`;
        const formData = new FormData();
        formData.append("quantity", result.value.quantity);
        MethodPost(url, formData)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: `Actualizado `,
              text: "Se añadio correctamente el stock!",
              timer: 1500,
              showConfirmButton: false,
            });

            dispatch({
              type: INCREASE_STOCK_PRODUCT,
              payload: res.data,
            });
          })
          .catch((error) => {
            Swal.fire({
              Title: "Error",
              icon: "error",
              text: "Ocurrio un error",
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  const StoreEntryProduct = (data) => {
    let url = "/enter-products";
    MethodPost(url)
      .then((res) => {
        dispatch({
          type: CREATE_ENTRY_PRODUCTS,
          payload: res.data,
        });
        Swal.fire({
          title: "Excelente!",
          text: "La entrada de productos",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetEntryProducts = () => {
    let url = "/enter-products";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ENTRY_PRODUCTS,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ProductsContext.Provider
      value={{
        products: state.products,
        search_products: state.search_products,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        totalDocs: state.totalDocs,
        limit: state.limit,
        page: state.page,
        hasPrevPage: state.hasPrevPage,
        prevPage: state.prevPage,
        hasNextPage: state.hasNextPage,
        nextPage: state.nextPage,
        links: state.links,
        totalPages: state.totalPages,
        searchProductsAdmin,
        GetAllProducts,
        GetAllProductsNoAuth,
        GetProductsPaginate,
        GetProductsWithoutImage,
        GetProductsLowStock,
        AddMultimediaProduct,
        UpdateProduct,
        ProductsCategory,
        AddProduct,
        searchProduct,
        increaseStock,
        GetProductsDesactivate,
        StoreEntryProduct,
        GetEntryProducts,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export default ProductsState;
