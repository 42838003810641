import React, { useContext } from "react";
import Header from "./Header";
import "./styles.css";
import Footer from "./Footer";
import AuthContext from "../../Context/auth/AuthContext";

const Layout = ({ children }) => {
  const { usuario } = useContext(AuthContext);
  return (
    <>
      <div>
        <Header children={children} />
        {usuario && usuario.type_user === 1 && <Footer />}
      </div>
    </>
  );
};

export default Layout;
