import React, { useContext, useEffect, useState } from "react";
import { Card, Box, CardMedia, CardContent, Typography } from "@mui/material";
import AuthContext from "../../Context/auth/AuthContext";
import MethodGet from "../../config/Service";
import InfoForPoints from "./InfoForPoints";

const Points = () => {
  const { usuario } = useContext(AuthContext);
  const { phone } = usuario;
  const [puntos, setPuntos] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let url = `https://apipoints.wapizima.com/api/points/${phone}`;
    MethodGet(url)
      .then((res) => {
        setPuntos(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [phone]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (puntos.cliente === null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [puntos.cliente]);

  if (open) {
    return <InfoForPoints open={open} handleClose={handleClose} />;
  }

  return (
    <Card>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CardMedia
          component='img'
          sx={{ width: "150px" }}
          image={require("../../assets/img/gift-svgrepo-com.png")}
          alt='pedidos'
        />
      </Box>
      <CardContent sx={{ flex: "1 0 auto" }}>
        <Typography component='div' variant='h5'>
          Mis Puntos
        </Typography>
        <Typography variant='subtitle1' color='text.secondary' component='div'>
          {puntos.cliente?.puntos || 0} Puntos obtenidos
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Points;
