import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
const useStyles = makeStyles({
  card: {
    width: "330px",
    height: "195px",
    background: "#ffa489",
    // background:
    //   "linear-gradient(to  bottom,#f06292,#f06292,#f06292,#f06292,#f06292,#f06292,#f06292,#f06292,#f06292,#f06292,#f06292,#f06292,#ad1457,#f06292,#f06292,#f06292,#f06292,#f06292,#f06292,#f06292,#f06292,#f06292,#f06292,#f06292,#f06292,#f06292)",
    borderRadius: "15px",
  },
  imgCenter: {
    marginTop: -125,
    marginBottom: 0,
    justifyContent: "center",
    display: "flex",
    opacity: "0.9",
    //background: "linear-gradient(to  bottom,#f8bbd0,#f48fb1,#f8bbd0)",
    // width: "150px",
    // height: "150px",
  },
  chipImg: {
    display: "flex",
    justifyContent: "start",
    marginTop: 15,
  },
  body: {
    font: "16px Helvetica Neue, Helvetica, sansSerif",
    backgroundColor: "#fbfbfb",
    color: "#666",
  },
});

export default function PaymentInfo({ open, handleClose }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>{"Información para pagos"}</DialogTitle>
        <DialogContent>
          <Card className={classes.card}>
            <CardContent>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <img
                  src={require("../../assets/img/Mastercard-logo.png")}
                  width={50}
                  height={50}
                />
              </div>

              <div>
                <Typography
                  sx={{
                    color: "white",
                    fontSize: 20,
                    marginTop: -6,
                  }}
                >
                  <span style={{ fontSize: 13, marginTop: -5 }}>
                    <b> Tarjeta: </b>
                  </span>
                  <br />
                  5579 0900 4257 9467
                </Typography>
              </div>
              <div>
                <Typography
                  sx={{
                    color: "white",
                    fontSize: 18,
                    marginTop: 0.5,
                  }}
                >
                  <span style={{ fontSize: 13, marginTop: -10 }}>
                    <b>Clabe:</b>
                  </span>
                  <br />
                  0144 2726 0289 3120 12
                </Typography>
              </div>
              <div>
                <Typography sx={{ marginTop: 0, color: "#FFF" }}>
                  <span>
                    <b> Beneficiario:</b>
                  </span>
                  <br />
                  Fernando Bonifacio Morales
                </Typography>
              </div>
              <div>
                <Typography sx={{ marginTop: -1, color: "#FFF" }}>
                  <span>
                    <b> Banco: Santander</b>
                  </span>
                </Typography>
              </div>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='error' onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
