import React from "react";
import BrandsReducer from "./BrandsReducer";
import BrandsContext from "./BrandsContext";
import MethodGet, { MethodDelete, MethodPost } from "../../config/Service";
import headerConfig from "../../config/imageHeaders";
import { useReducer } from "react";

import Swal from "sweetalert2";
import {
  CREATE_BRAND,
  DELETE_BRAND,
  GET_ALL_BRANDS,
  PRODUCTS_BRAND,
} from "../../types";
const BrandsState = ({ children }) => {
  const initialState = {
    brands: [],
    products_b: [],
    ErrorsApi: [],
    success: false,
  };
  const [state, dispatch] = useReducer(BrandsReducer, initialState);
  //Funcion para obtener todos los productos auth
  const GetBrands = () => {
    let url = `/brands`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_BRANDS,
          payload: res.data.brands,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ProductsBrand = (params) => {
    let url = `/productos/${params.name}?page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: PRODUCTS_BRAND,
          payload: {
            products: res.data.data,
            totalDocs: res.data.total,
            links: res.data.links,
            hasPrevPage: res.data.prev_page_url,
            hasNextPage: res.data.next_page_url,
            limit: params.limite,
            page: params.pages,
            totalPages: res.data.last_page,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const AddBrand = (data) => {
    let url = "/brands";
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("image", data.image);
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        dispatch({
          type: CREATE_BRAND,
          payload: res.data,
        });
        Swal.fire({
          title: "Registrado",
          text: "La marca se ha creado exitosamente!",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "error",
          showConfirmButton: false,
          icon: "error",
          text: error.response.data.message,
          timer: 1500,
        });
      });
  };

  const DeleteBrand = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "La marca seleccionada será eliminada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/brands/${id}`;
        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch({
              type: DELETE_BRAND,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 2000,
              showConfirmButton: false,
            });
          });
      }
    });
  };
  return (
    <BrandsContext.Provider
      value={{
        brands: state.brands,
        products_b: state.products_b,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        totalDocs: state.totalDocs,
        limit: state.limit,
        page: state.page,
        hasPrevPage: state.hasPrevPage,
        prevPage: state.prevPage,
        hasNextPage: state.hasNextPage,
        nextPage: state.nextPage,
        links: state.links,
        totalPages: state.totalPages,
        GetBrands,
        AddBrand,
        ProductsBrand,
        DeleteBrand,
      }}
    >
      {children}
    </BrandsContext.Provider>
  );
};

export default BrandsState;
